import React, { Component } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Table,  
  Tab,
  Tabs,
  Alert
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card.jsx";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import { userListThArray } from "../../variables/Variables.jsx";
import {
  convertFirebseSecondsToDate,
  userAccountStatus
} from "components/Helper/index";

//Datatable Modules
import $ from 'jquery'; 
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import api from "api.js";
import { convertMilisecondsToDate } from "components/Helper/index.jsx";

class AssessmentList extends Component {

  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      activeUsers:[],
      testUsers:[],
      userData:{},
      errors:{},
      error:""
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#active-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });
    $('#test-customers-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });
  }
  
  fetchData = async() => {
    this.setState({isLoading:true});
    try {
      const { data: customers } = await api.get('getUsers')
      let activeUserArr = [];
      let testUsersArr = [];
      customers.forEach(dataObj => {
        let item = userAccountStatus().find(item => item.key === dataObj.status);
        if (!dataObj.deleted && dataObj.deleted !== true && dataObj.currentTreatmentID) {
          if (dataObj.test !== true) {
            let activeItem = {
              id: dataObj.id,
              version: dataObj.version,
              name: dataObj.firstName + " " + dataObj.lastName,
              email: dataObj.email,
              address: dataObj.address,
              ref: dataObj.ref,
              dob: dataObj.dob,
              gender: dataObj.gender,
              status: item ? item.val : "",
              deleted: dataObj.deleted,
              currentTreatmentID: dataObj.currentTreatmentID,
              created: convertMilisecondsToDate(new Date(dataObj.created).getTime()),
              created_seconds: new Date(dataObj.created).getTime()
            }
            activeUserArr.push(activeItem);
          } else if (dataObj.test === true) {
            testUsersArr.push({
              id: dataObj.id,
              version: dataObj.version,
              name: dataObj.name,
              email: dataObj.email,
              address: dataObj.address,
              ref: dataObj.ref,
              dob: dataObj.dob,
              gender: dataObj.gender,
              status: item ? item.val : "",
              deleted: dataObj.deleted,
              currentTreatmentID: dataObj.currentTreatmentID,
              created: convertMilisecondsToDate(new Date(dataObj.created).getTime()),
              created_seconds: new Date(dataObj.created).getTime()
            });
          }
        }
      })
      this.setState({
        activeUsers: activeUserArr,
        testUsers: testUsersArr,
        isLoading: false
      });
    } catch (err) {
      this.setState({ error: err.message })
    }
  }

  deleteUser = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      try {
        await api.post('updateUser', { id, deleted: true })
        window.location.reload();
        // await this.fetchData(); 
      }catch(err) {
        this.setState({error:err.message})
      }
    }
  }


  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title=""
                category=""
                ctTableFullWidth
                ctTableResponsive
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Tabs defaultActiveKey="active" id="uncontrolled-tab-example">
                    <Tab eventKey="active" title="Live Customers" >
                      <Table striped hover id="active-datatable">
                        <thead>
                          <tr>
                            {userListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.activeUsers.map((val, key) => {
                            return (
                              <tr key={key} id={key} className={ val.test ? 'shipment-danger' : ''}>
                                <td><span style={{display:"none"}}>{val.created_seconds}</span>{val.created}</td>
                                <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                                <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                                <td>{val.email}</td>
                                <td>{val.status}</td>
                                <td className="users-list-fafa"> 
                                  <Link target="_blank" to={`/admin/assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="fa fa-eye" title="View" /></Link>&nbsp;&nbsp;
                                  <Link target="_blank" to={`/admin/users/${val.version == 2 ? 'assessment' : 'diagnosis'}/${val.id}/${val.currentTreatmentID}?type=t`}> <i className="fa fa-edit" title="View" /></Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Tab>
                    <Tab eventKey="test-customers" title="Test Customers" >
                      <Table striped hover id="test-customers-datatable">
                        <thead>
                          <tr>
                            {userListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.testUsers.map((val, key) => {
                            return (
                              <tr key={key} id={key}>
                              <td><span style={{display:"none"}}>{val.created_seconds}</span>{val.created}</td>
                                <td><Link to={`users/view/${val.id}`}>{val.ref}</Link></td>
                                <td>{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</td>
                                <td>{val.email}</td>
                                <td>{val.status}</td>
                                <td className="users-list-fafa">
                                  <Link to={`/admin/assessment/view/${val.id}/${val.currentTreatmentID}`}> <i className="fa fa-eye" title="View" /></Link>&nbsp;&nbsp;
                                  <Link to={`/admin/users/diagnosis/${val.id}/${val.currentTreatmentID}?type=t`}> <i className="fa fa-edit" /></Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </Tab>
                    </Tabs>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AssessmentList;
