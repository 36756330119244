import React, {useState} from "react";
import bgimgfrom from "../../src/assets/img/lady-background.jpg";
import { NavLink} from "react-router-dom";
import * as fb from "../../src/firebase";
import {actionCodeForgotPasswordSettings} from "../components/Utils/helper";

const ForgotPassword = ({ history }) => {
  
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const sendPasswordResetEmail = (event,email) => {
    event.preventDefault();
    fb.auth.sendPasswordResetEmail(email, actionCodeForgotPasswordSettings()).then(res => {
        setSuccess("Reset password link has been sent to your registered email address!")
    }).catch(error => {
      setError(error.message);
      console.error("Error signing in with password and email", error);
    });
  };

  const onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'userEmail') {
      setEmail(value);
    }
  };

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <form className="login100-form validate-form">
            <span className="login100-form-title p-b-43">
              Forgot Password
            </span>
            {error !== null && <div className = "py-4 bg-red-600 w-full text-danger mb-3">{error}</div>}
            {success !== null && <div className = "py-4 bg-red-600 w-full text-success mb-3">{success}</div>}
            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
              <input 
                className="input100" 
                type="email" 
                name="userEmail" 
                placeholder="Email"
                value = {email}
                onChange={(event) => onChangeHandler(event)}
              />
              <span className="focus-input100" />
            </div>
            <div className="container-login100-form-btn">
              <button className="login100-form-btn" onClick = {(event) => {sendPasswordResetEmail(event, email)}}>
                Submit
              </button>
            </div>
            <div className="text-center p-t-46 p-b-20">
              <span className="txt2">
                <NavLink
                  to="/admins"
                  activeClassName="active"
                >
                Existing Account?
              </NavLink>
              </span>
            </div>
          </form>
          <div className="login100-more" style={{backgroundImage: `url(${bgimgfrom})`}}>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
