import logo from "../../web_assets/img/logo.png";
import depost from "../../web_assets/img/libby3.png";
import waitingList from "../../web_assets/img/img.png";
import upload from "../../web_assets/img/upload.PNG";
import check from "../../web_assets/img/check.svg";
import alertCircle from "../../web_assets/img/alert-circle.png";
import downloadBanner from "../../web_assets/img/libby5.png";
import popupImage from "../../web_assets/img/popup.png";
import startOverBanner from "../../web_assets/img/libby4.png";
import depositConfirm from "../../web_assets/img/deposit-confirm.png";
import shipping from "../../web_assets/img/shipping.png";
import key_bg from "../../web_assets/img/key_bg.png";
import apple_icon_download from "../../web_assets/img/apple_icon_download.png";
import apple_logo_download from "../../web_assets/img/apple_logo_download.png";
import google_icon_download from "../../web_assets/img/google_icon_download.png";
import key_image from "../../web_assets/img/key_image.png";
import key_label from "../../web_assets/img/key_label.png";
import disclaimer from "../../web_assets/img/disclaimer.png";


export const staticWebImages = {
  logo:logo,
  depost:depost,
  waitingList:waitingList,
  upload:upload,
  check:check,
  alertCircle:alertCircle,
  downloadBanner:downloadBanner,
  popupImage:popupImage,
  startOverBanner:startOverBanner,
  depositConfirm:depositConfirm,
  shipping:shipping,
  key_bg:key_bg,
  apple_icon_download:apple_icon_download,
  apple_logo_download:apple_logo_download,
  google_icon_download:google_icon_download,
  key_image:key_image,
  key_label:key_label,
  disclaimer:disclaimer
}








