import React, { Component, createContext } from "react";
import * as fb from "../firebase";

export const UserContext = createContext({ user: null });

class UserProvider extends Component {
  state = {
    user: null
  };

  componentDidMount = async () => {
    fb.auth.onAuthStateChanged(async userAuth => {
      // if(userAuth){
      //   await fb.usersCollection.doc(userAuth.uid).get().then(res => {
      //     const dataObj = res.data();
          this.setState({ user:userAuth });
      //   });
      // }
    });
  };

  render() {
    const { user } = this.state;
    // console.log('current user in provider', user)
    return (
      <UserContext.Provider value={user}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;