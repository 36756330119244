import React, { Component } from "react";

class TypeFormDateField extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:"",
        }
    }

    onChangeHandler = async (event) => {
        const {name, value} = event.currentTarget
        if(value){
            let date = value.split('-')
            let diff = new Date().getTime() - new Date(date[0],date[1]-1,date[2]).getTime()
            let years = diff/(1000*60*60*24*365)
            if(diff > 0){
                if(years < 120 && years > 15){
                    this.props.onChange(value)
                }else{
                    this.props.onError('You are too old or young')
                    this.props.onChange(null)
                }
            }else{
                this.props.onError('That date must be in the past')
                this.props.onChange(null)
            }
        }else{
            this.props.onError('That date isn`t valid.')
            this.props.onChange(null)
        }
    }

    render() {
        return (
            <input
                className="inputText"
                type="date"
                onChange={this.onChangeHandler} />
        );
    }
}

export default TypeFormDateField;
