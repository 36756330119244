import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import { Link } from "react-router-dom"; 

class AddReferral extends Component {
  constructor(props){
    super(props);
    this.state = {
        user_id:"",
        name:"Admin",
        amount:"",
        credits:"",
        currency:"",
        error:"",
        errors:{},
        disabled : false,
        errorMessage:""
    }
    this._isMounted = false;
  }

    onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
        if(name === 'name') {
            this.setState({name:value});
        }else if(name === 'amount'){
            this.setState({amount:value});
        }else if(name === 'credits'){
            this.setState({credits:value});
        }else if(name === 'currency'){
            this.setState({currency:value});
        }
    };

    componentDidMount = async () => {
        const  url = window.location.href;
        const segment = url.substring(url.lastIndexOf('/') + 1);
        this.setState({
            user_id:segment
        });
    }

    handleValidation = () => {
        let {name, amount, currency, credits} = this.state;
        let errors = {};
        let formIsValid = true;

        //Name
        if(!name){
            formIsValid = false;
            errors["name"] = "Name is required";
        }

        //amount
        if(!amount){
            formIsValid = false;
            errors["amount"] = "Amount is required";
        }

        //Password
        if(!currency){
            formIsValid = false;
            errors["currency"] = "Currency is required";
        }

        //credits
        if(!credits){
            formIsValid = false;
            errors["credits"] = "Credits is required";
        } 
        this.setState({errors: errors});
        return formIsValid;
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this._isMounted = true;
        this.setState({disabled: true});
        if(this.handleValidation()) {
            fb.usersCollection.doc(this.state.user_id).collection('Referral').doc().set({
                refId:"q6pn8JbJk3ffpqB2yIYcw50poHJ3",
                refName:this.state.name,
                currency:this.state.currency,
                amount:this.state.amount,
                credits:this.state.credits,
                type:"added",
                time:fb.timestamp.serverTimestamp()
            }).then(res=> {
                this.props.history.push(`/admin/users/referral/${this.state.user_id}`)
            }).catch(err=> {
                this.setState({
                    errorMessage:err.message,
                    disabled: false
                })
            });
        }else{
            this.setState({
                disabled: false
            })
        }
    }

    componentWillUnmount = () => {
        if(this._isMounted){
            this._isMounted = false;
        }
    }
  
    render () {
        console.log('render', this.state)
        return (
        <div className="content">
            <Container fluid>
                <Row>
                    <Col md={12}>
                    {this.state.error &&
                        <Alert bsStyle="danger">
                        <button type="button" aria-hidden="true" className="close">
                            &#x2715;
                        </button>
                        <span>{this.state.error}</span>
                        </Alert>
                    }
                    {this.state.errorMessage && 
                        <Alert bsStyle="danger">
                          <span>{this.state.errorMessage}</span>
                        </Alert>
                    }
                    <Card
                        title="Add referral"
                        button={
                            <Link to={`/admin/users/referral/${this.state.user_id}`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                        }
                        content={
                        <form onSubmit={this.handleSubmit}>
                            <Row>
                                <div className="col-md-6">
                                    <FormGroup>
                                    <FormLabel>Ref Name</FormLabel>
                                    <input 
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        name="name"
                                        value={this.state.name}
                                        onChange={(event)=>this.onChangeHandler(event)}
                                    />
                                    </FormGroup>
                                </div>
                                <div className="col-md-6">
                                    <FormGroup>
                                    <FormLabel>Credits</FormLabel>
                                    <input 
                                        type="text"
                                        className="form-control"
                                        placeholder="Credits"
                                        name="credits"
                                        value={this.state.credits}
                                        onChange={(event)=>this.onChangeHandler(event)}
                                    />
                                    </FormGroup>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-6">
                                    <FormGroup>
                                        <FormLabel>Amount</FormLabel>
                                            <input 
                                                type="text"
                                                className="form-control"
                                                placeholder="Amount"
                                                name="amount"
                                                value={this.state.amount}
                                                onChange={(event)=>this.onChangeHandler(event)}
                                            />
                                    </FormGroup>
                                </div>
                                <div className="col-md-6">
                                    <FormGroup>
                                        <FormLabel>Currency</FormLabel>
                                        <input 
                                            type="text"
                                            className="form-control"
                                            placeholder="Currency"
                                            name="currency"
                                            value={this.state.currency}
                                            onChange={(event)=>this.onChangeHandler(event)}
                                        />
                                    </FormGroup>
                                </div>
                            </Row>
                            <Button
                                className="btn-fill pull-right libi-admin-btn"
                                bsStyle="info" 
                                pullRight fill 
                                type="submit"
                                disabled={this.state.disabled}
                            >
                                {this.state.disabled ? 'Loading...' : 'Add Referral'}
                            </Button>
                            <div className="clearfix" />
                        </form>
                        }
                    />
                    </Col>
                </Row>
            </Container>
        </div>
        );
    }
}

export default AddReferral;
