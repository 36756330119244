import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert,
  // FormControl
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import { Link } from "react-router-dom";
import {addAdminLog} from "../../components/Helper";

class AddInviteUser extends Component {
  constructor(props){
    super(props);
    this.state = {
      name:"",
      number:"",
      disabled : false,
      error:"",
      errors:{},
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'name') {
      this.setState({name:value});
    }else if(name === 'number'){
      this.setState({number:value});
    }
  };

  handleValidation = () => {
    let {name, number} = this.state;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!name){
      formIsValid = false;
      errors["name"] = "Name is required";
    }

    //Number
    if(!number || number.length != 6){
      formIsValid = false;
      errors["number"] = "Please enter the 6 digit valid number";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({disabled: true}); //disable button
    if(this.handleValidation(this.state.errors)){
      let docRef = fb.InvitedUsersCollection.doc();
      await docRef.set({
        id:docRef.id,
        name:this.state.name,
        number:this.state.number,
        created:fb.timestamp.serverTimestamp()
      }).then(spanShot => {
        addAdminLog('created_invite',
          `Created the invite code ${docRef.id}`)
        this.props.history.push('/admin/invited')
      }).catch(err => {
        this.setState({
          error:`Something went wrong-${err}`,
          disabled: false
        });
      });
    }else{
      this.setState({
        disabled: false
      });
    }
  }
  
  render() {
    console.log('state', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Invite Code"
                button={
                  <Link to={`/admin/invited`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Name</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            value= {this.state.name}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.name}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Number</FormLabel>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Number"
                            name="number"
                            value= {this.state.number}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.number}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Button 
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                    {this.state.disabled ? 'Loading...' : 'Submit'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AddInviteUser;
