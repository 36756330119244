import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import { Link } from "react-router-dom";
import {Multiselect} from "multiselect-react-dropdown";
import SelectSearch from 'react-select-search';
import axios from "axios";
import CustomNumberField from "../../components/CustomNumberField/CustomNumberField";
import {
  addAdminLog,
  treatmentTimeCases
} from "components/Helper/index";
import { areaOptions } from "views/rules/AddRule.jsx";
import api from "api.js";

class AddPackage extends Component {
  constructor(props){
    super(props);
    this.state = {
      customer:"",
      customerVersion: '',
      admins:[],
      shipmentProduct:[],
      products:[],
      productss:[],
      productsList:[],
      customersList:[],
      test:"",
      shippingAddress:{
        city:"",
        email:"",
        country:"",
        fullname:"",
        phone:"",
        line1:"",
        line2:"",
        postal_code:""
      },
      type: 'rb',
      error:"",
      errors:{},
      disabled : false,
      isProductChanged:false,
      maxProductsQty: 0,
      minProductsQty: 0
    }
    this._isMounted = false;
  }

  componentDidMount = async () => {
    fb.metaCollection.doc('assessment_form').get().then(res => {
      this.setState({
        minProductsQty: res.data().min_products_quantity,
        maxProductsQty: res.data().max_products_quantity,
      })
    })

    try {
      //feth admins to send email
      await this.getAdmins();
      const expiresIn = 7200
      const createdAt = new Date(Date.now() + 24 * 60 * 60 * 1000)//fb.timestamp.serverTimestamp().toDate()
      console.log('createdAt', createdAt)
      const {data: customers} = await api.get('getUsers')
      let customersList = [];
      customers.forEach(dataObj => {
        if(!dataObj.deleted || dataObj.deleted !== true){
          customersList.push({
            name: dataObj.name+" <"+dataObj.email+">",
            value: dataObj.id
          });
        }
      });
      this.setState({
        customersList: customersList
      })
      let productsList = [];
      const products = await fb.productsCollection.orderBy("order", "asc").get();
      products.docs.forEach(doc=> {
        const dataObj = doc.data();
        let p = dataObj.ref.replace("p", "");
        productsList.push({
          ref:dataObj.ref,
          title:dataObj.title,
          p:p
        })
      });
      const sortedProduct = productsList.sort(function (a, b) {
        return parseInt(a.p) - parseInt(b.p);
      });
      this.setState({
        productsList: sortedProduct
      })
    }catch(err){
      this.setState({
        error:err.message
      })
    }
  }

  onChangeHandler = async (event, ref = 0) => {
    const {name, value} = event.currentTarget;
    if(name === 'd_name'){
      let currentState = this.state.shippingAddress;
      currentState.fullname = value;
      this.setState({currentState:currentState});
    }else if(name === 'postal_code'){
      let currentState = this.state.shippingAddress;
      currentState.postal_code = value;
      this.setState({currentState:currentState});
    }else if(name === 'd_phone'){
      let currentState = this.state.shippingAddress;
      currentState.phone = value;
      this.setState({currentState:currentState});
    }else if(name === 'country'){
      let currentState = this.state.shippingAddress;
      currentState.country = value;
      this.setState({currentState:currentState});
    }else if(name === 'city'){
      let currentState = this.state.shippingAddress;
      currentState.city = value;
      this.setState({currentState:currentState});
    }else if(name === 'line1'){
      let currentState = this.state.shippingAddress;
      currentState.line1 = value;
      this.setState({currentState:currentState});
    }else if(name === 'line2'){
      let currentState = this.state.shippingAddress;
      currentState.line2 = value;
      this.setState({currentState:currentState});
    } else if (name === "product_" + ref) {
      //manage product changed or not
      this.setState({ isProductChanged: true });
      const { products } = this.state;
      var masterProduct = this.state.productsList.find(
        (item) => item.ref === ref
      );
      //check check or uncheck
      var productExist = products.find((item) => item.value === ref);
      if (productExist) {
        products.splice(products.findIndex(p => p.value === ref), 1)
        this.setState({ products: products });
      } else {
        products.push({
          value: ref,
          title: masterProduct.title,
          rule: '',
          time: '',
          frequency: '',
          qty: 1,
          area: []
        });
        this.setState({ products: products });
      }
    } else if (name === "rule_" + ref) {
      //manage product changed or not
      this.setState({ isProductChanged: true });
      const { products } = this.state;
      var masterProduct = this.state.productsList.find((item) => item.ref === ref);
      let productIndex = products.findIndex((obj => obj.value == ref));
      if (productIndex >= 0) {
        products[productIndex].rule = value;
      } else {
        products.push({
          value: ref,
          rule: value,
          title: masterProduct.title,
          time: '',
          frequency: products[productIndex].frequency ? products[productIndex].frequency : '',
          qty: products[productIndex].qty ? products[productIndex].qty : 1,
          area: products[productIndex].area ? products[productIndex].area : []
        });
      }
      this.setState({ products: products });
    } else if (name === "time_" + ref) {
      this.setState({ isProductChanged: true });
      const { products } = this.state;
      var masterProduct = this.state.productsList.find((item) => item.ref === ref);
      let productIndex = products.findIndex((obj => obj.value == ref));
      if (productIndex >= 0) {
        products[productIndex].time = value;
      } else {
        products.push({
          value: ref,
          rule: '',
          title: masterProduct.title,
          time: value,
          frequency: products[productIndex].frequency ? products[productIndex].frequency : '',
          qty: products[productIndex].qty ? products[productIndex].qty : 1,
          area: products[productIndex].area ? products[productIndex].area : []
        });
      }
      this.setState({ products: products });
    } else if (name === "qty_" + ref) {
      const { products } = this.state;
      var masterProduct = this.state.productsList.find((item) => item.ref === ref);
      let productIndex = products.findIndex((obj => obj.value == ref));
      if (productIndex >= 0) {
        products[productIndex].qty = value;
      } else {
        products.push({
          value: ref,
          title: masterProduct.title,
          qty: value,
          frequency: products[productIndex].frequency ? products[productIndex].frequency : '',
          area: products[productIndex].area ? products[productIndex].area : [],
          time: products[productIndex].time ? products[productIndex].time : '',
          rule: products[productIndex].rule ? products[productIndex].rule : '',
        });
      }
      this.setState({ products: products });
    } else if (name === "frequency_" + ref) {
      const { products } = this.state;
      var masterProduct = this.state.productsList.find((item) => item.ref === ref);
      let productIndex = products.findIndex((obj => obj.value == ref));
      if (productIndex >= 0) {
        products[productIndex].frequency = value;
      } else {
        products.push({
          value: ref,
          title: masterProduct.title,
          frequency: value,
          qty: products[productIndex].qty ? products[productIndex].qty : 1,
          area: products[productIndex].area ? products[productIndex].area : [],
          time: products[productIndex].time ? products[productIndex].time : '',
          rule: products[productIndex].rule ? products[productIndex].rule : '',
        });
      }
      this.setState({ products: products });
    }
  };

  onChangeArea = (value, ref) => {
    const { products } = this.state;
    var masterProduct = this.state.productsList.find((item) => item.ref === ref);
    let productIndex = products.findIndex((obj => obj.value == ref));
    if (productIndex >= 0) {
      products[productIndex].area = value;
    } else {
      products.push({
        value: ref,
        title: masterProduct.title,
        area: value,
        qty: products[productIndex].qty ? products[productIndex].qty : 1,
        frequency: products[productIndex].frequency ? products[productIndex].frequency : '',
        time: products[productIndex].time ? products[productIndex].time : '',
        rule: products[productIndex].rule ? products[productIndex].rule : '',
      });
    }
    this.setState({ products: products });
  };

  handleValidation = () => {
    let {customer, products} = this.state;
    let errors = {};
    let formIsValid = true;

    if(!customer){
      formIsValid = false;
      errors["customer"] = "Customer is required";
    }

    if(!products){
      formIsValid = false;
      errors["products"] = "Products is required";
    }

    products.forEach((val, key) => {
      if (this.state.customerVersion != 2 && (val.rule === "" || val.rule === undefined)) {
        formIsValid = false;
        errors[`${val.value}`] = "Rule is required";
        errors['mainError'] = "Rule is required for each product!";
      } else if (this.state.customerVersion == 2 && (val.frequency === undefined || val.frequency === "")) {
        formIsValid = false;
        errors[`${val.value}`] = "Frequency is required";
        errors["mainError"] = "Frequency is required for each product!";
      } else if (this.state.customerVersion == 2 && (val.area === undefined || val.area.length === 0)) {
        formIsValid = false;
        errors[`${val.value}`] = "Area is required";
        errors["mainError"] = "Area is required for each product!";
      } else if (this.state.customerVersion == 2 && (val.time === undefined || val.time === "")) {
        formIsValid = false;
        errors[`${val.value}`] = "Time is required";
        errors["mainError"] = "Time is required for each product!";
      }
    })

    // const productsQuantity = products.reduce((prev, curr) => prev + Number(curr.qty), 0)

    // if (productsQuantity < this.state.minProductsQty) {
    //   formIsValid = false;
    //   errors['min'] = `Min count of products is ${this.state.minProductsQty}`;
    //   errors["mainError"] = `Min count of products is ${this.state.minProductsQty}!`;
    // } else if (productsQuantity > this.state.maxProductsQty) {
    //   formIsValid = false;
    //   errors['max'] = `Max count of products is ${this.state.maxProductsQty}`;
    //   errors["mainError"] = `Max count of products is ${this.state.maxProductsQty}!`;
    // }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this._isMounted = true;
    this.setState({disabled: true});
    if(this.handleValidation()){
      this.savePackage();
    }else{
      this.setState({disabled: false});
    }
  }

  savePackage = async () => {
    try {
      //fetch ship_by and package_by date
      const shipment = await fb.metaCollection.doc('shipment').get();
      if (shipment.data()) {
        let currentTime = Math.round(new Date().getTime() / 1000);
        const { data: userData } = await api.get('userByID', { params: { userId: this.state.customer } })
        let ref = `LD${Math.round(new Date().getTime() / 1000)}`;
        const p = this.state.products.map(i => ({ ...i, rule: i?.rule || '' }))

        api.post('createPackage', {
          userId: this.state.customer,
          userName: ((userData.data().firstName && userData.data().lastName) && `${userData.data().firstName} ${userData.data().lastName}`) || userData.data().name ||
          ((userData.data().first_name && userData.data().last_name) && `${userData.data().first_name} ${userData.data().last_name}`) || '-',
          userEmail: userData.data().email,
          products: p,
          shippingAddress: this.state.shippingAddress,
          test: this.state.test || "",
          created: fb.timestamp.serverTimestamp(),
          ship_by: new Date((currentTime + shipment.data().ship_by_shift) * 1000),
          package_due: new Date((currentTime + shipment.data().package_due_shift) * 1000),
          status: 'waiting_shipment',
          ref: ref,
          deleted: false,
          treatmentId: userData?.data()?.currentTreatmentID || '',
          type: this.state.type
        }).then(async (res) => {
          // addAdminLog(
          //   'package_created',
          //   `Created the package for the user ${this.state.customer}`,
          //   res.data.insertId,
          //   this.state.customer,
          //   userData.data().name
          // )
          //make a clone if changed something in products
          if (this.state.isProductChanged) {
            this.createTreatmentClone();
          }

          //send email to the admins
          let packageObj = {
            userName: userData.data().name || "",
            status: 'waiting_shipment',
            ref: ref
          }
          await this.sendEmails(packageObj);
          this.props.history.push('/admin/shipment')
        }).catch(err => {
          this.setState({
            error: err.message,
            disabled: false
          })
        });
      }
    } catch (err) {
      this.setState({
        error: err.message,
        disabled: false
      })
    }
  }

  getAdmins = async () => {
    //fetch Admins
    const { data: admins } = await api.get('getAdmins')
    const adminsArr = [];
    admins.forEach(dataObj => {
      if (!dataObj.deleted && dataObj.adminRoles && dataObj.adminRoles.includes("shipment")) {
        adminsArr.push({
          id: dataObj.id,
          name: dataObj.name,
          email: dataObj.email,
          approved: dataObj.approved,
          deleted: dataObj.deleted,
          adminRoles: dataObj.adminRoles
        })
      }
    });
    this.setState({
      admins: adminsArr
    });
  }

  sendEmails = async (packageObj) => {
    let emailsRef = fb.emailsCollection.doc();
    await emailsRef.set({
      id:emailsRef.id,
      userName:packageObj.userName,
      status:packageObj.status,
      ref:packageObj.ref,
      sent:fb.timestamp.serverTimestamp()
    }).then(async snapShot => {
      //sent emails
      this.state.admins && this.state.admins.forEach(async admin=> {
        const sendEmals = await api.get(`s/email/${emailsRef.id}`,{headers: {"Access-Control-Allow-Origin": "*"}})
        console.log('result email', sendEmals)
      });
    }).catch(err=> {
      this.setState({
        error:err.message,
        disabled: false
      })
    });
  }
 
  componentWillUnmount = () => {
    if(this._isMounted){
      this._isMounted = false;
    }
  }

  onProductSelect = (selectedList, selectedItem) => {
    this.setState({products:selectedList})
  }

  createTreatmentClone = async () => {
    let { customer, products } = this.state;
    const { data: user } = await api.get('userByID', { params: { userId: customer } })
    if (user) {
      let currentTreatmentID = user.currentTreatmentID;
      let treatments;
      if (currentTreatmentID) {
        const { data: treatmentsByUserId } = await api.get('getTreatmentsByUserId', { params: { userId: customer } })
        treatments = treatmentsByUserId.find(i => i.id === currentTreatmentID)
      } else {
        const { data: treatmentsByUserId } = await api.get('getTreatmentsByUserId', { params: { userId: customer } })
        treatments = treatmentsByUserId.filter(i => i?.products)
      }
      if (treatments) {
        let pro = {};
        for (let key in products) {
          const data1 = await fb.productsCollection
            .where("ref", "==", products[key].value)
            .get();
          if (data1.docs.length > 0 && data1.docs[0].data()) {
            const dataObj1 = data1.docs[0].data();
            pro[dataObj1.ref] = {
              title: dataObj1.title,
              title_ru: dataObj1.title_ru,
              order: Number(dataObj1.order) || 1,
              rule: products[key].rule || "",
              qty: dataObj1.qty || 1,
              description: this.getProductDescription(dataObj1)['en'],
              description_ru: this.getProductDescription(dataObj1)['ru'],
            };
          }
        }
        let t = treatments;
        delete t.id;
        t.products = pro;
        api.post('createTreatment', {
          user_id: customer,
          ...t
        }).then(async (res) => {
          await api.post('updateUser', {
            id: customer,
            currentTreatmentID: res?.data?.insertId
          });
          this.setState({
            sendDisabled: false,
            successMessage: "User diagnosis copied successfully!",
          });
          setTimeout(() => {
            this.setState({
              successMessage: "",
            });
            // window.location.reload();
          }, 3000);
        }).catch((err) => {
          console.log('err', err)
          this.setState({ errorMessage: err.message });
        });
      } else {
        this.setState(({ products: [] }));
      }
    }
  }


  getProductDescription = (product_data) => {
    console.log('product_data',product_data)
    let picture_slider = ''
    if((product_data.hasOwnProperty('pictures') && product_data.pictures.length > 0) ||
      (product_data.hasOwnProperty('picture') && product_data.picture !== '')){
      picture_slider += '<div class="picture_slider">';
      if(product_data.hasOwnProperty('pictures')){
        for (const picture of product_data.pictures.sort((a,b) => {return a.order - b.order})) {
          if(picture.type && picture.type.includes('video')){
            picture_slider += `<div><video controls><source src="${picture.url}" type="${picture.type}"/></video></div>`;
          }else{
            picture_slider += `<div><img src="${picture.url}" /></div>`;
          }
        }
      }else{
        picture_slider += `<div><img src="${product_data.picture}" /></div>`;
      }
      picture_slider += '</div>';
    }
    return {
      en: `/0/${product_data.title} /1/ /2/${picture_slider} ${product_data.description}<p><b>Instruction:</b></p>${product_data.instruction}/3/`,
      ru: `/0/${product_data.title_ru} /1/ /2/${picture_slider} ${product_data.description_ru}<p><b>Инструкция:</b></p>${product_data.instruction_ru} /3/`
    }
  }

  

  onCustomerSelect = async (selectedItem) => {
    this.setState({ customer: selectedItem })
    const {data: customer} = await api.get('userByID', {params: {userId: selectedItem}})
    if (customer) {
      let currentTreatmentID = customer.currentTreatmentID;
      let treatments;
      if (currentTreatmentID) {
        const { data: treatmentsByUserId } = await api.get('getTreatmentsByUserId', { params: { userId: selectedItem } })
        treatments = treatmentsByUserId.find(i => i.id === currentTreatmentID)
      } else {
        const { data: treatmentsByUserId } = await api.get('getTreatmentsByUserId', { params: { userId: selectedItem } })
        treatments = treatmentsByUserId.filter(i => i?.products)
      }
  
      let shippingAddress = customer.shippingAddress;
      const version = customer.version || '2';
      this.setState({ customerVersion: version })
      if (shippingAddress) {
        this.setState({
          test: customer.test,
          shippingAddress: {
            fullname: shippingAddress.fullname || '',
            email: customer.email || '',
            postal_code: shippingAddress.postal_code || '',
            phone: shippingAddress.phone || '',
            country: shippingAddress.country || '',
            city: shippingAddress.city || '',
            line1: shippingAddress.line1 || '',
            line2: shippingAddress.line2 || ''
          }
        });
      } else {
        this.setState({
          test: "",
          shippingAddress: {
            fullname: '',
            email: '',
            postal_code: '',
            phone: '',
            country: '',
            city: '',
            line1: '',
            line2: ''
          }
        });
      }
      let productsSelected = [];
      if (treatments.exists) {
        const products = treatments.products;
        for (let key in products) {
          productsSelected.push({
            value: key,
            rule: products[key].rule,
            time: products[key].time || products[key].rule || '',
            title: products[key].title,
            qty: products[key].qty || 1,
            frequency: products[key].frequency || '',
            area: products[key].area || [],
          });
        }
        this.setState({
          products: productsSelected,
        });
      } else {
        this.setState(({ products: [] }));
      }
    }
  }

  checkSelectedProduct = (products, ref) => {
    let DBProduct = products.find((item) => item.value === ref);
    if(DBProduct){
      return true;
    }
    return false;
  }

  checkQty = (products, ref) => {
    let DBProduct = products.find((item) => item.value === ref);
    if(DBProduct && DBProduct.qty){
      return DBProduct.qty;
    }
  }

  checkFrequency = (products, ref) => {
    let DBProduct = products.find((item) => item.value === ref);
    if(DBProduct && DBProduct.frequency){
      return DBProduct.frequency;
    }
  }

  checkArea = (products, ref) => {
    let DBProduct = products.find((item) => item.value === ref);
    if(DBProduct && DBProduct.area){
      return DBProduct.area;
    }
  }

  checkSelectedRule = (products, ref) => {
    let DBProduct = products.find((item) => item.value === ref);
    if(DBProduct){
      return this.state.customerVersion == 2 ? DBProduct.time : DBProduct.rule;
    }
    return "";
  }

  productRuleIndicator = (products,ref) => {
    let product = products.find((item) => item.value === ref);
    if(product){
      let rule = this.state.customerVersion == 2 ? product.time : product.rule
      if (rule === 'M&E' || rule === 'M, E'){
        return (<span style={{backgroundColor:'#CDD2FF'}}>M,E</span>)
      }else if(rule === 'M'){
        return (<span style={{backgroundColor:'#FFEBCD'}}>M</span>)
      }else{
        return (<span style={{backgroundColor:'#CDE7FF'}}>E</span>)
      }
    }else{
      return (<span/>)
    }
  }

                                                    
  
  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Add Package"
                button={
                  <Link to={`/admin/shipment`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-12">
                        <h4>Delivery Address</h4>
                        <Row>
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>Customer</FormLabel>
                              <SelectSearch
                                options={this.state.customersList}
                                search={true}
                                value={this.state.customer}
                                name="customer"
                                placeholder="Select Customer"
                                onChange={(event)=>this.onCustomerSelect(event)}
                              />
                              <span className="help-block">{this.state.errors.customer}</span>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>Full Name</FormLabel>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                name="d_name"
                                value={this.state.shippingAddress.fullname}
                                onChange={(event)=>this.onChangeHandler(event)}
                              />
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>Appartment</FormLabel>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Appartment"
                                name="line2"
                                value={this.state.shippingAddress.line2}
                                onChange={(event)=>this.onChangeHandler(event)}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>Phone</FormLabel>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone"
                                name="d_phone"
                                value={this.state.shippingAddress.phone}
                                onChange={(event)=>this.onChangeHandler(event)}
                              />
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>Country</FormLabel>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                name="country"
                                value={this.state.shippingAddress.country}
                                onChange={(event)=>this.onChangeHandler(event)}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>City</FormLabel>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                name="city"
                                value={this.state.shippingAddress.city}
                                onChange={(event)=>this.onChangeHandler(event)}
                              />
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>Street</FormLabel>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Street"
                                name="line1"
                                value={this.state.shippingAddress.line1}
                                onChange={(event)=>this.onChangeHandler(event)}
                              />
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>ZIP</FormLabel>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="ZIP"
                                name="postal_code"
                                value={this.state.shippingAddress.postal_code}
                                onChange={(event)=>this.onChangeHandler(event)}
                              />
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>Type</FormLabel>
                              <select
                                className="form-control"
                                name="type"
                                value={this.state.type}
                                onChange={(event)=>this.setState({type: event.target.value})}
                              >
                                <option value="rb">Red Box</option>
                                {/* <option value="qc">Quintessential Collection</option> */}
                                <option value="ds">Discovery Set</option>
                                <option value="ip">Individual Products</option>
                              </select>
                            </FormGroup>
                          </div>
                        </Row>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <Row className="add_wrap">
                          <div className="col-md-12">
                              <FormGroup>
                                <FormLabel className="block_heading">
                                  <strong>Products</strong>
                                </FormLabel>
                                {this.state.productsList &&
                                  this.state.productsList.map((val, key) => {
                                    return (
                                      <div key={key}>
                                        <div className="border_bottom">
                                          <Row className="wrap_border_product1">
                                            <div className="product_check">
                                              {" "}
                                              <input
                                                type="checkbox"
                                                className="mr-5 "
                                                id={"product_" + val.ref}
                                                name={"product_" + val.ref}
                                                value={val.ref}
                                                checked={this.checkSelectedProduct(this.state.products, val.ref)}
                                                onChange={(event) =>
                                                  this.onChangeHandler(
                                                    event,
                                                    val.ref
                                                  )
                                                }
                                              />
                                              <label
                                                htmlFor={"product_" + val.ref}
                                                className="template_type label_wrap"
                                              >
                                                {val.title}
                                              </label>
                                            </div>
                                            <div className="product_treatment">
                                              {this.state.customerVersion == 2 && <div className="product-title">Time</div>}

                                              <div className="treatment_product_rule_wrapper">
                                                <div className="treatment_product_rule_indicator">
                                                  {this.productRuleIndicator(this.state.products,val.ref)}
                                                </div>
                                              <select
                                                className="form-control"
                                                  name={this.state.customerVersion == 2 ? "time_" + val.ref : "rule_" + val.ref}
                                                  value={this.checkSelectedRule(this.state.products, val.ref)}
                                                  onChange={(event) =>
                                                    this.onChangeHandler(
                                                      event,
                                                      val.ref
                                                    )
                                                  }
                                                >
                                                  {this.state.customerVersion == 2 ?
                                                    <>
                                                      <option value="">Select Time</option>
                                                      <option value="M">M</option>
                                                      <option value="E">E</option>
                                                      <option value="M&E">M&E</option>
                                                    </> :
                                                    treatmentTimeCases().map(
                                                      (treatmentTime, index) => {
                                                        return (
                                                          <option
                                                            key={index}
                                                            value={treatmentTime.key}
                                                          >
                                                            {treatmentTime.key}
                                                          </option>
                                                        );
                                                      }
                                                    )
                                                  }
                                                </select>
                                              </div>
                                            </div>
                                            <div className="product_time">
                                              {this.state.customerVersion == 2 && <div className="product-title">Quantity</div>}

                                              <CustomNumberField
                                                  onChange={(event) =>
                                                    this.onChangeHandler(
                                                        event,
                                                        val.ref
                                                    )
                                                  }
                                                  min="0"
                                                  max="10"
                                                  name={"qty_" + val.ref}
                                                  value={
                                                    this.checkQty(this.state.products, val.ref)  && this.checkQty(this.state.products, val.ref)
                                                  }
                                              />
                                            </div>
                                            {this.state.customerVersion == 2 &&
                                              <div className="product_frequency">
                                                {this.state.customerVersion == 2 && <div className="product-title">Frequency</div>}

                                                <select
                                                  className="form-control"
                                                  name={"frequency_" + val.ref}
                                                  value={
                                                    this.checkFrequency(this.state.products, val.ref) && this.checkFrequency(this.state.products, val.ref)
                                                  }
                                                  onChange={(event) =>
                                                    this.onChangeHandler(
                                                      event,
                                                      val.ref
                                                    )
                                                  }
                                                >
                                                  <option value="">Select Frequency</option>
                                                  <option>ED</option>
                                                  <option>E2D</option>
                                                  <option>E3D</option>
                                                  <option>E7D</option>
                                                  <option>E10D</option>
                                                  <option>E14D</option>
                                                </select>
                                              </div>
                                            }
                                          </Row>
                                          {this.state.customerVersion == 2 &&
                                            <Row>
                                              <div className="product_area">
                                                <Multiselect
                                                  options={areaOptions}
                                                  selectedValues={
                                                    this.checkArea(this.state.products, val.ref) && this.checkArea(this.state.products, val.ref)
                                                  }
                                                  onSelect={(value) => this.onChangeArea(value, val.ref)}
                                                  onRemove={(value) => this.onChangeArea(value, val.ref)}
                                                  displayValue="val"
                                                  showCheckbox={true}
                                                  placeholder="Select Area"
                                                  closeOnSelect={false}
                                                />
                                              </div>
                                            </Row>
                                          }
                                          {this.state.errors[val.ref] && <span className="help-block">{this.state.errors[val.ref]}</span>}
                                        </div>
                                      </div>
                                    );
                                  })}
                              </FormGroup>
                          </div>
                        </Row>
                      </div>
                    </Row>

                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Loading...' : 'Add Package'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AddPackage;
