import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import logo from "assets/img/logo.png"

class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: window.innerWidth,
      navigationToggle:false,
      roles:[]
    }
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : ""
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth })
  }
  componentDidMount() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions.bind(this))
  }

  componentWillReceiveProps = async (newProps) => {
    if (newProps.path) {
      this.setState({
        roles:newProps.roles,
      })
    }
  }

  navigationToggle = () => {
    this.setState({
      navigationToggle: !this.state.navigationToggle
    })
  }
  
  render() {
    // console.log('sidebar state', this.state)
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")"
    }
    return (
      <div
        id="sidebar"
        className={`sidebar ${this.state.navigationToggle ? 'closed' : '' } `}
        data-color={this.props.color}
        data-image={this.props.image}
      >
          {/* {this.props.hasImage ? (
            <div className="sidebar-background" style={sidebarBackground} />
          ) : (
            null
          )} */}
        <div className="logo">
          <NavLink
            to="/admin/dashboard"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              <img src={logo} alt="logo_image" />
            </div>
          </NavLink>
          <NavLink
            className="simple-text logo-normal"
            to="/admin/dashboard"
          >
            L&D Control
          </NavLink>
          <div className="sidebar-toggler" onClick={this.navigationToggle} style={{cursor:'pointer'}}>
            <i className="fa fa-chevron-left"></i> <i className="fa fa-chevron-right"></i>
          </div>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
            {this.props.routes.map((prop, key) => {
              if(prop.invisible) return null
              if (!prop.redirect)
                return (
                  this.state.roles && this.state.roles.length > 0 && this.state.roles.includes(prop.path.split('/')[1]) ? <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : this.activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                    <OverlayTrigger
                      key={prop.name}
                      placement="right"
                      overlay={
                        <Tooltip id={`tooltip-${prop.name}`}>
                          <strong>{prop.name}</strong>
                        </Tooltip>
                      }
                    >
                      <i className={prop.icon} />
                    </OverlayTrigger>
                        <p>{prop.name}</p>
                      </NavLink>
                  </li>
                  :
                  null
                )
              return null
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default Sidebar
