import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Card } from "../components/Card/Card.jsx";


class NoAccess extends Component {
  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="No Access"
                content={
                    <Row>
                      <div className="col-md-12">
                        <h3>Checkimg permissions...</h3>
                      </div>
                    </Row>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default NoAccess;
