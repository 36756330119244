import React, { Component } from "react";
import { staticWebImages } from "../../components/Utils/images";
import * as fb from "../../firebase";
import Loading from "./Loading";
import { Link } from "react-router-dom";
import { langText } from "../../components/Utils/helper";
import api from "api";


class AcceptPrivacy extends Component {
  constructor(props){
    super(props);
    this.state = {
      userId:"",
      isNotification:false,
      isTerms:false,
      errors:{},
      disabled: false,
      titleText: '',
      contentText: '',
      rulesFirst: '',
      rulesSecond: '',
      rulesFirstLink: '',
      rulesSecondLink: '',
      submitLabel: '',
      isLoading:false,
    }
  }

  onChangeHandlerIsNotification = async (event) => {
    this.setState({isNotification:!this.state.isNotification});
  };

  onChangeHandlerIsTerms = async (event) => {
    this.setState({isTerms:!this.state.isTerms});
  };

  async componentDidMount() {
    let langTextObj = await langText();
    this.setState({
      titleText: langTextObj.auth_rules_title,
      contentText: langTextObj.auth_rules_detail,
      rulesFirst: langTextObj.auth_rules_first_check,
      rulesSecond: langTextObj.auth_rules_second_check.split(langTextObj.auth_rules_first_link)[0],
      submitLabel: langTextObj.auth_rules_button,
      rulesFirstLink: langTextObj.auth_rules_first_link,
      rulesSecondLink: langTextObj.auth_rules_second_link,
    });
    // Confirm the link is a sign-in with email link.
    if (fb.auth.isSignInWithEmailLink(window.location.href)) {
      var email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt('Please provide your email for confirmation');
      }
      fb.auth.signInWithEmailLink(email, window.location.href)
          .then(async (result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            //set user login true
            window.localStorage.setItem('userLogin', "yes");
            window.localStorage.setItem('userId', result.user.uid);
            this.setState({
              userId: result.user.uid
            });
            const user = await fb.usersCollection.doc(result.user.uid).get();

            if (user.exists) {
              //here needs to check the current status of existing user
              if (user.data().status !== "registered") {
                this.setState({isLoading: false});
                this.doRoute(user.data())
              } else {
                this.setState({isLoading: true});
              }
            } else {

              let uid = result.user.uid;
              let language = window.localStorage.getItem('language');
              if(!language){
                let userLang = navigator.language || navigator.userLanguage;
                language = userLang === 'ru' ? 'RU' : 'EN'
              }
              //create user collection
              fb.usersCollection.doc(uid).set({
                userID: result.user.uid,
                email: result.user.email,
                status: "registered",
                role_id: 1,
                deleted: false,
                created: fb.timestamp.serverTimestamp(),
                test: result.user.email.includes('libi.com'),
                language: language
              }).then(res => {
                this.setState({
                  isLoading: true
                });
              }).catch(err => {
                this.setState({isLoading: true});
              });
              api.get("s/new_refncode")
                .then((result) => {
                  fb.usersCollection.doc(uid).update({
                    invite_code: result.data.code,
                    ref: result.data.ref
                  }).then(res => {
                    this.setState({
                      isLoading: true
                    });
                  }).catch(err => {
                    this.setState({ isLoading: true });
                  });
                },
                  (error) => {
                  }
                )
            }
          }).catch((error) => {
        console.log('error.message', error.message)
      });
    } else {
      this.setState({isLoading: true});
    }
  }

  doRoute = (user_data) => {
    switch(user_data.status){
      case 'registered':
        this.props.history.push('/accept-privacy');
      break;

      case 'waiting_list':
        this.props.history.push('/invitation');
      break;

      case 'questionnaire1':
        this.props.history.push('/questionnaire')
      break;

      case 'deposit_vip':
        this.props.history.push('/deposit-vip')
      break;

      case 'waiting_diagnose':
        if(user_data.paymentPlanActive){
          this.props.history.push('/thankyou-diagnose')
        }else{
          this.props.history.push('/deposit')
        }
        break;

      case 'waiting_delivery':
        this.props.history.push('/after-payment')
        break;

      case 'waiting_payment':
        this.props.history.push('/skincare-plan')
        break;

      default:
        alert("Something went wrong! Please try after some time.")
      break;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({disabled: true});
    const changeUserStatus = fb.functions.httpsCallable('changeUserStatus');
    changeUserStatus({uid:this.state.userId,status:'waiting_list'}).then(res => {
      this.props.history.push('/invitation')
    }).catch(err=> {
      this.setState({
        disabled: false
      });
    });
  }

  privacy = () => {
    window.open("https://libi.com/privacy-policy/", "_blank");
  }

  terms = () => {
    window.open("https://libi.com/terms-conditions/", "_blank");
  }

  render() {
    console.log('render', this.state)
    return (
        <section className="Subscription" style={{background: 'rgba(238, 221, 211, 0.6)'}}>
          {this.state.isLoading ?
              <div>
                <div className="container_5-fluid">
                  <div className="row_5">
                    <div className="col_5-lg-6 col_5-12 hidden-xs hidden-sm" style={{padding: 0}}>
                      <div className="Subscription_cust" style={{background: 'transparent'}}>
                        <img src={staticWebImages.depost} alt="" style={{width: "100%", maxWidth: "530px"}}/>
                      </div>
                    </div>
                    <div className="col_5-lg-6 col_5-12">
                      <div className="subs_mainBox">
                        <div className="subsRightBoxWrapper">
                          <div className="subsRightBox">
                            <div className="main-logo">
                              <img src={staticWebImages.logo} alt=""
                                   className="img-responsive center-block web-logo-hide"/>
                            </div>
                            <h2 className="main_txt_sign text-center" style={{paddingBottom: '20px'}}>{this.state.titleText}</h2>
                            <p className="shiping_para">{this.state.contentText}</p>
                            <div className="form-section_add">
                              <form onSubmit={this.handleSubmit} className="d-flex justify-content-center align-items-center">
                                <p className="alrt_txt1 ">
                                  {
                                    this.state.isNotification ?
                                        <img
                                            src={staticWebImages.check}
                                            onClick={this.onChangeHandlerIsNotification}
                                            style={{cursor:"pointer"}} />
                                        :
                                        <i
                                            className="fa fa-circle-thin accept-red-color"
                                            onClick={this.onChangeHandlerIsNotification}>
                                        </i>
                                  } {this.state.rulesFirst}</p>

                                <p className="alrt_txt1">{
                                  this.state.isTerms ?
                                      <img
                                          src={staticWebImages.check}
                                          onClick={this.onChangeHandlerIsTerms}
                                          className="alrt-img"
                                          style={{cursor:"pointer"}} />
                                      :
                                      <i
                                          className="fa fa-circle-thin accept-red-color"
                                          onClick={this.onChangeHandlerIsTerms}>
                                      </i>
                                } {this.state.rulesSecond} <b><Link to={`#`} onClick={this.privacy} >{this.state.rulesFirstLink}</Link></b> &amp; <b><Link to={`#`} onClick={this.terms}>{this.state.rulesSecondLink}</Link></b> </p>
                                <button
                                    type="submit"
                                    className="form-control custom-btn-web"
                                    disabled={!this.state.isTerms || this.state.disabled}
                                >
                                  {this.state.disabled ? 'Loading...' : this.state.submitLabel}
                                </button>
                              </form>
                            </div>
                          </div>
                        </div>
                        <p className="new_copyright mt-auto"> © 2020 Libi&amp;Daughters </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <Loading />
          }
        </section>
    );
  }
}

export default AcceptPrivacy;
