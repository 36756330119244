import React, { Component } from "react";


import { staticWebImages } from "../../components/Utils/images";


class Download extends Component {
  constructor(props){
    super(props);
    this.state = {
      customer:"",
      error:"",
      errors:{},
      disabled : false
    }
    this._isMounted = false;
  }

  downloadLink = () => {
    window.open("https://apps.apple.com/us/app/l-d-my-skincare/id1542599721", "_blank");
  }

  render() {
    console.log('render', this.state)
    return (
      <div className="deposit downloadMain">
        <section className="Sign_Home waitingMain deposit py-5">
          <span className="custm-angle d-none"><i className="fa fa-angle-left" aria-hidden="true" />
          </span>
          <div className="container-fluid col-md-10 mx-auto">
            <div className="row">
              <div className="col-md-6 col-12 static-on-mobile ">
                <div className="mainImgBox downloadMainImg no-trans-mobile">
                  <img src={staticWebImages.downloadBanner} className="img-responsive" />
                </div>
              </div>
              <div className="col-md-6 col-12 align-V-center">
                <div className="deposit_main_Sec DownloadContainer ">
                  <div className="main-logo">
                    <img src={staticWebImages.logo} className="img-responsive center-block web-logo-hide" />
                  </div>
                  <h2 className="main_txt ">Welcome to Libi &amp; Daughters <span className="MobileText">My Skincare</span></h2>
                  
                  <p className="sec_text pop-mb-30">The last step is left, you need to download the <span className="MobileText"> application, you need to click on the button and go to</span> the app store.  </p>
                  
                  <button className="DownloadBtn" onClick={this.downloadLink}> <i className="fa fa-apple" /> <span className="downloadTxt"><small>Download From</small> </span><span className="d-block">Apple app Store</span></button>
                </div>
                <p className="copyright"> © 2020 Libi&amp;Daughters </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Download;
