import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { Link } from "react-router-dom"; 
import * as fb from '../../firebase';
import {addAdminLog} from "../../components/Helper";

class EditMeta extends Component {

  constructor(props){
    super(props);
    this.state = {
      id:"",
      key:"",
      lng:"",
      value:"",
      category:"",
      old_category:"",
      categoryOptions: [
        {id:'txt',name:'Text'},
        {id:'notifications_txt',name:'Notifications'},
        {id:'questionnaire',name:'Questionnaire'},
        {id:'instruction_txt',name:'Instruction'},
        {id:'portal_txt',name:'Portal'},
      ],
      disabled : false,
      error:"",
      errors:{}
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'value') {
      this.setState({
        value:value
      });
    }
    if(name === 'category') {
      this.setState({
        category:value
      });
    }
  };

  componentDidMount = async () => {
    const  url = window.location.href;
    const slug = url.split('/')[7];
    const category = url.split('/')[6];
    const key = slug.split("?")[0];

    const search = this.props.location.search;
    const lng = new URLSearchParams(search).get('lng');
    let doc;
    if(lng==="en"){
      doc = category;
    }else if(lng==="ru"){
      doc = `${category}_ru`;
    }
    const metadata = await fb.metaCollection.doc(doc).get();
    let value = "";
    if(key === "weeklyDays"){
      value = metadata.data()[key].join();
    }else{
      value = metadata.data()[key];
    }
    this.setState({
      key:key,
      lng:lng,
      value:value,
      category:category,
      old_category:category,
      isLoading:false
    });
  
  }

  handleValidation = () => {
    let {key, value, category} = this.state;
    let errors = {};
    let formIsValid = true;

    //Title
    if(!key){
      formIsValid = false;
      errors["key"] = "Key is required";
    }

    //Description
    if(!value){
      formIsValid = false;
      errors["value"] = "Value is required";
    }

    if(!category){
      formIsValid = false;
      errors["category"] = "Category is required";
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this._isMounted = true;
    this.setState({disabled: true});
    if(this.handleValidation(this.state.errors)){
      let doc;
      let old_doc;
      if(this.state.lng==="en"){
        doc = this.state.category;
        old_doc = this.state.old_category;
      }else if(this.state.lng==="ru"){
        doc = `${this.state.category}_ru`;
        old_doc = `${this.state.old_category}_ru`;
      }
      if(this.state.category !== this.state.old_category){
        await fb.metaCollection.doc(old_doc).update({
          [this.state.key]:fb.firebase.firestore.FieldValue.delete()
        })
      }
      await fb.metaCollection.doc(doc).update({
        [this.state.key]:this.state.key==="weeklyDays" ? this.state.value.split(",").map(Number) : this.state.value
      }).then(snapShot => {
        addAdminLog('meta_changed',
          `Changed the meta ${this.state.key} at the ${this.state.category}`)
        this.props.history.push('/admin/meta')
      }).catch(err=> {
        this.setState({
          error:`Something went wrong-${err}`,
          disabled: false
        });
      });
    }else{
      this.setState({
        disabled: false
      });
    }
  }

  

  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Edit Meta Text"
                button={
                  <Link to={`/admin/meta`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Key</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Key"
                            name="key"
                            value= {this.state.key}
                            onChange = {(event) => this.onChangeHandler(event)}
                            disabled={true}
                          />
                          <span className="help-block">{this.state.errors.key}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Value</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Value"
                            name="value"
                            value= {this.state.value}
                            onChange ={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.value}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Category</FormLabel>
                          <select
                            className="form-control"
                            name="category"
                            value={this.state.category}
                            onChange={(event) => this.onChangeHandler(event)}
                          >
                            <option value="">Select category</option>
                            {
                              this.state.categoryOptions.map((cat, index) => {
                                return <option key={index} value={cat.id}>{cat.name}</option>
                              })
                            }
                          </select>
                          <span className="help-block">{this.state.errors.category}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Loading...' : 'Update'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditMeta;
