import React, { Component } from "react"
import * as fb from "../../firebase"
import Loading from "./Loading"
import {ReactComponent as TypeFormCheck} from '../../web_assets/img/typeFormCheck.svg'
import {staticWebImages} from "../../components/Utils/images";
import ShortText from "./TypeForm/ShortText";
import Dropdown from "./TypeForm/Dropdown";
import MultipleChoice from "./TypeForm/MultipleChoice";
import TypeFormDate from "./TypeForm/TypeFormDateField";
import FileUpload from "./TypeForm/FileUpload";
import TypeFormPhone from "./TypeForm/TypeFormPhone";
import LongText from "./TypeForm/LongText";
import TypeFormRating from "./TypeForm/TypeFormRating";
import PopupImage from "./TypeForm/PopupImage";
import  './TypeForm/TypeForm.scss';
import WelcomeScreen from "./TypeForm/WelcomeScreen";
import { withRouter } from "react-router-dom";

class TypeForm extends Component {
    constructor(props){
        super(props)
        this.state = {
            error:"",
            errors:"",
            disabled: false,
            isLoading: false,
            questionsJson: {},
            theme: {
                "id": "wIhrnK",
                "font": "Montserrat",
                "name": "Plain Raspberry (copy)",
                "has_transparent_button": false,
                "colors": {
                    "question": "#686262",
                    "answer": "#92001A",
                    "button": "#92001A",
                    "background": "#E9D5CB"
                },
                "visibility": "private",
                "screens": {
                    "font_size": "x-small",
                    "alignment": "center"
                },
                "fields": {
                    "font_size": "medium",
                    "alignment": "left"
                }
            },
            currentScreenId: '',
            currentScreenObj: {},
            currentQuestionIndex: 0,
            currentQuestionGroupIndex: null,
            currentScreenGroupObj: {},
            vCurrentScreenId: '',
            vCurrentScreenObj: {},
            vCurrentQuestionIndex: 0,
            vCurrentQuestionGroupIndex: null,
            vCurrentScreenGroupObj: {},
            answerObj: {
                event_id: "01FHFFGAVCPTCJ0NAEZJ93K1ET",
                event_type: "form_response",
                form_response: {
                    definition: {},
                    answers: {}
                }
            },
            answerValue: null,
            uid: '',
            userData: null,
            expertData: null,
            mark: '',
            autofile: ''
        }
        this._isMounted = false
    }

    componentDidMount = async () => {
        if(!fb.firebase.auth().currentUser){
            await fb.auth.signInWithEmailAndPassword('maxim+questionnaire@libi.com', '$12345678').then(res => {
            }).catch(error => {
                console.error("Error signing in with password and email", error);
            });
        }
        let query = await new URLSearchParams(this.props.location.search)
        let mark = query.get("mark").toLowerCase()
        let uid = query.get("uid")
        let autofile = query.get("autofile")
        if(!uid || !mark) return console.log('params are not set')
        let user = await fb.usersCollection.doc(uid).get();
        if(!user.exists) return console.log('uid query param is wrong')
        this.setState({userData: user.data()})
        let userLang = user.data().language || 'EN'
        let langTitle = userLang === 'RU' ? '_ru' : ''
        let expert = await fb.usersCollection.doc(user.data().expertId).get();
        if(expert.exists) this.setState({expertData: expert.data()})
        if (mark === 'photos') {
            this.setState({uid: uid, mark: mark, isLoading: true})
            return
        }
        let metaQuestionnaire = await fb.metaCollection.doc('questionnaire_new').get();
        if(metaQuestionnaire.data().hasOwnProperty(mark+'_json'+langTitle)){
            let questionData = JSON.parse(metaQuestionnaire.data()[mark+'_json'+langTitle])
            await this.setState({
                uid: uid,
                mark: mark,
                autofile: autofile,
                questionsJson: questionData
            })
            this.setState(prevState => ({
                answerObj: {
                    ...prevState.answerObj,
                    form_response: {
                        ...prevState.answerObj.form_response,
                        form_id: questionData.id,
                        landed_at: new Date().toISOString(),
                        hidden: {
                            mark: mark,
                            uid: uid
                        },
                        definition: {
                            id: questionData.id,
                            title: questionData.title,
                            fields: []
                        },
                        answers: []
                    }
                }
            }))
            // this.loadScreen('welcome_screens')
            // this.loadScreen(this.state.questionsJson.fields[9].ref)
        }else{
        //    TODO show some error about questionData is not exist
            console.log('Questions data is not exist')
            return
        }
        this.setState({
            isLoading: true
        })
        var self = this
        window.photosUrls = async function (sBinaryParam) {
            var sDecodedParam = window.atob(sBinaryParam);
            var oData = JSON.parse(sDecodedParam);
            console.log('window.photosUrls', oData)
            // get images from oData
            let images = Object.values(oData)
            console.log('images',images)
            await self.setState({
                vCurrentScreenId: self.state.currentScreenId,
                vCurrentScreenObj: self.state.currentScreenObj,
                vCurrentQuestionIndex: self.state.currentQuestionIndex,
                vCurrentScreenGroupObj: self.state.currentScreenGroupObj,
                vCurrentQuestionGroupIndex: self.state.currentQuestionGroupIndex
            })
            let nextScreen = self.state.vCurrentScreenObj
            let screenIndex = 0
            while(nextScreen.hasOwnProperty('type') && nextScreen.type === 'file_upload'){
                if(images[screenIndex] !== undefined){
                    let type = 'file_url'
                    let answers = self.state.answerObj.form_response.answers
                    let definitionFields = self.state.answerObj.form_response.definition.fields
                    answers.push({
                        type: type,
                        [type]: images[screenIndex],
                        field: {
                            id: self.state.vCurrentScreenObj.id,
                            type: self.state.vCurrentScreenObj.type,
                            ref: self.state.vCurrentScreenObj.ref
                        }
                    })
                    let newDefinitionField = {
                        id: self.state.vCurrentScreenObj.id,
                        title: self.state.vCurrentScreenObj.title,
                        type: self.state.vCurrentScreenObj.type,
                        ref: self.state.vCurrentScreenObj.ref,
                        properties: {},
                    }
                    definitionFields.push(newDefinitionField)
                    // saving new answer
                    await self.setState(prevState => ({
                        answerObj: {
                            ...prevState.answerObj,
                            form_response: {
                                ...prevState.answerObj.form_response,
                                definition: {
                                    ...prevState.answerObj.form_response.definition,
                                    fields: definitionFields
                                },
                                answers: answers
                            }
                        }
                    }))
                }
                nextScreen = await self.getNextScreenByLogic()
                screenIndex++
            }
            await self.setState({
                currentScreenId: self.state.vCurrentScreenId,
                currentScreenObj: self.state.vCurrentScreenObj,
                currentQuestionIndex: self.state.vCurrentQuestionIndex,
                currentScreenGroupObj: self.state.vCurrentScreenGroupObj,
                currentQuestionGroupIndex: self.state.vCurrentQuestionGroupIndex
            })
            return 'true'
        }
    }

    async loadScreen(screenId) {
        let currentScreenObj = {}
        let currentQuestionIndex = 0
        if (screenId === 'welcome_screens') {
            if(this.state.questionsJson.hasOwnProperty('welcome_screens')){
                currentScreenObj = this.state.questionsJson.welcome_screens[0]
            }else{
                this.loadScreen(this.state.questionsJson.fields[0].ref)
                return
            }
        } else if (screenId === 'thankyou_screens') {
            currentScreenObj = this.state.questionsJson.thankyou_screens[0]
            this.props.history.push('/deposit')
        } else if (screenId === 'next') {
            console.log('loading next screen')
            if (this.state.currentScreenObj.type === 'group') {
                console.log('current is group')
                await this.setState({
                    currentScreenGroupObj: this.state.currentScreenObj,
                    currentQuestionGroupIndex: this.state.currentQuestionIndex
                })
                console.log('load first screen of group')
                this.loadScreen(this.state.currentScreenObj.properties.fields[0].ref)
            } else {
                console.log('current is not group')
                if (this.state.currentQuestionGroupIndex !== null) {
                    console.log('current is subquestion')
                    if (this.state.currentScreenGroupObj.properties.fields.length > this.state.currentQuestionIndex + 1) {
                        console.log('load next subquestion')
                        this.loadScreen(this.state.currentScreenGroupObj.properties.fields[this.state.currentQuestionIndex + 1].ref)
                    } else {
                        let groupIndex = this.state.currentQuestionGroupIndex
                        await this.setState({
                            currentScreenGroupObj: {},
                            currentQuestionGroupIndex: null
                        })
                        console.log('load from subquestion next question')
                        this.loadScreen(this.state.questionsJson.fields[groupIndex + 1].ref)
                    }
                } else {
                    console.log('load next question')
                    this.loadScreen(this.state.questionsJson.fields[this.state.currentQuestionIndex + 1].ref)
                }
            }
            return
        } else {
            console.log('loading exactly screen',this.state.currentQuestionGroupIndex);
            if (this.state.currentQuestionGroupIndex !== null) {
                console.log('it is subquestion')
                currentScreenObj = this.state.currentScreenGroupObj.properties.fields.find(x => x.ref === screenId)
                currentQuestionIndex = this.state.currentScreenGroupObj.properties.fields.findIndex(x => x.ref === screenId)
                console.log('question in group')
                if(currentQuestionIndex === -1){
                    await this.setState({
                        currentScreenGroupObj: {},
                        currentQuestionGroupIndex: null
                    })
                    currentScreenObj = this.state.questionsJson.fields.find(x => x.ref === screenId)
                    currentQuestionIndex = this.state.questionsJson.fields.findIndex(x => x.ref === screenId)
                    console.log('question is out of group',currentScreenObj)
                }
            }else{
                console.log('it is question')
                currentScreenObj = this.state.questionsJson.fields.find(x => x.ref === screenId)
                currentQuestionIndex = this.state.questionsJson.fields.findIndex(x => x.ref === screenId)
            }
        }
        await this.setState({
            currentScreenId: screenId,
            currentScreenObj: currentScreenObj,
            currentQuestionIndex: currentQuestionIndex
        })
        console.log('currentScreenObj',currentScreenObj)
        if(this.state.currentScreenObj.type === 'file_upload' && this.state.autofile){
            // Calculate quantity of photo question by jump rules and send to mobile
            await this.setState({
                vCurrentScreenId: screenId,
                vCurrentScreenObj: currentScreenObj,
                vCurrentQuestionIndex: currentQuestionIndex,
                vCurrentScreenGroupObj: this.state.currentScreenGroupObj,
                vCurrentQuestionGroupIndex: this.state.currentQuestionGroupIndex
            })
            let fileScreensCount = 1
            let nextScreen = await this.getNextScreenByLogic()
            while(nextScreen.hasOwnProperty('type') && nextScreen.type === 'file_upload'){
                fileScreensCount++
                nextScreen = await this.getNextScreenByLogic()
            }
            window.callNative(JSON.stringify({photos: fileScreensCount}))
            console.log('fileScreensCount',fileScreensCount)
            // this only for debug
            // let dataFromMobile = {}
            // for (let i = 1;i<=fileScreensCount;i++){
            //     dataFromMobile[`photo${i}`] = `https://some.photo${i}`
            // }
            // window.photosUrls(window.btoa(JSON.stringify(dataFromMobile)))
            // END this only for debug
            this.fileUploadRef.click()
        }
    }

    isNotLastQuestion(){
        if(this.state.currentQuestionGroupIndex !== null){
            if(this.state.questionsJson.fields.length > this.state.currentQuestionGroupIndex+1){
                return true
            }else{
                return this.state.currentScreenGroupObj.properties.fields.length > this.state.currentQuestionIndex+1
            }
        }else if(this.state.currentScreenObj.type === 'group' && this.state.currentScreenObj.properties.fields.length > 0){
            return true
        }else{
            return this.state.questionsJson.fields.length > this.state.currentQuestionIndex+1
        }
    }

    async submitAction(e) {
        this.setState({isLoading: false})
        if (this.state.currentScreenId === 'welcome_screens') {
            this.loadScreen(this.state.questionsJson.fields[0].ref)
        } else {
            if (this.hasRequired() && !this.state.answerValue) {
                this.setState({errors: 'This field is required'})
                this.setState({isLoading: true})
                return
            }
            if (this.hasLimit() && (this.state.answerValue > this.state.currentScreenObj.validations.max_value || this.state.answerValue < this.state.currentScreenObj.validations.min_value)) {
                this.setState({errors: `Please enter a number between ${this.state.currentScreenObj.validations.min_value} and ${this.state.currentScreenObj.validations.max_value}`})
                this.setState({isLoading: true})
                return
            }
            if(this.state.currentScreenObj.ref === 'name'){
                if(this.state.answerValue.match(/[^a-zA-Z -]/g) !== null){
                    this.setState({errors: 'This field contains non-Latin characters'})
                    this.setState({isLoading: true})
                    return
                }
                this.setState({answerValue: this.state.answerValue.trim()})
            }
            let type = this.state.currentScreenObj.type
            // field type transformation
            if(type === 'short_text' || type === 'long_text') type = 'text'
            if(type === 'rating') type = 'number'
            if(type === 'dropdown') type = 'choice'
            if(type === 'multiple_choice'){if(this.state.currentScreenObj.properties.allow_multiple_selection){ type = 'choices' }else{ type = 'choice'}}
            if(type === 'file_upload'){
                type = 'file_url'
                if(this.state.answerValue){
                    let timestamp = Math.floor(Date.now() / 1000);
                    let position = this.state.answerObj.form_response.answers.length
                    fb.storage.child(`questionnaire/${timestamp}-${this.state.answerValue.name}`).put(this.state.answerValue)
                        .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {
                            // this.setState({answerValue: url})
                            let answers = this.state.answerObj.form_response.answers
                            answers[position]['file_url'] = url
                            this.setState(prevState => ({
                                answerObj: {
                                    ...prevState.answerObj,
                                    form_response: {
                                        ...prevState.answerObj.form_response,
                                        answers: answers
                                    }
                                }
                            }))
                        });
                }
            }
            if(type === 'yes_no'){
                type = 'boolean'
                await this.setState({answerValue: this.state.answerValue.ref})
            }
            if(type !== 'group'){
                // creating new answer
                let answers = this.state.answerObj.form_response.answers
                let definitionFields = this.state.answerObj.form_response.definition.fields
                let index = answers.findIndex(x => x.field.ref === this.state.currentScreenObj.ref)
                if(index !== -1){
                    answers.splice(index, 1)
                    definitionFields.splice(index, 1)
                }
                answers.push({
                    type: type,
                    [type]: this.state.answerValue,
                    field: {
                        id: this.state.currentScreenObj.id,
                        type: this.state.currentScreenObj.type,
                        ref: this.state.currentScreenObj.ref
                    }
                })
                let newDefinitionField = {
                    id: this.state.currentScreenObj.id,
                    title: this.state.currentScreenObj.title,
                    type: this.state.currentScreenObj.type,
                    ref: this.state.currentScreenObj.ref,
                    properties: {},
                }
                if(['multiple_choice','dropdown'].includes(this.state.currentScreenObj.type)){
                    newDefinitionField['choices'] = this.state.currentScreenObj.properties.choices
                }
                if(this.state.currentScreenObj.properties.hasOwnProperty('allow_multiple_selection') && this.state.currentScreenObj.properties.allow_multiple_selection){
                    newDefinitionField['allow_multiple_selections'] = true
                }
                if(this.state.currentScreenObj.properties.hasOwnProperty('allow_other_choice') && this.state.currentScreenObj.properties.allow_other_choice){
                    newDefinitionField['allow_other_choice'] = true
                }
                definitionFields.push(newDefinitionField)
                // saving new answer
                await this.setState(prevState => ({
                    answerObj: {
                        ...prevState.answerObj,
                        form_response: {
                            ...prevState.answerObj.form_response,
                            definition: {
                                ...prevState.answerObj.form_response.definition,
                                fields: definitionFields
                            },
                            answers: answers
                        }
                    }
                }))
            }
            if(!this.isNotLastQuestion()){
                await this.saveAnswersToDB()
            }
            console.log('============')
            this.redirectToNewQuestion()
        }
        console.log('============')
        console.log('answer', this.state.answerObj)
        this.setState({answerValue: null})
        this.setState({errors: ''})
        this.setState({isLoading: true})
    }

    async getNextScreenByLogic() {
        if (this.isNotLastQuestion()) {
            if (!this.state.questionsJson.hasOwnProperty('logic')) {
                return await this.getScreen('next')
            }
            let jumpRules = this.state.questionsJson.logic.find(x => x.ref === this.state.vCurrentScreenId)
            if (jumpRules) {
                let toScreenId = ''
                for (const action of jumpRules.actions) {
                    if (action.condition.op === 'is') {
                        let field = action.condition.vars.find(x => x.type === 'field').value
                        let value = action.condition.vars.find(x => x.type !== 'field')
                        let answer = this.state.answerObj.form_response.answers.find(x => x.field.ref === field)
                        if (!answer) continue
                        let answerVal = []
                        if (value.type === 'choice') {
                            if (answer[answer.type].hasOwnProperty('ref')) {
                                answerVal = [answer[answer.type].ref]
                            } else {
                                answerVal = answer[answer.type].refs
                            }
                        } else {
                            answerVal = [answer[answer.type]]
                        }
                        if (answerVal.includes(value.value)) {
                            if (action.details.to.type === 'field') {
                                toScreenId = action.details.to.value
                                break
                            }
                        }
                    } else if (action.condition.op === 'or') {
                        for (const subAction of action.condition.vars) {
                            let field = subAction.vars.find(x => x.type === 'field').value
                            let value = subAction.vars.find(x => x.type !== 'field')
                            let answer = this.state.answerObj.form_response.answers.find(x => x.field.ref === field)
                            if (!answer) continue
                            let answerVal = []
                            if (value.type === 'choice') {
                                if (answer[answer.type].hasOwnProperty('ref')) {
                                    answerVal = [answer[answer.type].ref]
                                } else {
                                    answerVal = answer[answer.type].refs
                                }
                            } else {
                                answerVal = [answer[answer.type]]
                            }
                            if (answerVal.includes(value.value)) {
                                if (action.details.to.type === 'field') {
                                    toScreenId = action.details.to.value
                                    break
                                }
                            }
                        }
                    } else if (action.condition.op === 'always') {
                        if (action.details.to.type === 'field') {
                            toScreenId = action.details.to.value
                            break
                        }
                    }
                }
                if (toScreenId) {
                    return await this.getScreen(toScreenId)
                } else {
                    return await this.getScreen('next')
                }
            } else {
                return await this.getScreen('next')
            }
        } else {
            return await this.getScreen('thankyou_screens')
        }
    }

    async getScreen(screenId) {
        let currentScreenObj = {}
        let currentQuestionIndex = 0
        if (screenId === 'welcome_screens') {
            if (this.state.questionsJson.hasOwnProperty('welcome_screens')) {
                currentScreenObj = this.state.questionsJson.welcome_screens[0]
            } else {
                return await this.getScreen(this.state.questionsJson.fields[0].ref)
            }
        } else if (screenId === 'thankyou_screens') {
            currentScreenObj = this.state.questionsJson.thankyou_screens[0]
        } else if (screenId === 'next') {
            if (this.state.vCurrentScreenObj.type === 'group') {
                await this.setState({
                    vCurrentScreenGroupObj: this.state.vCurrentScreenObj,
                    vCurrentQuestionGroupIndex: this.state.vCurrentQuestionIndex
                })
                return await this.getScreen(this.state.vCurrentScreenObj.properties.fields[0].ref)
            } else {
                if (this.state.vCurrentQuestionGroupIndex !== null) {
                    if (this.state.vCurrentScreenGroupObj.properties.fields.length > this.state.vCurrentQuestionIndex + 1) {
                        return await this.getScreen(this.state.vCurrentScreenGroupObj.properties.fields[this.state.vCurrentQuestionIndex + 1].ref)
                    } else {
                        let groupIndex = this.state.vCurrentQuestionGroupIndex
                        await this.setState({
                            vCurrentScreenGroupObj: {},
                            vCurrentQuestionGroupIndex: null
                        })
                        return await this.getScreen(this.state.questionsJson.fields[groupIndex + 1].ref)
                    }
                } else {
                    return await this.getScreen(this.state.questionsJson.fields[this.state.vCurrentQuestionIndex + 1].ref)
                }
            }
            return
        } else {
            if (this.state.vCurrentQuestionGroupIndex !== null) {
                currentScreenObj = this.state.vCurrentScreenGroupObj.properties.fields.find(x => x.ref === screenId)
                currentQuestionIndex = this.state.vCurrentScreenGroupObj.properties.fields.findIndex(x => x.ref === screenId)
                if (currentQuestionIndex === -1) {
                    await this.setState({
                        vCurrentScreenGroupObj: {},
                        vCurrentQuestionGroupIndex: null
                    })
                    currentScreenObj = this.state.questionsJson.fields.find(x => x.ref === screenId)
                    currentQuestionIndex = this.state.questionsJson.fields.findIndex(x => x.ref === screenId)
                }
            } else {
                currentScreenObj = this.state.questionsJson.fields.find(x => x.ref === screenId)
                currentQuestionIndex = this.state.questionsJson.fields.findIndex(x => x.ref === screenId)
            }
        }
        await this.setState({
            vCurrentScreenId: screenId,
            vCurrentScreenObj: currentScreenObj,
            vCurrentQuestionIndex: currentQuestionIndex
        })
        return currentScreenObj
    }

    redirectToNewQuestion(){
        if(this.isNotLastQuestion()){
            console.log('it was not last question')
            if(!this.state.questionsJson.hasOwnProperty('logic')){
                console.log('has no jump rules')
                this.loadScreen('next')
                return
            }
            let jumpRules = this.state.questionsJson.logic.find(x => x.ref === this.state.currentScreenId)
            if(jumpRules){
                console.log('has jump rule',jumpRules)
                let toScreenId = ''
                for (const action of jumpRules.actions) {
                    if(action.condition.op === 'is'){
                        let field = action.condition.vars.find(x => x.type === 'field').value
                        let value = action.condition.vars.find(x => x.type !== 'field')
                        let answer = this.state.answerObj.form_response.answers.find(x => x.field.ref === field)
                        if(!answer) continue
                        let answerVal = []
                        if(value.type === 'choice'){
                            if(answer[answer.type].hasOwnProperty('ref')){
                                answerVal = [answer[answer.type].ref]
                            }else{
                                answerVal = answer[answer.type].refs
                            }
                        }else{
                            answerVal = [answer[answer.type]]
                        }
                        if(answerVal.includes(value.value)){
                            console.log(`equals to ${field}=${value.value}`)
                            if(action.details.to.type === 'field'){
                                toScreenId = action.details.to.value
                                break
                            }
                        }
                    }else if(action.condition.op === 'or'){
                        for (const subAction of action.condition.vars) {
                            let field = subAction.vars.find(x => x.type === 'field').value
                            let value = subAction.vars.find(x => x.type !== 'field')
                            let answer = this.state.answerObj.form_response.answers.find(x => x.field.ref === field)
                            if(!answer) continue
                            let answerVal = []
                            if(value.type === 'choice'){
                                if(answer[answer.type].hasOwnProperty('ref')){
                                    answerVal = [answer[answer.type].ref]
                                }else{
                                    answerVal = answer[answer.type].refs
                                }
                            }else{
                                answerVal = [answer[answer.type]]
                            }
                            if(answerVal.includes(value.value)){
                                console.log(`equals to ${field}=${value.value}`)
                                if(action.details.to.type === 'field'){
                                    toScreenId = action.details.to.value
                                    break
                                }
                            }
                        }
                    }else if(action.condition.op === 'always'){
                        console.log('equals to always')
                        if(action.details.to.type === 'field'){
                            toScreenId = action.details.to.value
                            break
                        }
                    }
                }
                if(toScreenId){
                    console.log('go to',toScreenId)
                    this.loadScreen(toScreenId)
                }else{
                    console.log('has no equals jump rule')
                    this.loadScreen('next')
                }
            }else{
                console.log('has no jump rule')
                this.loadScreen('next')
            }
        }else{
            console.log('it was last question')
            this.loadScreen('thankyou_screens')
        }
    }

    async saveAnswersToDB() {
        await this.setState(prevState => ({
            answerObj: {
                ...prevState.answerObj,
                form_response: {
                    ...prevState.answerObj.form_response,
                    submitted_at: new Date().toISOString(),
                }
            }
        }))
        let tempTreatment = 'd2'
        let q_refs = ['a0e3d47e-a86f-4c37-b4bb-c0d9267fecd1', '6d89a9c3-20b5-4515-9105-666b9c55f263', '3c53e3f3-f15a-458a-b24f-c123e0f6e70', 'ebe79853-c534-4847-be0e-cee8437bcea4'];
        let answer = this.state.answerObj.form_response.answers.find(x => x.field.ref === 'concerns')
        if(answer){
            answer.choices.refs.forEach(ref => {
                if (q_refs.includes(ref)) tempTreatment = 'd1'
            })
        }
        window.callNative(JSON.stringify({starter: tempTreatment !== 'd1'}))
        const saveTypeForm = fb.functions.httpsCallable('save_type_form');
        await saveTypeForm({answers: this.state.answerObj})
            .then(async (result) => {
                if(this.state.mark.toLowerCase() === 'intro'){
                    const changeUserStatus = fb.functions.httpsCallable('changeUserStatus');
                    await changeUserStatus({uid:this.state.uid,status:'waiting_diagnose'}).then(res=> {

                    }).catch(err=> {
                        console.log('change status error',err.message)
                    });
                }
                if(this.state.mark.toLowerCase() === 'weekly'){
                    await fb.usersCollection.doc(this.state.uid).update({
                        weeklyQuestionnaireDone:true,
                    }).then(res=> {

                    }).catch(err=> {
                        console.log('change status error',err.message)
                    });
                }
            }).catch(async err => {
                console.log('saveTypeForm error',err.message)
            });
    }

    typeFormSlideClass(){
        return `typeFormSlide ${this.state.currentScreenObj.hasOwnProperty('layout') ? this.state.currentScreenObj.layout.type : ''}`
    }

    typeFormSlideStyle(){
        return {
            "--answer-color": this.state.theme.colors.answer,
            "--question-color": this.state.theme.colors.question,
            "--button-color": this.state.theme.colors.button,
            background: this.state.theme.colors.background,
            fontFamily: this.state.theme.font,
            textAlign: (this.state.currentScreenId === 'welcome_screens' || this.state.currentScreenId === 'thankyou_screens') ? this.state.theme.screens.alignment : this.state.theme.fields.alignment,
            color: this.state.theme.colors.question
        }
    }

    hasImage(){
        return this.state.currentScreenObj.hasOwnProperty('attachment') && this.state.currentScreenObj.attachment.type === 'image'
    }

    hasRequired(){
        return this.state.currentScreenObj.hasOwnProperty('validations') && this.state.currentScreenObj.validations.required
    }

    hasLimit(){
        return this.state.currentScreenObj.hasOwnProperty('validations') && this.state.currentScreenObj.validations.hasOwnProperty('min_value')
    }

    hasLayoutImage(){
        return this.state.currentScreenObj.hasOwnProperty('layout') && this.state.currentScreenObj.layout.hasOwnProperty('attachment') && this.state.currentScreenObj.layout.attachment.type === 'image'
    }

    showActionBtn(){
        return this.state.currentScreenObj.properties.hasOwnProperty('show_button') && this.state.currentScreenObj.properties.show_button
    }

    questionNumber(){
        if(this.state.currentScreenId !== 'thankyou_screens' && this.state.currentScreenId !== 'welcome_screens'){
            if(this.state.currentQuestionGroupIndex !== null){
                return (this.state.currentQuestionGroupIndex+1)+'.'+(this.state.currentQuestionIndex+1)
            }else{
                return this.state.currentQuestionIndex+1
            }
        }else{
            return '';
        }
    }

    setAnswerValue = async (value,type='') => {
        await this.setState({answerValue: value})
        console.log('answerValue',this.state.answerValue)
        if(type === 'file' && this.state.autofile){
            // this.submitAction()
            this.submitBtnRef.click()
        }
    }

    getName(){
        let answer = this.state.answerObj.form_response.answers.find(x => x.field.ref === 'name')
        if(answer){
            return answer[answer.type]
        }else{
            return ''
        }
    }

    replaceAnswerFields(text){
        this.state.answerObj.form_response.answers.forEach(answer => {
            let pattern = '{{field:'+answer.field.ref+'}}'
            let reg = new RegExp(pattern, "g");
            let value = ''
            if(answer.type === 'choice') {
                value = answer[answer.type].label
            }else if(answer.type === 'choices'){
                value = answer[answer.type].labels.join(', ')
            }else{
                value = answer[answer.type]
            }
            text = text.replace(reg, value);
        })
        return text
    }

    getFocalPoint(){
        let x,y
        if(this.state.currentScreenObj.layout.attachment.hasOwnProperty('properties') && this.state.currentScreenObj.layout.attachment.properties.hasOwnProperty('focal_point')){
            let point = this.state.currentScreenObj.layout.attachment.properties.focal_point
            x = (point.x + 1) / 2 * 100
            y = (point.y + 1) / 2 * 100
        }else{
            x = 50
            y = 50
        }
        return `${x}% ${y}%`
    }

    getYesNoProps(){
        return {
            choices: [
                {
                    ref: true,
                    label: 'Yes'
                },
                {
                    ref: false,
                    label: 'No'
                }
            ],
            allow_other_choice: false,
            allow_multiple_selection: false
        }
    }

    onError(text){
        this.setState({errors: text})
        setTimeout(()=>{this.setState({errors: ''})},3000)
    }

    render() {
        return (
            <section className="TypeForm">
                {this.state.isLoading ? 
                    Object.keys(this.state.currentScreenObj).length ? (
                        <div className={this.typeFormSlideClass()} style={this.typeFormSlideStyle()}>
                            <div className="question">
                                <div className="questionWrapper">
                                    <div className="questionWrapperWrapper">
                                        {this.hasImage() &&
                                        <PopupImage className="questionMainImage" big={this.state.currentScreenObj.ref === "d0916f5a-48ca-435f-a18e-95c6d29d88a0"} src={this.state.currentScreenObj.attachment.href} />
                                        }
                                        {this.state.currentScreenObj.hasOwnProperty('title') &&
                                        <div className="questionTitle">
                                            <span className="questionNumber">{this.questionNumber()}</span>
                                            {this.replaceAnswerFields(this.state.currentScreenObj.title)}
                                            {this.hasRequired() && '*'}
                                        </div>
                                        }
                                        {this.state.currentScreenObj.properties.hasOwnProperty('description') &&
                                        <div className="questionDescription"
                                            dangerouslySetInnerHTML={{__html: this.state.currentScreenObj.properties.description.replaceAll('\n', '<br/>').replaceAll('\\*', '*')}} />
                                        }
                                        {this.state.currentScreenObj.hasOwnProperty('type') &&
                                            <div className="answerWrapper">
                                                {this.state.currentScreenObj.type === 'short_text' &&
                                                    <ShortText type="text" onChange={this.setAnswerValue} />
                                                }
                                                {this.state.currentScreenObj.type === 'number' &&
                                                    <ShortText type="number" onChange={this.setAnswerValue} />
                                                }
                                                {this.state.currentScreenObj.type === 'phone_number' &&
                                                    <TypeFormPhone onChange={this.setAnswerValue} onError={this.onError.bind(this)} />
                                                }
                                                {this.state.currentScreenObj.type === 'dropdown' &&
                                                    <Dropdown onChange={this.setAnswerValue} options={this.state.currentScreenObj.properties.choices} />
                                                }
                                                {this.state.currentScreenObj.type === 'multiple_choice' &&
                                                    <MultipleChoice onChange={this.setAnswerValue} properties={this.state.currentScreenObj.properties} />
                                                }
                                                {this.state.currentScreenObj.type === 'yes_no' &&
                                                    <MultipleChoice onChange={this.setAnswerValue} properties={this.getYesNoProps()} />
                                                }
                                                {this.state.currentScreenObj.type === 'date' &&
                                                    <TypeFormDate onChange={this.setAnswerValue} properties={this.state.currentScreenObj.properties} onError={this.onError.bind(this)} />
                                                }
                                                {this.state.currentScreenObj.type === 'rating' &&
                                                    <TypeFormRating onChange={this.setAnswerValue} properties={this.state.currentScreenObj.properties} onError={this.onError.bind(this)} />
                                                }
                                                {this.state.currentScreenObj.type === 'long_text' &&
                                                    <LongText onChange={this.setAnswerValue} />
                                                }
                                                {this.state.currentScreenObj.type === 'file_upload' &&
                                                    <FileUpload
                                                    ref={instance => { this.fileUploadRef = instance; }}
                                                    onChange={this.setAnswerValue}
                                                    onError={this.onError.bind(this)} />
                                                }
                                                {this.state.errors &&
                                                <p className="alert">
                                                    <img src={staticWebImages.alertCircle}/> {this.state.errors}
                                                </p>
                                                }
                                                {!this.showActionBtn() &&
                                                    <button type="button"
                                                            ref={instance => { this.submitBtnRef = instance; }}
                                                            onClick={this.submitAction.bind(this)}
                                                            className="answerButton">{this.isNotLastQuestion() ?
                                                        <>OK <TypeFormCheck/></>
                                                        :
                                                        <>Submit</>
                                                    }
                                                    </button>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="rightSidebar">
                                {this.hasLayoutImage() &&
                                <div className="backgroundImage"
                                    style={{backgroundImage: `url("${this.state.currentScreenObj.layout.attachment.href}")`,backgroundPosition: this.getFocalPoint()}}/>
                                }
                            </div>
                            {(this.showActionBtn() && this.state.currentScreenId !== 'thankyou_screens') &&
                            <div className="action">
                                <button type="button" onClick={this.submitAction.bind(this)} className="actionButton">{this.state.currentScreenObj.properties.button_text}</button>
                            </div>
                            }
                        </div>
                    ) : (
                        <div style={this.typeFormSlideStyle()}>
                            <WelcomeScreen 
                                onNext={() => this.state.mark === 'photos'
                                    ? this.props.history.push(`/upload-photos/${this.state.uid}`)
                                    : this.loadScreen('welcome_screens')
                                }
                                user={this.state.userData}
                                expert={this.state.expertData}
                                mark={this.state.mark}
                            />
                        </div>
                    )
                : <Loading />}
            </section>
        )
    }
}

export default withRouter(TypeForm)
