import React, {useEffect, useState} from "react";
import PageLoader from "components/Loader/PageLoader.jsx";
import {Container, Row, Col, Table, Tab, Tabs} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import * as fb from '../../firebase';
import "./User.css";
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import { saveAs } from 'file-saver'
import api from "api";

function AnswersView({isAssessment, answerId}) {

    const [userId, setUserId] = useState('');
    const [currentAnswerId, setCurrentAnswerId] = useState('');
    const [answer, setAnswer] = useState({});
    const [treatmentId, setTreatmentId] = useState('');
    const [userAnswers, setUserAnswers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [questionsRu, setQuestionsRu] = useState([]);
    const [questionsEn, setQuestionsEn] = useState([]);

    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            const  url = window.location.href;
            let urlArr = url.indexOf('?') !== -1 ? url.slice(0, url.indexOf('?')).split('/') : url.split('/');
            const userId = (urlArr[urlArr.length - 2] === 'assessment' || urlArr[urlArr.length - 2] === 'diagnosis')
                ? urlArr[urlArr.length - 1]
                : urlArr[urlArr.length - 2];
            const urlAnswerId = answerId || urlArr[urlArr.length-1];

            await api.get('userByID', { params: { userId } })
                .then(res => {
                    setTreatmentId(res.data?.currentTreatmentID)
                })
                .catch((err) => {
                    console.log('err', err)
                    setIsLoading(false)
                })

            await api.get('getAnswersByUserId', { params: { userId } }).then(async res => {
                const answerObj = res.data?.find(i => i.id === urlAnswerId)

                if (questionsRu.length === 0) {
                    await getQuestions(answerObj.mark.toLowerCase())
                }
                setAnswer(answerObj)
                const { form_response } = answerObj || {};
                if (form_response && form_response.definition && form_response.answers) {
                    let definition = form_response.definition;
                    let answers = form_response.answers;
                    let answersArr = [];
                    definition.fields.forEach((question, itemKey) => {
                        let item = answers.find(answer => answer.field.type === question.type && answer.field.ref === question.ref);
                        let itemRu = findQuestion(questionsRu, question.ref)
                        let itemEn = findQuestion(questionsEn, question.ref)
                        if (item && itemRu && itemEn) {
                            if (question.type === "short_text") {
                                answersArr.push({
                                    id: question.id,
                                    ref: question.ref,
                                    question: { en: itemEn.title, ru: itemRu.title }, // question.title
                                    answer: { en: item.text, ru: item.text },
                                    type: question.type
                                });
                            } else if (question.type === "dropdown") {
                                let choiceRu = getChoiceLabel(itemRu, item.choice.ref, item.choice.label)
                                let choiceEn = getChoiceLabel(itemEn, item.choice.ref, item.choice.label)
                                answersArr.push({
                                    id: question.id,
                                    ref: question.ref,
                                    question: { en: itemEn.title, ru: itemRu.title },
                                    answer: { en: choiceEn, ru: choiceRu },
                                    type: question.type
                                });
                            } else if (question.type === "multiple_choice") {
                                if (question.allow_multiple_selections) {
                                    let answer;
                                    if (item.choices && item.choices.other) {
                                        answer = { en: item.choices.other, ru: item.choices.other }
                                    } else {
                                        if (item.choices) {
                                            let answerRu = []
                                            let answerEn = []
                                            let index = 0
                                            if (item.choices.hasOwnProperty('refs')) {
                                                for (const ref of item.choices.refs) {
                                                    answerRu.push(getChoiceLabel(itemRu, ref, item.choices.labels[index]))
                                                    answerEn.push(getChoiceLabel(itemEn, ref, item.choices.labels[index]))
                                                    index++
                                                }
                                                answer = { en: answerEn.join(), ru: answerRu.join() }
                                            } else {
                                                answer = { en: item.choices.labels.join(), ru: item.choices.labels.join() }
                                            }

                                        } else {
                                            answer = { en: "", ru: "" }
                                        }
                                    }
                                    let choices = question.choices.map((e) => {
                                        return { en: getChoiceLabel(itemEn, e.ref, e.label), ru: getChoiceLabel(itemRu, e.ref, e.label) }
                                    })
                                    answersArr.push({
                                        id: question.id,
                                        ref: question.ref,
                                        question: { en: itemEn.title, ru: itemRu.title },
                                        answer: answer,
                                        type: question.type,
                                        choices: choices
                                    });
                                } else {
                                    let choiceRu = getChoiceLabel(itemRu, item.choice.ref, item.choice.label)
                                    let choiceEn = getChoiceLabel(itemEn, item.choice.ref, item.choice.label)
                                    let choices = question.choices.map((e) => {
                                        return { en: getChoiceLabel(itemEn, e.ref, e.label), ru: getChoiceLabel(itemRu, e.ref, e.label) }
                                    })
                                    answersArr.push({
                                        id: question.id,
                                        ref: question.ref,
                                        question: { en: itemEn.title, ru: itemRu.title },
                                        answer: { en: choiceEn, ru: choiceRu },
                                        type: question.type,
                                        choices: choices
                                    });
                                }
                            } else if (question.type === "date") {
                                if (item.field.ref === question.ref) {
                                    answersArr.push({
                                        id: question.id,
                                        ref: question.ref,
                                        question: { en: itemEn.title, ru: itemRu.title },
                                        answer: { en: item.date, ru: item.date },
                                        type: question.type
                                    });
                                }
                            } else if (question.type === "yes_no") {
                                answersArr.push({
                                    id: question.id,
                                    ref: question.ref,
                                    question: { en: itemEn.title, ru: itemRu.title },
                                    answer: { en: item.boolean === true ? 'Yes' : 'No', ru: item.boolean === true ? 'Да' : 'Нет' },
                                    type: question.type
                                });
                            } else if (question.type === "file_upload") {
                                answersArr.push({
                                    id: question.id,
                                    ref: question.ref,
                                    question: { en: itemEn.title, ru: itemRu.title },
                                    answer: item.file_url,
                                    type: question.type
                                });
                            }
                        }
                    });
                    setUserAnswers(answersArr);
                }
                setUserId(userId);
                setCurrentAnswerId(urlAnswerId);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log('err', err)
                setIsLoading(false)
            });
        }
        fetchData();
    }, [questionsRu, questionsEn, answerId]);

    const downloadImage = (url) => {
        console.log('dadad', url)
        saveAs(url, 'image.jpg') // Put your image url here.
    }

    const findQuestion = (array,ref) => {
        for (const item of array) {
            if(item.ref === ref){
                return item
            }
            if(item.properties.hasOwnProperty('fields')){
                for (const subItem of item.properties.fields) {
                    if(subItem.ref === ref){
                        return subItem
                    }
                }
            }
        }
        return false
    }
    const getChoiceLabel = (itemLang,ref,label) => {
        let choiceLang = itemLang.properties.choices.find((x) => x.ref === ref)
        return choiceLang ? choiceLang.label : label
    }
    const getQuestions = async (mark) => {
        const q = await fb.metaCollection.doc('questionnaire_new').get().then(qref => {
            setQuestionsRu(JSON.parse(qref.data()[mark+'_json_ru']).fields)
            setQuestionsEn(JSON.parse(qref.data()[mark+'_json']).fields)
        })
    }

    const replaceAnswerFields = (text) => {
        answer.form_response.answers.forEach(a => {
            let pattern = '{{field:'+a.field.ref+'}}'
            let reg = new RegExp(pattern, "g");
            let value = ''
            if(a.type === 'choice') {
                value = a[a.type].label
            }else if(a.type === 'choices'){
                value = a[a.type].labels.join(', ')
            }else{
                value = a[a.type]
            }
            text = text.replace(reg, value);
        })
        return text
    }

    const renderAnswer = (lang) => {
        let answerObj = [];
        userAnswers && userAnswers.forEach((val, key) => {
            if(val.type==="file_upload"){
                answerObj.push(
                <div key={key}>
                    <hr/>
                    <p className="black"><b>{`Q${++key}. `}</b>{replaceAnswerFields(val.question[lang])}</p>
                    <p className="text-primary"><b>A. </b></p>
                    <div style={{ width: '500px', height: '500px' }}>
                        <PinchZoomPan>
                            <img alt='Test Image' src={val.answer} />
                        </PinchZoomPan>
                    </div>
                    <button onClick={()=>downloadImage(val.answer)} className="btn btn-success" style={{marginTop:'16px'}}>Download Image</button>
                </div>
                )
            }else{
                answerObj.push(
                <div key={key}>
                    <hr/>
                    <p className="black"><b>{`Q${++key}. `}</b>{val.question[lang]}</p>
                    <p className="text-success"><b>A. </b>{val.answer[lang]} </p>
                    {
                        val.choices && val.choices.map((c,i) => {
                            return(
                                <p key={i} className="text-danger">-{c[lang]} </p>
                            )
                        })
                    }
                </div>
                )
            }
        })
        return answerObj;
    }

    //review the answer
    const reviewAnswer = () => {
        if (window.confirm("Are you sure you want to keep user treatment the same?")) {
            api.post('updateUser', {
                id: userId,
                weeklyQuestionnaireReviewed: true
            });
            fb.usersCollection.doc(userId).collection('Answers')
              .doc(currentAnswerId).update({
                treatment: true,
                reviewedBy: fb.firebase.auth().currentUser.uid,
                reviewedDate: new Date()
            }).then(() => {
                let a = answer
                a.treatment = true
                setAnswer(a)
            });
        }
    }

  return (
    <div className="content">
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card
            title="Customer Answers"
            category=""
            ctTableFullWidth
            ctTableResponsive
            button={
                <div className={isAssessment && 'hidden'}>
                    <Link to={`/admin/users/view/${userId}`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back
                    </Link>
                    {!answer.treatment &&
                    <Link to={`#`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"
                          onClick={() => {
                              reviewAnswer()
                          }}>
                        Mark done
                    </Link>
                    }
                    <Link to={`/admin/users/diagnosis/${userId}/${treatmentId}?type=t`} className="btn btn-link custom-btn pull-right">
                        Edit Treatment
                    </Link>
                    <Link to={`/admin/assessment/view/${userId}/${treatmentId}`} className="btn btn-link custom-btn pull-right">
                        View Treatment
                    </Link>
                </div>
            }
            loader={ isLoading && <PageLoader />}
            content={
                <Tabs defaultActiveKey="en" id="uncontrolled-tab-example">
                    <Tab eventKey="en" title="EN">
                        <div className={isAssessment && 'answer-scroll-block'}>
                            { renderAnswer('en') }
                        </div>
                    </Tab>
                    <Tab eventKey="ru" title="RU">
                        <div className={isAssessment && 'answer-scroll-block'}>
                            { renderAnswer('ru') }
                        </div>
                    </Tab>
                </Tabs>
            }
          />
        </Col>
      </Row>
    </Container>
  </div>
  );
}
  
export default AnswersView;
