import React from "react";
import { Modal, Button} from "react-bootstrap";

function ImageView(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <p className="modal-image">
          <img src={props.src}  alt="" />
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
export default ImageView;
