import React, {useEffect, useState} from "react";
import { 
    Modal, Button,
    Row,
    FormGroup,
    FormLabel,
    Alert
} from "react-bootstrap";
import { Multiselect } from 'multiselect-react-dropdown';
import { Link } from 'react-router-dom';
import * as fb from '../../firebase';
import { arrayChunks } from '../Helper/index';
import api from "api";

function AddAdminToChatView(props) {

    const [currentuserId, setCurrentuserId] = useState('');
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState([])
    const [masters, setMasters] = useState(false)
    const [disableLeaveGroup, setDisableLeaveGroup] = useState(false)
    const [newSelectedOption, setNewSelectedOption] = useState([])
    const [error, seterror] = useState('')

    useEffect(() => {
        async function fetchData() {
            console.log('props.data', props.data.currentUser)
            const { userID, adminRoles = [] } = props.data.currentUser;
            setCurrentuserId(userID);
            let data = ''
            if(adminRoles.length > 0 && adminRoles.includes("masters")){
                const {data: admins} = await api.get('getAdmins')
                data = admins
                setMasters(true);
            }else{
                const {data: userByID} = await api.get('userByID', {params: {userId: userID}})
                data = userByID
                setMasters(false);
            }
            let admins = data?.length 
                ? data?.map(i => ({ name: i?.firstName + ' ' + i?.lastName })) 
                : [{...data, name: data?.firstName + ' ' + data?.lastName}]

            if(props.data.currentPeerUser.admins){
                try{
                    const { admins = [] } = props.data.currentPeerUser;
                    //make chucks of ref as firebase dones't support more than 10 
                    let adminsChunk = arrayChunks(admins);
                    const selectedAdmins = [];
                    for(var i=0; i < adminsChunk.length; i++){
                        const {data: userByID} = await api.get('userByID', {params: {userId: adminsChunk[i]}})
                        selectedAdmins.push({
                            id: userByID.id,
                            name: userByID?.firstName + ' ' + userByID?.lastName
                        })
                    }
                    setSelectedOption(selectedAdmins)

                    //check if i am added in current chat or not
                    if(admins.includes(userID)){
                        setDisableLeaveGroup(true)
                    }
                }catch(err){
                    seterror(err.message)
                }
                
            }
            setOptions(admins)
        }
        fetchData();
    }, []);
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        setDisabled(true)
        let finalSelected = [];
        //check and merge in case of chat roles
        if(newSelectedOption && newSelectedOption.length > 0){
            finalSelected  = newSelectedOption;
        }else{
            finalSelected  = selectedOption;
        }
        // console.log('newSelectedOption', newSelectedOption)
        const chatRoomRef = fb.chatRoomCollection.doc(props.data.currentPeerUser.userID);
        await chatRoomRef.update({
            admins:finalSelected.map(user=> user.id)
        }).then(res => {
            props.onHide();
        });
    }

    const onSelect = (selectedList, selectedItem) => {
        if(masters===true){
            setSelectedOption(selectedList);
        }else{
            console.log('selectedList', selectedList)
            setNewSelectedOption(selectedList);
        }
    }
    
    const onRemove = (selectedList, removedItem) => {
        if(masters===true){
            setSelectedOption(selectedList);
        }else{
            setNewSelectedOption(selectedList);
        }
    }

    const deleteMeFromChat = async(id) => {
        const { userID, admins=[] } = props.data.currentPeerUser;
        //shown current thread admin
        if (window.confirm("Do you want to remove yourself from this chat ?")) {
            if(admins && admins.length > 0){
                const chatRoomRef = fb.chatRoomCollection
                .doc(userID)
                .update({   
                    admins: admins.filter(post => post !== currentuserId)
                }).then(res=> {
                    props.onHide();
                }).catch(function(error) {
                    console.error("Error removing document: ", error);
                });
            }
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <h4 style={{textAlign:"center"}}><strong>Add Admin</strong></h4>
                { error && 
                    <Alert bsStyle="danger">
                      <span>{error}</span>
                    </Alert>
                }
                { masters===false && disableLeaveGroup===true &&
                    <Link to={`#`} onClick={() => deleteMeFromChat() } className="btn btn-success btn-small btn-fill"> Leave Group</Link>
                }
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <div className="col-md-12 custom-multi-select">
                            <FormGroup>
                                <FormLabel>Admin</FormLabel>
                                    {
                                        masters===true ? 
                                        <Multiselect
                                        options={options}
                                        selectedValues={selectedOption}
                                        onSelect={onSelect}
                                        onRemove={onRemove} 
                                        displayValue="name" 
                                        showCheckbox={true}
                                        placeholder="Select Admin"
                                    />
                                    :
                                    <Multiselect
                                        options={options}
                                        selectedValues={selectedOption}
                                        onSelect={onSelect}
                                        onRemove={onRemove} 
                                        displayValue="name" 
                                        showCheckbox={true}
                                        placeholder="Select Admin"
                                        disablePreSelectedValues={true}
                                    />
                                    }
                                    
                                <span className="help-block">{errors.group}</span>
                            </FormGroup>
                        </div>
                    </Row>
                    <Button 
                        bsStyle="info" 
                        type="submit"
                        disabled={disabled}
                    >
                        {disabled ? 'Loading...' : 'Submit'}
                    </Button>
                    <div className="clearfix" />
                </form>
            </Modal.Body>
        </Modal>
    );
}
  
export default AddAdminToChatView;
