import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import {
  addAdminLog,
  createUnixTimestamp
} from "components/Helper/index";
import { Link } from "react-router-dom";

class AddChatAdmin extends Component {
  constructor(props){
    super(props);
    this.state = {
      name:"",
      picture: {},
      pictureUrl:"",
      disabled : false,
      error:"",
      errors:{}
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'name') {
      this.setState({name:value});
    }else if(event.target.files && event.target.files[0]){
      this.setState({picture:event.target.files[0]});
    }
  };


  handleValidation = () => {
    let {name} = this.state;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!name){
      formIsValid = false;
      errors["name"] = "Name is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    //disable button
    this.setState({disabled: true});
    if(this.handleValidation(this.state.errors)){
      if(typeof(this.state.picture.name) != "undefined") {
        var timestamp = createUnixTimestamp();
        await fb.storage.child(`chat-admin/${timestamp}-${this.state.picture.name}`).put(this.state.picture)
        .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {   
          this.setState({pictureUrl:url}, () => {
            this.saveItem();
          });
        });
      }else{
        this.saveItem(); 
      }
    }else{
      this.setState({
        disabled: false
      });
    }
  }

  saveItem = async () => {
    let docRef = fb.chatAdminsCollection.doc();
    await docRef.set({
      id:docRef.id,
      name:this.state.name,
      picture:this.state.pictureUrl,
      created:fb.timestamp.serverTimestamp()
    }).then(spanShot => {
      addAdminLog(
        'chat_admin_created',
        `Created the chat admin ${docRef.id}`
      )
      this.props.history.push('/admin/chat-admin')
    }).catch(err => {
      this.setState({
        error:`Something went wrong-${err}`,
        disabled: false
      });
    });
  }
  
  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Add Chat Admin"
                button={
                  <Link to={`/admin/chat-admin`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Name</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            value= {this.state.name}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.title}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Picture</FormLabel>
                          <input 
                            type="file" 
                            name="picture" 
                            className="form-control" 
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Loading...' : 'Add'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AddChatAdmin;
