/**
 * Converts CSV to HTML Table
 *
 */

export function parseCsvToRowsAndColumn(csvText, csvColumnDelimiter = '\t') {
  const rows = csvText.split('\n');
  // console.log('rows', rows)
  if (!rows || rows.length === 0) {
    return [];
  }

  String.prototype.splitCSV = function() {
    var matches = this.match(/(\s*"[^"]+"\s*|\s*[^,]+|,)(?=,|$)/g);
    for (var n = 0; n < matches.length; ++n) {
        matches[n] = matches[n].trim();
        if (matches[n] === ',') matches[n] = '';
    }
    if (this[0] === ',') matches.unshift("");
    return matches;
  }

  return rows.map(row => row.splitCSV());
}
