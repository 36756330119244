import React, { useEffect, useState } from "react";
import api from "api";
import Card from "components/Card/Card";
import PageLoader from "components/Loader/PageLoader";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const RenderData = ({ data }) => {
  return (
    Object.entries(data).map(([key, value]) => (
      <div
        key={key}
        style={{
          display: key === 'products' ? 'block' : 'flex',
          gap: '10px'
        }}
      >
        <div style={{ whiteSpace: 'nowrap', fontWeight: 600 }}>{key}:</div>
        <div>
          {key === 'products'
            ? value.map((i, index) => (
              <div
                key={index}
                style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
              >
                {Object.entries(i).map(([productKey, productValue]) => (
                  <div
                    key={productKey}
                    style={{
                      display: productKey === 'area' ? 'block' : 'flex',
                      gap: '10px',
                      marginLeft: '20px'
                    }}
                  >
                    <div style={{ whiteSpace: 'nowrap', fontWeight: 600 }}>{productKey}:</div>
                    <div>
                      {productKey === 'area'
                        ? Object.values(productValue).map((j, jIndex) => (
                          <div key={jIndex} style={{ marginBottom: '20px' }}>
                            {Object.entries(j).map(([areaKey, areaValue]) => (
                              <div
                                key={areaKey}
                                style={{
                                  display: 'flex',
                                  gap: '10px',
                                  marginLeft: '20px'
                                }}
                              >
                                <div style={{ whiteSpace: 'nowrap', fontWeight: 600 }}>{areaKey}:</div>
                                <div>{areaValue}</div>
                              </div>
                            ))}
                          </div>
                        ))
                        : JSON.stringify(productValue)
                      }
                    </div>
                  </div>
                ))}
                <hr />
              </div>
            ))
            : JSON.stringify(value)
          }
        </div>
      </div>
    ))
  )
}

const ViewAdminLog = () => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')

  const fetchData = async () => {
    try {
      setIsLoading(true)

      const id = window.location.href.split('/').splice(-1)[0]
      const { data: log } = await api.get('getAdminLogById', { params: { id } })
      setData(log)
    } catch (err) {
      console.log('err', err)
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="content">
      <Link to={`/admin/admin-logs`} className="btn btn-success users-back-btn libi-admin-btn">Back</Link>

      <Container fluid>
        <Row>
          <Col md={12}>
            {error && <Alert bsStyle="danger"><span>{error}</span></Alert>}

            <Card
              title="Admin Log"
              category=""
              ctTableFullWidth
              ctTableResponsive
              loader={isLoading && <PageLoader />}
              content={
                data ?
                  <div>
                    <p>{data.title}</p>
                    <p>{data.description}</p>

                    <Row>
                      <Col md={6}>
                        <h4 style={{ fontWeight: 600 }}>Data before</h4>

                        <RenderData data={data.dataBefore} />
                      </Col>

                      <Col md={6}>
                        <h4 style={{ fontWeight: 600 }}>Data after</h4>

                        <RenderData data={data.dataAfter} />
                      </Col>
                    </Row>
                  </div>
                  : null
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ViewAdminLog