import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import {
  addAdminLog,
  adminRoles,
  adminRolesValues
} from "../../components/Helper/index";
import { Link } from "react-router-dom";
import { Multiselect } from 'multiselect-react-dropdown';
import api from "api.js";

class EditAdmin extends Component {

  constructor(props){
    super(props);
    this.state = {
      id:"",
      name:"",
      email:"",
      approved:"",
      errors:{},
      disabled : false,
      selectedOptions:[]
    }
  }
  
  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'name') {
      this.setState({name:value});
    }else if(name === 'email'){
      this.setState({email:value});
    }else if(name === 'phone'){
      this.setState({title:value});
    }else if(name === 'admin_role'){
      this.setState({admin_role:value});
    }else if(name === 'approved'){
      this.setState({approved:value});
    }
  };

  componentDidMount = async () => {
    const  url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);

    const {data: userByID} = await api.get('userByID', {params: {userId: segment}})
    this.setState({
      id: userByID.id,
      name: userByID.name,
      email: userByID.email,
      selectedOptions: userByID.adminRoles && Array.isArray(userByID.adminRoles) ? adminRolesValues(userByID.adminRoles) : [],
      approved: userByID.approved === true ? "1" : "0"
    })
  }

  handleValidation = () => {
    let {name, email, selectedOptions=[] } = this.state;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!name){
      formIsValid = false;
      errors["name"] = "Name is required";
    }

    //Email
    if(!email){
      formIsValid = false;
      errors["email"] = "Please enter a valid email address";
    }

    //Roles
    if(selectedOptions.length < 1){
      formIsValid = false;
      errors["admin_role"] = "Please assign a role";
    }

    if(typeof email !== "undefined"){
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && email.indexOf('@@') === -1 && lastDotPos > 2 && (email.length - lastDotPos) > 2)) {
        formIsValid = false;
        errors["email"] = "Please enter a valid email address";
      }
    }  
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({disabled: true});
    if(this.handleValidation(this.state.errors)) {
      api.post('updateUser', {
        id: this.state.id,
        name:this.state.name,
        adminRoles:this.state.selectedOptions.map(val=>val.key),
        approved:this.state.approved === "1" ? true : false
      }).then(res => {
        // addAdminLog(
        //   'admin_updated',
        //   `Updated the admin data ${this.state.id}`,
        //   this.state.id,
        //   this.state.id,
        //   this.state.name
        // )
        this.props.history.push('/admin/masters')
      }).catch(err=> {
        this.setState({
          disabled: false
        });
      });
    }else{
      this.setState({
        disabled: false
      });
    }
  }

  onSelect = (selectedList, selectedItem) => {
    this.setState({selectedOptions:selectedList})
  }

  onRemove = (selectedList, removedItem) => {
    this.setState({selectedOptions:selectedList})
  }

  render() {
    console.log('render', this.state, adminRoles())
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Edit Admin"
                button={
                  <Link to={`/admin/masters`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Name</FormLabel>
                          <input
                            type ="text"
                            className= "form-control"
                            placeholder= "Name"
                            name= "name"
                            value= {this.state.name}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.name}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Email</FormLabel>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            readOnly
                            name="email"
                            value= {this.state.email}
                            onChange ={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.email}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Status</FormLabel>
                          <select
                            className= "form-control"
                            name="approved"
                            value={this.state.approved} 
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Status</option>
                            <option value="1">Active</option>
                            <option value="0">Pending</option>
                          </select>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Roles</FormLabel>
                          <Multiselect
                              options={adminRoles()}
                              selectedValues={this.state.selectedOptions}
                              onSelect={this.onSelect}
                              onRemove={this.onRemove} 
                              displayValue="val" 
                              showCheckbox={true}
                              placeholder="Select Role"
                          />
                          <span className="help-block">{this.state.errors.admin_role}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Loading...' : 'Update'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditAdmin;
