import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Container, Row, Col, Table, Alert, Tab,Tabs } from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import {
  addAdminLog,
  convertFirebseSecondsToDate
} from "components/Helper/index";
import { Link } from "react-router-dom";
import {
  dataBar,
} from "../../variables/Variables.jsx";
import * as fb from '../../firebase';  

//Datatable Modules
import $ from 'jquery'; 
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"

import "./Dashboard.css";
import { convertFirebseSecondsToDateShorted } from "components/Helper/index.jsx";
import {Bar, Line} from 'react-chartjs-2';
import axios from "axios";


class Dashboard extends Component {

  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      usersList:[],
      usersTreatmentList:[],
      usersTreatmentListClosed:[],
      weeklyQuestionnaire:[],
      errors:{},
      error:"",
      usersBarChart:{
        labels: [],
        datasets: [
          {
            label: 'Customers',
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: []
          },
        ]
      },
      salesLineChart: {
        labels: [],
        datasets: [
          {
            label: 'Sales in GBP',
            fill: true,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 2,
            data: []
          },
          {
            label: 'Sales in EURO',
            fill: false,
            lineTension: 0.5,
            borderColor: '#742774',
            borderWidth: 2,
            data: []
          }
        ]
      },
      showLoadingTreatmentPopup: false,
      trPopupData: {},
    }
  }

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  componentDidMount = async () => {
    this.setState({domain:fb.CRM_URL})
    await this.fetchData();
    await this.weeklyQuestionnaire();
    await this.fetchUserGraphData();
    await this.fetchSalesGraphData();
    $('#users-datatable').DataTable({
      "bStateSave": true,
      "pageLength": 16,
      "order": [[ 0, "desc" ]],
      language: { search: "", searchPlaceholder: "Search..", sLengthMenu: "Show _MENU_" } 
    });

    $('#users--treatment-datatable').DataTable({
      "bStateSave": true,
      "pageLength": 16,
      "order": [[ 0, "desc" ]],
      language: { search: "", searchPlaceholder: "Search..", sLengthMenu: "Show _MENU_" }
    });
    $('#users--treatment-datatable_closed').DataTable({
      "bStateSave": true,
      "pageLength": 16,
      "order": [[ 0, "desc" ]],
      language: { search: "", searchPlaceholder: "Search..", sLengthMenu: "Show _MENU_" }
    });

    $('#weekly-users-datatable').DataTable({
      "bStateSave": true,
      "pageLength": 16,
      "order": [[ 0, "desc" ]],
      language: { search: "", searchPlaceholder: "Search..", sLengthMenu: "Show _MENU_" } 
    });
  }

  

  fetchData = async() => {
    try {
      this.setState({isLoading:true});
      const data = await fb.usersCollection
        .where("role", "==", "User")
        .where("status", "==", "waiting_diagnose")
        .get();
      const userArr = [];
      data.docs.forEach(async doc=> {
        const dataObj = doc.data();
        if(!dataObj.test || dataObj.test !== true){
          let lt = "";
          let treatmentRef = await fb.usersCollection
          .doc(dataObj.userID)
          .collection("Treatments")
          .orderBy("time", "desc")
          .limit(1)
          .get();
          if(treatmentRef.size > 0){
            lt = treatmentRef.docs[0].data().answerId;
          }
          userArr.push({
            id:dataObj.userID,
            name:dataObj.name,
            email:dataObj.email,
            created:convertFirebseSecondsToDate(dataObj.created.seconds),
            created_seconds:dataObj.created && dataObj.created.seconds,
            answerId: lt
          })
        }
      })
      const maxDays = await fb.metaCollection.doc('shipment').get().then((doc) => {
        if (!doc.exists) return 7
        let data = doc.data()
        if(data.hasOwnProperty('treatment_request_days')){
          return data.treatment_request_days
        }else{
          return 7
        }
      });
      const userTreatmentdata = await fb.usersCollection
        .where("role", "==", "User")
        // .where("treatmentRequestReviewed", "==", false)
        .get();
      const userTreatmentArr = [];
      const userTreatmentArrClosed = [];
      userTreatmentdata.docs.forEach(async doc=> {
        const dataObj = doc.data();
        if((!dataObj.test || dataObj.test !== true) && !dataObj.deleted && dataObj.paymentPlanActive/*&& dataObj.status === 'active'*/){
          const userTreatmentRequestData = await fb.usersCollection.doc(dataObj.userID).collection('TreatmentRequests')
            .orderBy("treatmentRequest", "desc").limit(1)
            .get().then((snap) => {
              if (!snap.empty) {
                return {...snap.docs[0].data(),id:snap.docs[0].id}
              } else {
                return false
              }
            })
          if(userTreatmentRequestData && !userTreatmentRequestData.treatmentRequestDone){
            let payed = ''
            if(dataObj.paymentPlanActive){
              var diff = Math.floor(dataObj.paymentPlanActive / (24 * 60 * 60)) - Math.floor(userTreatmentRequestData.treatmentRequest.seconds / (24 * 60 * 60))
              if(diff > maxDays) payed = '&#128176&#128176'
            }
            if(payed === '') this.closeTreatmentRequest(dataObj.userID,userTreatmentRequestData,maxDays)
            let lt = "";
            let answerRef = await fb.usersCollection
              .doc(dataObj.userID)
              .collection("Answers")
              .orderBy("time", "desc")
              .limit(1)
              .get();
            if(answerRef.size > 0){
              lt = answerRef.docs[0].id;
            }
            userTreatmentArr.push({
              ...dataObj,
              id:dataObj.userID,
              payed: payed,
              trID: userTreatmentRequestData.id,
              created:convertFirebseSecondsToDate(userTreatmentRequestData.treatmentRequest.seconds),
              created_seconds:userTreatmentRequestData.treatmentRequest && userTreatmentRequestData.treatmentRequest.seconds,
              answerId: lt
            })
          }else if(userTreatmentRequestData.treatmentRequestDone){
            let admin = userTreatmentRequestData.treatmentRequestDoneBy
            if(userTreatmentRequestData.treatmentRequestDoneBy !== 'expired'){
              admin = await fb.usersCollection.doc(admin).get().then((doc) => {
                if(doc.exists){
                  return doc.data().email
                }else{
                  return 'N/A'
                }
              })
            }
            userTreatmentArrClosed.push({
              ...dataObj,
              id:dataObj.userID,
              admin: admin,
              closed_date: userTreatmentRequestData?.treatmentRequestDoneDate?.seconds || 0,
              created:convertFirebseSecondsToDate(userTreatmentRequestData.treatmentRequest.seconds),
              created_seconds:userTreatmentRequestData.treatmentRequest && userTreatmentRequestData.treatmentRequest.seconds,
            })
          }
        }
      })
      this.setState({
        usersTreatmentList:userTreatmentArr,
        usersTreatmentListClosed:userTreatmentArrClosed,
        usersList:userArr,
        isLoading:false
      });
    } catch(err) {
      this.setState({error:err.message})
    }
  }

  closeTreatmentRequest = async (user_id,userTreatmentRequestData,maxDays) => {
    let diff_days = Math.floor(new Date().getTime() / (24 * 60 * 60 * 1000)) - Math.floor(userTreatmentRequestData.treatmentRequest.seconds / (24 * 60 * 60))
    if(diff_days >= maxDays){
      await fb.usersCollection.doc(user_id)
        .collection('TreatmentRequests').doc(userTreatmentRequestData.id).update({
          treatmentRequestDone: true,
          treatmentRequestDoneBy: 'expired',
          treatmentRequestDoneDate: fb.timestamp.serverTimestamp()
        });
    }
  }

  weeklyQuestionnaire = async() => {
    try {
      this.setState({isLoading:true});
      const data = await fb.usersCollection
        .where("role", "==", "User")
        .where("weeklyQuestionnaireDone", "==", true)
        .where("weeklyQuestionnaireReviewed", "==", false)
        .get();
      const userArr = [];
      data.docs.forEach(async doc=> {
        const dataObj = doc.data();
        if(!dataObj.test || dataObj.test !== true){
          let lt = "";
          let answerRef = await fb.usersCollection
          .doc(dataObj.userID)
          .collection("Answers")
            .where('mark','==','weekly')
          .orderBy("time", "desc")
          .limit(1)
          .get();
          if(answerRef.size > 0){
            lt = answerRef.docs[0].id;
          }
          userArr.push({
            id: dataObj.userID,
            name: dataObj.name,
            email: dataObj.email,
            created: answerRef.size > 0 ? convertFirebseSecondsToDate(answerRef.docs[0].data().time.seconds) : 'N/A',
            created_seconds: answerRef.size > 0 ? answerRef.docs[0].data().time.seconds : 'N/A',
            answerId: answerRef.size > 0 ? lt : 'N/A'
          })
        }
      })
      this.setState({
        weeklyQuestionnaire:userArr,
        isLoading:false
      });
    }catch(err) {
      this.setState({error:err.message})
    }
  }

  fetchUserGraphData = async() => {
    try {
      this.setState({isLoading:true});
      var start = new Date("1/1/" + (new Date()).getFullYear());
      var end = new Date("12/31/" + (new Date()).getFullYear());
      const data = await fb.usersCollection.where("role", "==", "User").where('created', '>=', start).where('created', '<=', end).get();
      let userArr = {};
      const monthArr = [];
      const finalMonthArr = [];
      var count = 1;
      data.docs.forEach(doc=> {
        const dataObj = doc.data();
        if(!dataObj.test || dataObj.test !== true){
          let date = convertFirebseSecondsToDateShorted(dataObj.created.seconds);
          let month  = date.split('/')[1];
          if(monthArr.includes(parseInt(month))) {
            count++;
          } else {
            finalMonthArr.push(dataBar.labels[parseInt(month)-1]);
            count = 1;
          }
          var indexMonth  = parseInt(month);
          userArr[indexMonth] = count;
          monthArr.push(parseInt(month));
        }
      })
      const { usersBarChart } = this.state;
      usersBarChart.labels = finalMonthArr;
      usersBarChart.datasets[0].data = Object.values(userArr);
      this.setState({
        usersBarChart:usersBarChart
      })
    }catch(err) {
      this.setState({error:err.message})
    }
  }

  fetchSalesGraphData = async() => {
    try {
      this.setState({isLoading:true});
      const data = await fb.stripeCollection.where("type", "==", "invoice.payment_succeeded").get();
      const monthArr = [];
      const finalMonthArr = [];
      let gbpI = [];
      let euroI = [];
      data.docs.forEach(doc=> {
        const dataObj = doc.data();
        let date = convertFirebseSecondsToDateShorted(dataObj.created);
        let month  = date.split('/')[1];
        if(dataObj.data.object){
          let currency = dataObj.c;
          let amount = dataObj.data.object.amount_paid/100;
          if(monthArr.includes(parseInt(month))) {
            if(currency==="UK"){ //GBP
              if(gbpI[parseInt(month)] == NaN || gbpI[parseInt(month)] == undefined) {
                gbpI[parseInt(month)] = 0;
              }
              gbpI[parseInt(month)] = gbpI[parseInt(month)] + amount;
            }else if(currency==="HU"){ //Euro
              if(euroI[parseInt(month)] == NaN || euroI[parseInt(month)] == undefined) {
                euroI[parseInt(month)] = 0;
              }
              euroI[parseInt(month)] = euroI[parseInt(month)]+amount;
            }
          } else {
            finalMonthArr.push(dataBar.labels[parseInt(month)-1]);
            if(currency==="UK"){
              gbpI[parseInt(month)] = amount;
            }else if(currency==="HU"){
              euroI[parseInt(month)] = amount;
            }
          }
        }
        monthArr.push(parseInt(month));
      })
      const { salesLineChart } = this.state;
      salesLineChart.labels = finalMonthArr;
      salesLineChart.datasets[0].data = Object.values(gbpI); //0 GBP
      salesLineChart.datasets[1].data = Object.values(euroI); //1 EURO
      this.setState({
        salesLineChart:salesLineChart
      })
    }catch(err) {
      this.setState({error:err.message})
    }
  }

  latestTreatment = async (userId) => {
    let treatmentRef = await fb.usersCollection
      .doc(userId)
      .collection("Treatments")
      .orderBy("time", "desc")
      .limit(1)
      .get();
      if(treatmentRef.size > 0){
        console.log('treatmentRef', treatmentRef.docs[0].data().answerId || "")
        return treatmentRef.docs[0].data().answerId || "";
      }
  }

  handleTreatmentRequestReview = async (event, item) => {
    this.setState({
      showLoadingTreatmentPopup: true,
      trPopupData: {
        name: item.name,
        date: item.created
      }
    })
    let currentTreatmentID = item.currentTreatmentID
// creating new Treatment
    const currentTreatmentData = await fb.usersCollection.doc(item.id)
      .collection("Treatments").doc(item.currentTreatmentID)
      .get().then((doc) => {
        if (doc.exists) {
          return doc.data()
        } else {
          return false
        }
      });
    console.log('currentTreatmentData',currentTreatmentData)
    if(currentTreatmentData){
      const treatmentRef = await fb.usersCollection
        .doc(item.id)
        .collection("Treatments")
        .doc();
      await treatmentRef
        .set({
          id: treatmentRef.id,
          answerId: currentTreatmentData.answerId || "",
          products: currentTreatmentData.products || {},
          answer: currentTreatmentData.answer || "",
          matched: currentTreatmentData.matched || "",
          treatment: currentTreatmentData.treatment || "",
          time: fb.timestamp.serverTimestamp(),
          mustaches: currentTreatmentData.mustaches || {},
          ref: currentTreatmentData.ref || "",
          additional_comment_results: currentTreatmentData.additional_comment_results || "",
          additional_comment_skincare: currentTreatmentData.additional_comment_skincare || "",
          version: 2
        }).then((res) => {
          addAdminLog('treatment_request_new_treatment',
            `The treatment request has created a new treatment ${treatmentRef.id} for the user ${item.id}`)
        }).catch((err) => {
          console.log('err', err)
        });
      currentTreatmentID = treatmentRef.id
      let statusUpdate = {
        // currentTreatmentID: currentTreatmentID,
        treatmentRequestReviewed: true
      }
      await fb.usersCollection.doc(item.id).update(statusUpdate);
      addAdminLog('treatment_request_reviewed',
        `The treatment request has changed user's treatmentRequestReviewed to true for the user ${item.id}`)
      let treatmentRequest = await fb.usersCollection.doc(item.id).collection('TreatmentRequests')
        .orderBy("treatmentRequest", "desc").limit(1)
        .get().then((snap) => {
          if (!snap.empty) {
            return snap.docs[0]
          } else {
            return false
          }
        })
      await this.createPackage(item,currentTreatmentData,currentTreatmentID,treatmentRequest.id)
      // Update last
      await fb.usersCollection.doc(item.id)
        .collection('TreatmentRequests').doc(treatmentRequest.id).update({
          treatmentRequestDone: true,
          treatmentRequestDoneBy: fb.firebase.auth().currentUser.uid,
          treatmentRequestDoneDate: fb.timestamp.serverTimestamp()
        });
      addAdminLog('treatment_request_updated',
        `Updated the treatment request data for the user ${item.id}`)
    }
    this.setState({
      treatmentPopupLoaded:true,
      trPopupData: {
        ...this.state.trPopupData,
        link: `/admin/users/diagnosis/${item.id}/${currentTreatmentID}?type=t`
      }
    })
  }

  createPackage = async (userData,treatmentData,currentTreatmentID,treatmentRequestId) => {
    try {
      const shiftObj = await fb.metaCollection.doc('shipment').get().then((doc) => {
        if (!doc.exists) return false;
        return doc.data();
      });
      var ship_by = new Date();
      ship_by.setSeconds(ship_by.getSeconds() + shiftObj.ship_by_shift);
      var package_due = new Date();
      package_due.setSeconds(package_due.getSeconds() + shiftObj.package_due_shift);
      let productsSelected = [];
      for (let key in treatmentData.products) {
        productsSelected.push({
          value: key,
          title: treatmentData.products[key].title,
          qty: treatmentData.products[key].hasOwnProperty('qty') ? treatmentData.products[key].qty : 1,
          rule: treatmentData.products[key].rule
        });
      }
      var risk = "yellow";
      if(userData.paymentPlanActive) {
        if(userData.paymentPlanActive < Math.floor(Date.now() / 1000)) {
          risk = "red";
        } else {
          risk = "green";
        }
      }
      let obj = {
        ref: 'LD' + Math.floor(Date.now() / 1000),
        userId: userData.id,
        created: fb.timestamp.serverTimestamp(),
        userName: userData.name,
        userEmail: userData.email,
        shippingAddress: userData.shippingAddress,
        status: 'waiting_shipment',
        products: productsSelected,
        package_due: package_due,
        ship_by: ship_by,
        test: userData.test || false,
        risk: risk,
        deleted: false,
        treatmentId: currentTreatmentID,
        treatmentRequestId: treatmentRequestId
      };

      obj.shippingAddress.email = userData.email

      var doc = fb.packagesCollection.doc();
      obj.id = doc.id;
      let record = await doc.set(obj);
      addAdminLog('treatment_request_new_package',
        `The treatment request has created a new package ${doc.id} for the user ${userData.id}`)
    }catch (e) {
      console.log('!!!package_add crash: '+e.message);
    }
  }

  treatmentRemoveHandler = async (user_id, trID) => {
    if(window.confirm('Do you really want to delete the treatment request?')) {
      fb.usersCollection.doc(user_id).collection('TreatmentRequests').doc(trID).update({
        treatmentRequestDone: true
      }).then(() => {
        const filteredTreatmentList = this.state.usersTreatmentList.filter(i => i.id !== user_id)
        this.setState({usersTreatmentList: filteredTreatmentList})
      })
    }
  }

  render() {
    // console.log('state', this.state)
    return (
      <div className="content">
        {this.state.showLoadingTreatmentPopup ?
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Treatment Request Review"
                  hCenter={true}
                  content={
                    <>
                      <Row><Col md={12}>{this.state.trPopupData.name}</Col></Row>
                      <Row><Col md={12}>{this.state.trPopupData.date}</Col></Row>
                      <Row><Col md={12}>{this.state.treatmentPopupLoaded ? 'Created' : 'Creating'} new treatment</Col></Row>
                      <Row><Col md={12}>{this.state.treatmentPopupLoaded ? 'Created' : 'Creating'} package</Col></Row>
                      <Row><Col md={12}>
                        {this.state.treatmentPopupLoaded ?
                          <Link to={this.state.trPopupData.link} className="btn btn-success libi-admin-btn">OK</Link>
                          :
                          <div className="loadingLine">
                            <div/>
                          </div>
                        }
                      </Col></Row>
                    </>
                  }
                />
              </Col>
            </Row>
          </Container>
          :
          <Container fluid>
            { /*<Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-server text-warning" />}
                statsText="Capacity"
                statsValue="105GB"
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-wallet text-success" />}
                statsText="Revenue"
                statsValue="$1,345"
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Last day"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-graph1 text-danger" />}
                statsText="Errors"
                statsValue="23"
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText="In the last hour"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-twitter text-info" />}
                statsText="Followers"
                statsValue="+45"
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
          </Row> */}
            <Row>
              <Col md={6}>
                {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
                }
                <Card
                  title="Treatment request"
                  category=""
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <div>
                      <Tabs defaultActiveKey="treatmentRequestOpened" id="treatmentRequestTabs">
                        <Tab eventKey="treatmentRequestOpened" title="Opened">
                          <Table striped hover id="users--treatment-datatable">
                            <thead>
                            <tr>
                              <th>Date</th>
                              <th>Name</th>
                              <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.usersTreatmentList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td><span style={{display: "none"}}>{val.created_seconds}</span>{val.created}</td>
                                  <td><Link to={`users/view/${val.id}`}>{val.name ? val.name : val.email}</Link> <span dangerouslySetInnerHTML={{__html: val.payed}} /></td>
                                  <td>
                                    {val.version == 2
                                      ? <Link
                                        to={`/admin/users/assessment/${val.id}/${val.answerId ? val.answerId + '?review=true' : val.currentTreatmentID + '?type=t&review=true'}`}
                                          className="btn btn-success libi-admin-btn red"
                                        >Review</Link>
                                      : val.currentTreatmentID
                                        ? <Link
                                            onClick={(event) => this.handleTreatmentRequestReview(event, val)}
                                            to={`#`}
                                            className="btn btn-success libi-admin-btn red"
                                          >Review</Link>
                                        : <Link
                                            to={`/admin/users/diagnosis/${val.id}/${val.answerId}`}
                                            className="btn btn-success libi-admin-btn red"
                                          >Review</Link>
                                    }
                                    <span
                                      className="treatment-remove"
                                      onClick={() => this.treatmentRemoveHandler(val.id, val.trID)}
                                    >
                                      &#9587;
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                            </tbody>
                          </Table>
                        </Tab>
                        <Tab eventKey="treatmentRequestClosed" title="Closed">
                          <Table striped hover id="users--treatment-datatable_closed">
                            <thead>
                            <tr>
                              <th>Date</th>
                              <th>Name</th>
                              <th>Closed by</th>
                              <th>Closed date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.usersTreatmentListClosed.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td><span style={{display: "none"}}>{val.created_seconds}</span>{val.created}</td>
                                  <td><Link to={`users/view/${val.id}`}>{val.name ? val.name : val.email}</Link></td>
                                  <td>
                                    {val.admin}
                                  </td>
                                  <td>
                                    <span style={{display: "none"}}>{val.closed_date}</span>
                                    {convertFirebseSecondsToDate(val.closed_date)}
                                  </td>
                                </tr>
                              );
                            })}
                            </tbody>
                          </Table>
                        </Tab>
                      </Tabs>
                    </div>
                  }
                />
                <Card
                  title="Waiting for Assesment"
                  category=""
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <div>
                      <Table striped hover id="users-datatable">
                        <thead>
                        <tr>
                          <th>Created</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.usersList.map((val, key) => {
                          return (
                            <tr key={key} id={key}>
                              <td><span style={{display: "none"}}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.name ? val.name : val.email}</Link></td>
                              <td>
                                {
                                  val.answerId && <Link to={`/admin/users/diagnosis/${val.id}/${val.answerId}`}
                                                        className="btn btn-success libi-admin-btn">Review</Link>
                                }
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </Table>
                    </div>
                  }
                />
              </Col>
              <Col md={6}>
                {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
                }
                <Card
                  title="Waiting for secondary questionnaire review"
                  category=""
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <div>
                      <Table striped hover id="weekly-users-datatable">
                        <thead>
                        <tr>
                          <th>Created</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.weeklyQuestionnaire.map((val, key) => {
                          return (
                            <tr key={key} id={key}>
                              <td><span style={{display: "none"}}>{val.created_seconds}</span>{val.created}</td>
                              <td><Link to={`users/view/${val.id}`}>{val.name ? val.name : val.email}</Link></td>
                              <td>
                                {
                                  val.answerId && <Link to={`/admin/users/answers/${val.id}/${val.answerId}`}
                                                        className="btn btn-success libi-admin-btn">Review</Link>
                                }
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </Table>
                    </div>
                  }
                />
              </Col>
              <Col md={6}>
                <Card
                  id="chartActivity"
                  title="Registrations"
                  category=""
                  stats=""
                  statsIcon=""
                  content={
                    <div className="ct-chart">
                      {this.state.usersBarChart.labels.length > 0 &&
                      <Bar
                        data={this.state.usersBarChart}
                        options={{
                          title: {
                            display: true,
                            text: 'Average Customers per month',
                            fontSize: 20
                          },
                          legend: {
                            display: true,
                            position: 'right'
                          }
                        }}
                      />
                      }
                    </div>
                  }
                />
              </Col>
              { /*<Col md={6}>
              <Card
                statsIcon=""
                id="chartHours"
                title="Sales"
                category=""
                stats=""
                content={
                  <div className="ct-chart">
                    { this.state.salesLineChart.labels.length > 0 &&
                      <Line
                        data={this.state.salesLineChart}
                        options={{
                          title:{
                            display:true,
                            text:'Average Sales per month',
                            fontSize:20
                          },
                          legend:{
                            display:true,
                            position:'right'
                          }
                        }}
                      />
                    }
                  </div>
                }
              />
            </Col> */}
            </Row>
            {/*
          <Row>
            <Col md={4}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Email Statistics"
                category="Last Campaign Performance"
                stats="Campaign sent 2 days ago"
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    <ChartistGraph data={dataPie} type="Pie" />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendPie)}</div>
                }
              />
            </Col>
            <Col md={6}>
              <Card
                title="Tasks"
                category="Backend development"
                stats="Updated 3 minutes ago"
                statsIcon="fa fa-history"
                content={
                  <div className="table-full-width">
                    <table className="table">
                      <Tasks />
                    </table>
                  </div>
                }
              />
            </Col>
          </Row>
          */}
          </Container>
        }
      </div>
    );
  }
}

export default Dashboard;
