import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table
} from "react-bootstrap";
import Card from "../../components/Card/Card.jsx";
import { adminLogsListThArray } from "../../variables/Variables.jsx";
import PageLoader from "../../components/Loader/PageLoader.jsx";

//Datatable Modules
import $ from 'jquery';
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import api from "api.js";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

class AdminLogsList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      adminLogs: [],
      errors: {}
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#list-datatable').DataTable({
      "bStateSave": true,
      "order": [[0, "desc"]]
    });
  }

  fetchData = async () => {
    this.setState({ isLoading: true })

    const { data: adminLogsArr } = await api.get('getAdminLogs?limit=1000')

    this.setState({
      adminLogs: adminLogsArr,
      isLoading: false
    })
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Admin Logs"
                category=""
                ctTableFullWidth
                ctTableResponsive
                loader={this.state.isLoading && <PageLoader />}
                content={
                  <Table striped hover id="list-datatable">
                    <thead>
                      <tr>
                        {adminLogsListThArray.map((val, key) => (
                          <th key={key}>{val}</th>
                        ))}
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.adminLogs.map((val, key) => {
                        return (
                          <tr key={key}>
                            <td style={{ whiteSpace: 'nowrap' }}><span style={{ display: "none" }}>{val.time}</span>{moment(new Date(+val.time)).format('DD MMM YYYY HH:mm')}</td>
                            <td>{val.title}</td>
                            <td>{val.description}</td>
                            <td>{val.userEmail}</td>
                            <td style={{ width: '200px' }}>
                              {val.customerId && val.customerName
                                ? (
                                  <Link to={`/admin/users/view/${val.customerId}`}>{val.customerName}</Link>
                                )
                                : '-'
                              }
                            </td>
                            <td>
                              {val.dataBefore && val.dataAfter
                                ? (
                                  <Link to={`/admin/admin-logs/${val.id}`}><i className="fa fa-eye" /></Link>
                                )
                                : '-'
                              }
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AdminLogsList;
