import React, { Component } from "react";
import { staticWebImages } from "../../components/Utils/images";

class Loading extends Component { 
  constructor(props){
    super(props);
  }

  render() {
    return (
        <section className="Sign_Home">
        <div className="container main-Section-box">
          <div className="row">
            <div className="col-md-12 col-12 mx-auto">
              <div className="main_Box">
                <h3 className="main_txt">Loading..</h3>
              </div>
            </div>
          </div>
        </div>
        <p className="copy"> © 2020 Libi&amp;Daughters </p>
      </section>
    );
  }
}

export default Loading;
