import React, { Component } from "react";
import { staticWebImages } from "../../components/Utils/images";
import {countries, langText} from "../../components/Utils/helper";
import SelectSearch from 'react-select-search';
import * as fb from "../../firebase";
import Loading from "./Loading";

class BillingAddress extends Component {
    constructor(props){
        super(props);
        this.state = {
            customer:"",
            error:"",
            errors:{},
            disabled : false,
            address: {
                fullName: '',
                country: '',
                city: '',
                street: '',
                apartment: '',
                zipCode: ''
            },
            titleText: '',
            contentText: '',
            fullnamePlace: '',
            countryPlace: '',
            cityPlace: '',
            streetPlace: '',
            apartmentPlace: '',
            zipPlace: '',
            submitLabel: '',
            isLoading: false,
        }
        this._isMounted = false;
    }

    componentDidMount = async () => {
        let langTextObj = await langText();
        this.setState({
            titleText: langTextObj.billing_address_title,
            contentText: langTextObj.shipping_details_detail,
            fullnamePlace: langTextObj.shipping_details_fullname_placeholder,
            countryPlace: langTextObj.shipping_details_country_placeholder,
            cityPlace: langTextObj.shipping_details_city_placeholder,
            streetPlace: langTextObj.shipping_details_street_placeholder,
            apartmentPlace: langTextObj.shipping_details_appartment_placeholder,
            zipPlace: langTextObj.shipping_details_zipcode_placeholder,
            submitLabel: langTextObj.shipping_details_bottom_button
        });
        let uid = localStorage.getItem('userId');
        if(!uid){
            this.props.history.push('/')
        }else{
            const user = await fb.usersCollection.doc(uid).get()
                .then(user => {
                    if (user.exists) {
                        if (user.data().status !== 'waiting_payment' && user.data().status !== 'waiting_diagnose') {
                            this.props.history.push('/')
                        }
                    } else {
                        this.props.history.push('/')
                    }
                });
        }
        try {
            let billingAddress = JSON.parse(localStorage.getItem('billingAddress'));
            if (billingAddress) {
                this.setState({address: billingAddress});
            }
        } catch (e) {
        }
        this.setState({
            isLoading: true
        });
    }

    generateRandomNDigits = (n) => {
        return Math.floor(Math.random() * (9 * (Math.pow(10, n)))) + (Math.pow(10, n));
    }
    changeCountry = (selectedItem) => {
        this.setState(prevState => ({
            address: {
                ...prevState.address,
                country: selectedItem
            }
        }));
    }
    changeFullName = (e) => {
        e.persist();
        if(!/^[A-Za-z -]+$/.test(e.target.value)){
            this.setState({errors: {name:'Latin symbols only'}});
        }else{
            this.setState({errors: {name:''}});
        }
        this.setState(prevState => ({
            address: {
                ...prevState.address,
                fullName: e.target.value
            }
        }));
    }
    changeCity = (e) => {
        e.persist();
        this.setState(prevState => ({
            address: {
                ...prevState.address,
                city: e.target.value
            }
        }));
    }
    changeStreet = (e) => {
        e.persist();
        this.setState(prevState => ({
            address: {
                ...prevState.address,
                street: e.target.value
            }
        }));
    }
    changeApartment = (e) => {
        e.persist();
        this.setState(prevState => ({
            address: {
                ...prevState.address,
                apartment: e.target.value
            }
        }));
    }
    changeZipCode = (e) => {
        e.persist();
        this.setState(prevState => ({
            address: {
                ...prevState.address,
                zipCode: e.target.value
            }
        }));
    }

    showErrors = () => {
        let errors = this.state.errors;
        return <p className="alert">{Object.keys(errors).map(function(key) {
            if(errors[key]){
                return <span key={key}><img src={staticWebImages.alertCircle}/> {errors[key]}<br/></span>
            }
        })}</p>;
    }

    submit = async (e) => {
        e.preventDefault();
        let validate_fields = ['fullName', 'country', 'city', 'street', 'apartment', 'zipCode'];
        await this.setState({errors: {}});
        for (const validateField of validate_fields) {
            if (!this.state.address[validateField]) {
                let errors = this.state.errors;
                errors[validateField] = `${validateField} field is required`
                await this.setState({errors: errors});
            }
        }
        if (Object.keys(this.state.errors).length > 0) return;
        localStorage.setItem('billingAddress', JSON.stringify(this.state.address));
        // this.props.history.push('/confirm-details');
        this.props.history.push('/subscribe');
    }

    render() {
        console.log('render', this.state)
        return (
            <section className="Subscription">
                {this.state.isLoading ? <>
                <span className="custm-angle">
                  <span className="backBtn" onClick={this.props.history.goBack}><i className="fa fa-angle-left" aria-hidden="true" /></span>
                  <span className="mobileTitle">{this.state.titleText}</span>
                </span>
                <div className="container_5-fluid">
                    <div className="row_5">
                        <div className="col_5-lg-6 col_5-12 hidden-xs hidden-sm" style={{padding: 0}}>
                            <div className="Subscription_cust">
                                <img src={staticWebImages.depost} alt="" style={{width:"100%",maxWidth:"530px"}}/>
                            </div>
                        </div>
                        <div className="col_5-lg-6 col_5-12">
                            <div className="subs_mainBox">
                                <div className="subsRightBoxWrapper">
                                    <div className="subsRightBox">
                                        <div className="main-logo">
                                            <img src={staticWebImages.logo} alt="" className="img-responsive center-block web-logo-hide" />
                                        </div>
                                        <h2 className="main_txt_sign text-center hidden-xs" style={{paddingBottom: '20px'}}>{this.state.titleText}</h2>
                                        <p className="shiping_para">{this.state.contentText}</p>
                                        <div className="form-section_add">
                                            <form className="m-auto" onSubmit={this.submit}>
                                                {
                                                    this.state.errors && this.showErrors()
                                                }
                                                <input type="text" pattern="[A-Za-z -]+" onChange={this.changeFullName} value={this.state.address.fullName}  className="form-control" placeholder={this.state.fullnamePlace} />
                                                <SelectSearch
                                                    options={countries()}
                                                    search={true}
                                                    value={this.state.address.country}
                                                    placeholder={this.state.countryPlace}
                                                    onChange={this.changeCountry}
                                                />
                                                <input type="text" onChange={this.changeCity} value={this.state.address.city} className="form-control" placeholder={this.state.cityPlace} />
                                                <input type="text" onChange={this.changeStreet} value={this.state.address.street} className="form-control" placeholder={this.state.streetPlace} />
                                                <input type="text" onChange={this.changeApartment} value={this.state.address.apartment} className="form-control" placeholder={this.state.apartmentPlace} />
                                                <input type="text" onChange={this.changeZipCode} value={this.state.address.zipCode} className="form-control" placeholder={this.state.zipPlace} />
                                                <input type="submit" value={this.state.submitLabel} className="form-control custom-btn-web" />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <p className="new_copyright mt-auto"> © 2020 Libi&amp;Daughters </p>
                            </div>
                        </div>
                    </div>
                </div>
                </>: <Loading />}
            </section>
        );
    }
}

export default BillingAddress;
