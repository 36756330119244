import React, { Component } from "react";
import image from "assets/img/loader.gif";

class PageLoader extends Component {
  render() {
    return (
      <div className="content loader" 
        style={{
            textAlign: "center",
            position: "absolute",
            top: "0",
            zIndex: "999",
            background: "rgb(255, 255, 255)",
            left: "0",
            right: "0",
            bottom: "0",
            borderRadius: "15px"
        }}
        >
        <img style={{width: "50px", top: "50%",transform: "translateY(-50%)",position: "relative"}} src={image} alt="" />
      </div>
    );
  }
}

export default PageLoader;
