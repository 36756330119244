import Card from 'components/Card/Card'
import PageLoader from 'components/Loader/PageLoader'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Button from "../../components/CustomButton/CustomButton.jsx";
import { Link } from 'react-router-dom'
import api from 'api'
import { convertMilisecondsToDate } from 'components/Helper/index.jsx'

const StripeShopifyView = () => {
  const id = window.location.pathname.split('/')[4]
  const type = new URLSearchParams(window.location.search).get('type')

  const [isLoading, setIsLoading] = useState(true)
  const [order, setOrder] = useState(null)

  const getOrder = async () => {
    try {
      setIsLoading(true)

      const {data: orders} = await api.get('getOrders')
      const orderObj = orders?.find(i => i?.paymentId === `${type}/${id}`)
      setOrder(orderObj)
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getOrder()
  }, [])

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Order View"
              button={
                <Link to={`/admin/stripe-shopify`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
              }
              category=""
              ctTableFullWidth
              ctTableResponsive
              loader={isLoading && <PageLoader />}
              content={
                <div>
                  <p>
                    <b>Ref: </b>{(order && order.ref) ? order.ref : '-'}
                  </p>
                  <p>
                    <b>Created: </b>{order ? convertMilisecondsToDate(new Date(order.created).getTime()) : '-'}
                  </p>
                  <p>
                    <b>User Email: </b>{order ? order.userEmail : '-'}
                  </p>

                  {(order && (!order.userId || !order.expertId)) &&
                    <Link to={`/admin/stripe-shopify/edit/${id}?type=${type}`}>
                      <Button
                        className="btn-fill libi-admin-btn"
                        bsStyle="info" 
                        fill
                      >
                        Edit
                      </Button>
                    </Link>
                  }
                </div>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default StripeShopifyView