import React, { Component } from "react";
import { staticWebImages } from "../../components/Utils/images";
import { langText } from "../../components/Utils/helper";
import * as fb from "../../firebase";
import Loading from "./Loading";

class Language extends Component {
  constructor(props){
    super(props);
    this.state = {
      language: '',
      errors:{},
      disabled: false,
      titleText: '',
      languageLabel: '',
      submitLabel: '',
      isLoading: false,
    }
  }

  onChangeHandler = async (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'language'){
      window.localStorage.setItem('language',value);
      this.setState({language:value});
      let langTextObj = await langText(value);
      this.setState({
        titleText: langTextObj.onboarding_language_title,
        languageLabel: langTextObj.onboarding_language_select_language,
        submitLabel: langTextObj.onboarding_language_bottom_button
      });
    }
  };

  async componentDidMount() {
    // Disable web app
    // fb.auth.signOut().then(() => {
    //   localStorage.clear()
    //   this.props.history.push('/admins')
    // }).catch((error) => {
    //   localStorage.clear()
    //   this.props.history.push('/admins')
    // });
    this.props.history.push('/admins')
    return
    // Disable web app
    let langTextObj = await langText();
    this.setState({
      titleText: langTextObj.onboarding_language_title,
      languageLabel: langTextObj.onboarding_language_select_language,
      submitLabel: langTextObj.onboarding_language_bottom_button
    });
    let language = window.localStorage.getItem('language');
    if(!language){
      let userLang = navigator.language || navigator.userLanguage;
      language = userLang.toLowerCase() === 'ru' ? 'RU' : 'EN'
    }
    this.setState({
      language: language
    });
    let uid = localStorage.getItem('userId');
    if(uid){
      const user = await fb.usersCollection.doc(uid).get();
      if(user.exists) {
        //here needs to check the current status of existing user
        this.doRoute(user.data())
      }else{
        localStorage.removeItem('userId');
        localStorage.removeItem('userLogin');
      }
    }else{
      localStorage.removeItem('userId');
      localStorage.removeItem('userLogin');
    }
    this.setState({
      isLoading: true
    });
  }

  doRoute = (user_data) => {
    switch(user_data.status){
      case 'registered':
        this.props.history.push('/accept-privacy');
        break;

      case 'waiting_list':
        this.props.history.push('/invitation');
        break;

      case 'questionnaire1':
        this.props.history.push('/questionnaire')
        break;

      case 'deposit_vip':
        this.props.history.push('/deposit-vip')
        break;

      case 'waiting_diagnose':
        if(user_data.paymentPlanActive){
          this.props.history.push('/thankyou-diagnose')
        }else{
          this.props.history.push('/deposit')
        }
        break;

      case 'waiting_delivery':
          this.props.history.push('/after-payment')
        break;

      case 'waiting_payment':
        this.props.history.push('/skincare-plan')
        break;

      default:
        alert("Something went wrong! Please try after some time.")
        break;
    }
  }

  render() {
    return (
        <section className="Subscription" style={{background: 'rgba(238, 221, 211, 0.6)'}}>
          {this.state.isLoading ?
          <div className="container_5-fluid">
            <div className="row_5">
              <div className="col_5-lg-6 col_5-12" style={{padding: 0}}>
                <div className="Subscription_cust" style={{background: 'transparent'}}>
                  <img src={staticWebImages.depositConfirm} alt="" style={{width:"100%",maxWidth:"530px"}}/>
                </div>
              </div>
              <div className="col_5-lg-6 col_5-12">
                <div className="subs_mainBox">
                  <div className="subsRightBoxWrapper">
                    <div className="subsRightBox">
                      <div className="main-logo">
                        <img src={staticWebImages.logo} alt="" className="img-responsive center-block web-logo-hide" />
                      </div>
                      <h2 className="main_txt_sign text-center" style={{paddingBottom: '20px'}}>{this.state.titleText}</h2>
                      <div className="form-section_add">
                          <div className="row">
                            <div className="col-12">
                              <div className="shiping_para">
                                <div className="horizontalRadio">
                                  <span>{this.state.languageLabel}</span>
                                  <div className="horizontalRadioInputs">
                                    <input id="EN" type="radio" name="language" value="EN" onChange={this.onChangeHandler} checked={this.state.language === 'EN' } />
                                    <label htmlFor="EN">EN</label>
                                    <input id="RU" type="radio" name="language" value="RU" onChange={this.onChangeHandler}  checked={this.state.language === 'RU' } />
                                    <label htmlFor="RU">RU</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <a href="/signup" className="form-control custom-btn-web"
                                 style={{width:"100%"}}
                              >{this.state.submitLabel}</a>
                            </div>
                          </div>
                        {
                          this.state.errors && this.state.errors.email &&
                          <p className="alert">
                            <img src={staticWebImages.alertCircle}/> {this.state.errors.email }
                          </p>
                        }
                      </div>
                    </div>
                  </div>
                  <p className="new_copyright mt-auto"> © 2020 Libi&amp;Daughters </p>
                </div>
              </div>
            </div>
          </div>
              : <Loading />}
        </section>
    );
  }
}

export default Language;
