import React, { Component } from "react";
import { Link } from "react-router-dom";

import { staticWebImages } from "../../components/Utils/images";
import * as fb from "../../firebase";
import {langText} from "../../components/Utils/helper";
import Loading from "./Loading";


class DepositConfirm extends Component {
  constructor(props){
    super(props);
    this.state = {
      country:"",
      errors:{},
      disabled : false,
      titleText: '',
      contentText: '',
      signOutLabel: '',
      isLoading: false,
    }
  }

  componentDidMount = async () => {
    let langTextObj = await langText();
    this.setState({
      titleText: langTextObj.standby_vip_title,
      contentText: langTextObj.standby_vip_detail,
      signOutLabel: langTextObj.standby_signout_button,
    });
    //get current user
    const userId = localStorage.getItem('userId');
    if (userId) {
      this.setState({
        userId: userId
      });
    }
    this.setState({
      isLoading: true
    });
  }

  logout = () => {
    fb.auth.signOut().then(() => {
      localStorage.removeItem('userId');
      localStorage.removeItem('userLogin');
      this.props.history.push('/')
    }).catch((error) => {
      alert(error)
    });
  }

  render() {
    console.log('render', this.state)
    return (
      <div className="deposit">
        <section className="Sign_Home ">
          {this.state.isLoading ? <>
          <span className="custm-angle d-none"><i className="fa fa-angle-left" aria-hidden="true" />
          </span>
          <div className="container-fluid col-md-10 mx-auto">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6 col-12 static-on-mobile">
                <div className="mainImgBox depositImg no-trans-mobile">
                  <img src={staticWebImages.depositConfirm} className="img-responsive center-block py-auto" alt="depositImg" />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 align-V-center ">
                <div className="deposit_main_Sec ">
                  <div className="main-logo">
                    <img src={staticWebImages.logo} className="img-responsive center-block web-logo-hide" />
                  </div>
                  <h2 className="main_txt" onClick={this.showNotification}>{this.state.titleText}</h2>
                  <p className="sec_text">{this.state.contentText}</p>
                  
                  <div className="form-section">
                    
                  </div>
                  <Link to={`#`} onClick={this.logout} className="web-custom-link">{this.state.signOutLabel}</Link>
                </div>
                <p className="copyright center-block text-center "> © 2020 Libi&amp;Daughters </p>
              </div>
            </div>
          </div>
          </>: <Loading />}
        </section>
      </div>
    );
  }
}

export default DepositConfirm;
