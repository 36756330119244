import React, { Component } from "react";
import ReactStars from "react-rating-stars-component";

class TypeFormRating extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:""
        }
    }

    onChangeHandler = async (event) => {
        this.props.onChange(event)
    }

    getMaxVal(){
        if(this.props.properties.hasOwnProperty('steps')){
            return this.props.properties.steps
        }else{
            return 5
        }
    }

    render() {
        return (
            <ReactStars
                classNames="TypeFormRating"
                emptyIcon={<i className="EmptyIcon">&#9734;</i>}
                filledIcon={<i className="FullIcon">&#9733;</i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                char="0"
                count={this.getMaxVal()}
                onChange={this.onChangeHandler}
                isHalf={false}/>
        );
    }
}

export default TypeFormRating;
