import React, { Component } from "react";
import { Link } from "react-router-dom";

import { staticWebImages } from "../../components/Utils/images";
import * as fb from "../../firebase";
import {langText} from "../../components/Utils/helper";
import Loading from "./Loading";


class ThankyouDiagnose extends Component {
  constructor(props){
    super(props);
    this.state = {
      country:"",
      errors:{},
      disabled : false,
      titleText: '',
      contentText: '',
      signOutLabel: '',
      isLoading: false,
    }
  }

  componentDidMount = async () => {
    let langTextObj = await langText();
    this.setState({
      titleText: langTextObj.package_status_waiting_shipment_bottom_title,
      contentText: langTextObj.standby_enabled_push,
      signOutLabel: langTextObj.standby_signout_button,
    });
    //get current user
    const userId = localStorage.getItem('userId');
    if(!userId){
      this.props.history.push('/')
    }else{
      fb.usersCollection.doc(userId).get().then(doc => {
        if(doc.exists){
          if(doc.data().status !== 'waiting_diagnose'){
            this.props.history.push('/')
          }
        }else{
          this.props.history.push('/')
        }
      });
    }

    if (userId) {
      this.setState({
        userId: userId
      });
    }
    this.setState({
      isLoading: true
    });
  }

  logout = () => {
    fb.auth.signOut().then(() => {
      localStorage.removeItem('userId');
      localStorage.removeItem('userLogin');
      this.props.history.push('/')
    }).catch((error) => {
      alert(error)
    });
  }

  render() {
    console.log('render', this.state)
    return (
        <section className="Subscription" style={{background: 'rgba(238, 221, 211, 0.6)'}}>
          {this.state.isLoading ?
          <div className="container_5-fluid">
            <div className="row_5">
              <div className="col_5-lg-6 col_5-12" style={{padding: 0}}>
                <div className="Subscription_cust" style={{background: 'transparent'}}>
                  <img src={staticWebImages.depost} alt="" style={{width:"100%",maxWidth:"530px"}}/>
                </div>
              </div>
              <div className="col_5-lg-6 col_5-12">
                <div className="subs_mainBox">
                  <div className="subsRightBoxWrapper">
                    <div className="subsRightBox">
                      <div className="main-logo">
                        <img src={staticWebImages.logo} alt="" className="img-responsive center-block web-logo-hide" />
                      </div>
                      <h2 className="main_txt_sign text-center" style={{paddingBottom: '20px'}}>{this.state.titleText}</h2>
                      <p className="shiping_para" style={{marginTop:"0px"}}>{this.state.contentText}</p>
                      <div className="form-section_add">

                      </div>
                      <Link to={`#`} onClick={this.logout} className="web-custom-link">{this.state.signOutLabel}</Link>
                    </div>
                  </div>
                  <p className="new_copyright mt-auto"> © 2020 Libi&amp;Daughters </p>
                </div>
              </div>
            </div>
          </div>
              : <Loading />}
        </section>
    );
  }
}

export default ThankyouDiagnose;
