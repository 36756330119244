import React, { Component } from "react";
import {withRouter} from 'react-router-dom'
import "../../components/Card/Card";
import "./Chat.css";
import ChatBoard from "components/Chat/ChatBoard";
import WelcomeBoard from "components/Chat/WelcomeBoard";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import { ReactComponent as DoubleCheck } from "../../assets/img/double-check.svg"
import {
  staticImages,
  convertFirebseSecondsToDate
} from "components/Helper/index";
import * as fb from '../../firebase';
import api from "api";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      usersList: [],
      currentUser: "",
      currentPeerUser: "",
      staticImages: {},
      search: "",
      defaultAdminId: "q6pn8JbJk3ffpqB2yIYcw50poHJ3"
    }
    this._isMounted = false;
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name ==='search') {
      this.setState({search:value});
      this._isMounted = true;
    }
  }

  //CHAT FROM USER'S LIST IN DASHBOARD
  manageChatFromUsersList = async () => {
    const peerUserId = window.location.pathname.split('/')[3];
    if(peerUserId && peerUserId !== ":id"){
      await api.get('userByID', {params: {userId: peerUserId}}).then(res => {
        const dataObj = res.data
        console.log('dataobj', dataObj, peerUserId)
        fb.chatRoomCollection
          .doc(peerUserId)
          .get()
          .then((chatRooms) => {
            if(chatRooms.exists){
              let chatsRoom = chatRooms.data();
              let currentPeerUser = {
                userID:peerUserId,
                name:dataObj.name || "",
                userPic:dataObj.picture || "",
                chatId:chatsRoom.id,
                type:chatsRoom.type,
                lastMessage:chatsRoom.lastMessage,
                admins:chatsRoom.admins,
                createdOn:fb.timestamp.serverTimestamp(),
              };
              this.setState({currentPeerUser:currentPeerUser})
            }else{
              const chatRoomsRef = fb.chatRoomCollection
                .doc(peerUserId);
              chatRoomsRef.set({
                id: chatRoomsRef.id,
                lastUserID:this.state.currentUser.id,
                lastMessage:"",
                type: 1,
                lastName:this.state.currentUser.name,
                peerName:dataObj.name || "",
                lastUserPic:dataObj.picture ? dataObj.picture : "",
                admins:[this.state.currentUser.id, this.state.defaultAdminId],
                createdOn:fb.timestamp.serverTimestamp()
              }).then(res => {
                const chatRef = fb.chatsCollection.doc(peerUserId)
                  .collection('messages')
                  .doc();
                chatRef.set({
                  id: chatRef.id,
                  chatId: chatRoomsRef.id,
                  content: "",
                  type: 1,
                  userId:this.state.currentUser.id,
                  userName:this.state.currentUser.name,
                  picture:this.state.currentUser.picture,
                  peerName:dataObj.name || "",
                  createdOn:fb.timestamp.serverTimestamp()
                }).then(res => {
                });
              });
              let currentPeerUser = {
                userID:peerUserId,
                name:dataObj.name,
                userPic:this.state.currentUser.picture,
                // chatId:chatsRoom.id,
                type:1,
                // lastMessage:chatsRoom.lastMessage,
                admins:[this.state.currentUser.id],
                createdOn:fb.timestamp.serverTimestamp(),
              };
              this.setState({currentPeerUser:currentPeerUser})
            }
          }).catch(function(error) {
          console.log("Error getting documents: ", error);
        });
      });
    }
  }

  componentDidMount = async() => {
    const si = await staticImages()
    this.setState({staticImages:si})
    this.setState({isLoading:true});
    await this.setCurrentUser();
  }

  setCurrentUser =  async () => {
    fb.auth.onAuthStateChanged(async (user) => {
      if (user != null) {
        await this.getCurrentUserData(user.uid);
      }
    });
  }

  getCurrentUserData = async (id) => {
    const {data: userByID} = await api.get('userByID', {params: {userId: id}})
    this.setState({
      currentUser: userByID
    }, async () => {
      await this.getRooms();
      await this.manageChatFromUsersList();
      this.setState({isLoading:false});
    })
  }

  getRooms = async () => {
    const usersArr = [];
    let roomRef = fb.chatRoomCollection;
    if(this.state.search){
      const termR = this.state.search.trim();
      const search = termR.charAt(0).toUpperCase() + termR.slice(1).toLowerCase();
      roomRef = roomRef.where('peerName', '>=', search).where('peerName', '<=', search+"\uf8ff");
    }
    // roomRef = roomRef.where('admins', 'array-contains', this.state.currentUser.userID);
    // roomRef.orderBy("createdOn", "desc")
    roomRef.onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          const dataObj = change.doc.data();
          usersArr.push({
            id:dataObj.id,
            userID:dataObj.id,
            name:dataObj.peerName || "",
            userPic:dataObj.lastUserPic,
            type:dataObj.type,
            lastMessage:dataObj.lastMessage,
            messageStatusAdmin:dataObj.messageStatusAdmin,
            admins:dataObj.admins,
            createdOn:dataObj.createdOn
          });
        } else if (change.type === "modified") {
          const dataObj = change.doc.data();
          usersArr.forEach((rooms, key) => {
            if(rooms.id===dataObj.id){
              usersArr[key].lastMessage = dataObj.lastMessage;
              usersArr[key].createdOn = dataObj.createdOn;
              usersArr[key].messageStatusAdmin = dataObj.messageStatusAdmin;
              usersArr[key].type = dataObj.type;
              usersArr[key].admins = dataObj.admins;
            }
          });
        }
      });
      this.setState({usersList:usersArr.sort((a, b) => b.createdOn - a.createdOn)})
    })
  }

  renderListUser = () => {
    let recentUsersList = [];
    this.state.usersList.forEach((val, key) => {
        recentUsersList.push(
          <div className={`chat_list_item ${this.state.currentPeerUser && this.state.currentPeerUser.userID === val.userID
            ? 'active_chat' : '' }`} onClick={() => { this.setState({currentPeerUser: val}) }} key={key}>
            <div className="avatar_wrapper">
              <img src={val.userPic ? val.userPic : this.state.staticImages.defaultAvtar} alt="" />
            </div>
            <div className="chat_list_data">
              <div>
                <span className="chat_list_data_name">{((val.firstName && val.lastName) && `${val.firstName} ${val.lastName}`) || val.name ||
                                  ((val.first_name && val.last_name) && `${val.first_name} ${val.last_name}`) || '-'}</span>
                <span className="chat_list_data_date">{val.createdOn ? convertFirebseSecondsToDate(val.createdOn.seconds) : convertFirebseSecondsToDate(Date.now() / 1000)}</span>
              </div>
              <div>
                <span className="chat_list_data_message">
                  {val.type===1 ?
                    val.lastMessage :
                    (val.type===2 || val.type===3 ?
                      <img className="recrent-file" alt="" src={this.state.staticImages.placeholderImage}/> :
                      '')
                  }
                </span>
                <span className="chat_list_data_read">{val.messageStatusAdmin===false ? <span className="unread">1</span> : <DoubleCheck />}</span>
              </div>
            </div>
          </div>
        )
    });
    return recentUsersList;
  }

  componentDidUpdate = () => {
    if(this._isMounted){
      this.getRooms();
      this._isMounted = false;
    }
  }

  clearCurrentPeerUser = () => {
    this.setState({currentPeerUser:''});
  }

  render() {
    return (
      <div className="content">
        <div className="chat_wrapper">
          {this.state.isLoading ? <PageLoader /> :
            <div className="user_list_wrapper">
              <h4>Chat</h4>
              <div className="search_wrapper">
                <span className="search_icon_wrapper"><i className="fa fa-search" aria-hidden="true" /></span>
                <input
                  type="text"
                  className="search-bar"
                  placeholder="Search"
                  name="search"
                  value={this.state.search}
                  onChange={(event)=>this.onChangeHandler(event)}
                />
              </div>
              <div className="inbox_chat_wrapper">
                <div className="inbox_chat">
                  { this.renderListUser() }
                </div>
              </div>
            </div>
          }
          {this.state.isLoading ? <PageLoader /> :
            <>{this.state.currentPeerUser ?
                <ChatBoard currentPeerUser={this.state.currentPeerUser} onExit={() => {this.clearCurrentPeerUser()}}/>
                :
                <WelcomeBoard />
            }</>
          }
        </div>
      </div>
    );
  }

}

export default withRouter(Chat)