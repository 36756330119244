import React, { Component } from "react";
import { 
  Container, 
  Row, 
  Col, 
  Table 
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card/Card.jsx";
import { eventListThArray } from "../../variables/Variables.jsx";
import * as fb from '../../firebase';  
import PageLoader from "../../components/Loader/PageLoader.jsx";
import {
getSelectedEventType, 
getSelectedEventTask,
convertFirebseSecondsToDate
} from "../../components/Helper/index";

//Datatable Modules
import $ from 'jquery'; 
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"

class EventList extends Component {

  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      functionTasks:[],
      errors:{}
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#list-datatable').DataTable({
      "bStateSave": true
    });
  }

  fetchData = async() => {
    this.setState({isLoading:true});
    const data = await fb.functionTaskCollection.get();
    const functionTaskArr = [];
    data.docs.forEach(doc=> {
        const dataObj = doc.data();
        if(doc.exists){
            functionTaskArr.push({
                id:doc.id,
                uid:dataObj.uid,
                name:dataObj.name,
                time: dataObj.time && dataObj.time.seconds ? convertFirebseSecondsToDate(dataObj.time.seconds) : "",
                type: dataObj.type,
                task: dataObj.task,
                time_seconds: dataObj.time && dataObj.time.seconds
            })
        }
    })
    this.setState({
        functionTasks:functionTaskArr,
        isLoading:false
    });
  }

  deleteUser = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await fb.functionTaskCollection.doc(id).delete().then(() => {
        this.fetchData();
      }).catch(function(error) {
        console.error("Error removing document: ", error);
      });
    }
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Events"
                category="All Event List"
                ctTableFullWidth
                ctTableResponsive
                button={
                  <Link to={`event/add`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"> Add Event</Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                    <Table striped hover id="list-datatable">
                        <thead>
                            <tr>
                            {eventListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                            })}
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.functionTasks.map((val, key) => {
                            return (
                                <tr key={key}>
                                <td><span style={{display:"none"}}>{val.time_seconds}</span>{val.time}</td>
                                <td>{val.name}</td>
                                <td>{getSelectedEventType(val.type)}</td>
                                <td>{getSelectedEventTask(val.task)}</td>
                                <td>
                                    <Link to={`event/edit/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;
                                    <Link to={`#`} onClick={() => {this.deleteUser(val.id)}}><i className="fa fa-times" /></Link>
                                </td>
                                </tr>
                            );
                            })}
                        </tbody>
                    </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EventList;
