import React, { Component } from "react";
import { NavItem, Nav, NavDropdown } from "react-bootstrap";
import { auth } from "../../firebase";
import { withRouter } from "react-router-dom"; 
import { UserContext } from "../../providers/UserProvider";
import * as fb from '../../firebase';
import axios from 'axios';
import api from "api";

class AdminNavbarLinks extends Component {
  
  static contextType = UserContext;
  constructor(props){
    super(props)
    this.state = {
      usersListCount:"",
      unreadList:"",
      mount: true
    }
    this._isMounted = false;
    this.logout = this.logout.bind(this)
  }

  logout = () => {
    auth.signOut().then(() => {
      //unsubscrip chat topics
      let currentToken = localStorage.getItem('firebaseClientToken');
      api.get(`s/unsubscribe/chat/${currentToken}`);
      localStorage.clear();
      this.props.history.push("/admins");
    });
  }

  getRooms = async () => {
    this.setState({unreadList:[]})
    const usersArr = [];
    fb.chatRoomCollection
    .where("messageStatusAdmin", "==", false)
    .onSnapshot(snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "removed") {
          const dataObj = change.doc.data();
          usersArr.forEach((rooms, key) => {
            if(rooms.id===dataObj.id){
              usersArr.splice(key,1);
            }
          });
        }else if(change.type === "added"){
          const dataObj = change.doc.data();
          usersArr.push({
            id:dataObj.id,
            userID:dataObj.id,
            name:dataObj.peerName,
            userPic:dataObj.lastUserPic,
            type:dataObj.type,
            lastMessage:dataObj.lastMessage,
            messageStatusAdmin:dataObj.messageStatusAdmin,
            createdOn:dataObj.createdOn,
          });
        }
      }); 
      this.setState({unreadList:usersArr})
    })
  }
  
  componentDidMount = async() => {
    await this.setCurrentUser();
    await this.fetchData();
  }

  setCurrentUser =  async () => {
    fb.auth.onAuthStateChanged(async (user) => {
      if (user != null) {
        await this.getCurrentUserData(user.uid);
      }
    });
  }

  getCurrentUserData = async (id) => {
    const {data: userByID} = await api.get('userByID', {params: {userId: id}})
    this.setState({
      currentUser: userByID
    }, async () => {    
      await this.getRooms();
    })
  }

  fetchData = async () => {
    this.setState({isLoading:true});

    let {data: users} = await api.get('getUsers')
    users = users?.filter(i => i?.status === 'waiting_diagnose' && !i?.deleted)

    this.setState({
      usersListCount: users?.length,
    });
    this.updateLastLoginEveryTime();
  }

  updateLastLoginEveryTime = () => {
    setInterval(async() => {
      if(this.state.currentUser && this.state.currentUser.id){
        await api.post('updateUser', {
          id: this.state.currentUser.id,
          lastOnlineTime:fb.timestamp.serverTimestamp()
        });
        console.log('test1111')
      }
    }, 60 * 1000);
  }

  render() {
    const user = this.context;
    return (
      <div>
        <Nav>
          <Nav.Link onClick = {this.logout}>{user ? user.email : ""} Logout</Nav.Link>
        </Nav>
      </div>
    );
  }
}

export default withRouter(AdminNavbarLinks);
