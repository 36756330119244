import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import {
  eventTpeDropDown,
  eventTaskDropDown, addAdminLog
} from "../../components/Helper/index";
import { Link } from "react-router-dom";
import SelectSearch from 'react-select-search';
import DatePicker from "react-datepicker";
import api from "api.js";

class AddEvent extends Component {

    constructor(props){
        super(props);
        this.state = {
        id:"",
        uid:"",
        name:"",
        customer:"",
        task:"",
        time:"",
        type:"",
        approved:"",
        errors:{},
        disabled : false,
        selectedOptions:[],
        customersList:[]
        }
    }
  
    onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
        if(name === 'name') {
        this.setState({name:value});
        }else if(name === 'task'){
        this.setState({task:value});
        }else if(name === 'type'){
        this.setState({type:value});
        }
    };

    onChangeDOBHandler = (date) => {
        this.setState({time:date});
    };

  componentDidMount = async () => {
    //customers
    const { data: users } = await api.get('getUsers')
    let customersList = [];
    users.forEach(dataObj => {
      if (!dataObj.deleted || dataObj.deleted !== true) {
        customersList.push({
          name: dataObj.name + " <" + dataObj.email + ">",
          value: dataObj.id
        });
      }
    });
    this.setState({
      customersList: customersList
    });
  }

    handleValidation = () => {
        let {customer, type, task, time } = this.state;
        let errors = {};
        let formIsValid = true;

        //Customer
        if(!customer){
            formIsValid = false;
            errors["customer"] = "Customer name is required";
        }

        //Type
        if(!type){
            formIsValid = false;
            errors["type"] = "Type is required";
        }

        //Task
        if(!task){
            formIsValid = false;
            errors["task"] = "Task is required";
        }

        //Time
        if(!time){
            formIsValid = false;
            errors["time"] = "Time is required";
        }
        this.setState({errors: errors});
        return formIsValid;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({disabled: true});
        if(this.handleValidation(this.state.errors)) {
            fb.functionTaskCollection.add({
                uid:this.state.name,
                name:this.state.customer,
                time:this.state.time,
                type: this.state.type,
                task: this.state.task
            }).then(res => {
              addAdminLog('event_created',
                `Created event for the user ${this.state.name}`)
                this.props.history.push('/admin/event')
            }).catch(err=> {
                this.setState({
                    disabled: false
                });
            });
        }else{
            this.setState({
                disabled: false
            });
        }
    }

    onCustomerSelect = async (selectedItem) => {
        const {data: user} = await api.get('userByID', {params: {userId: selectedItem}})
        if(user){
            let name = user.name;
            this.setState({
                uid: selectedItem,
                name: selectedItem,
                customer: name,
            })
        }
    }

  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Add Event"
                button={
                  <Link to={`/admin/event`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Name</FormLabel>
                            <SelectSearch
                                options={this.state.customersList}
                                search={true}
                                value={this.state.name}
                                name="name"
                                placeholder="Select Customer"
                                onChange={(event)=>this.onCustomerSelect(event)}
                            />
                          <span className="help-block">{this.state.errors.customer}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Status</FormLabel>
                          <select
                            className= "form-control"
                            name="type"
                            value={this.state.type} 
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Type</option>
                            { 
                                eventTpeDropDown().map((status, index) => {
                                  return <option key={index} value={status.key}>{status.val}</option>
                                })
                            }
                          </select>
                          <span className="help-block">{this.state.errors.type}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Select Repeted</FormLabel>
                          <select
                            className= "form-control"
                            name="task"
                            value={this.state.task} 
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Status</option>
                            { 
                                eventTaskDropDown().map((status, index) => {
                                  return <option key={index} value={status.key}>{status.val}</option>
                                })
                              }
                          </select>
                          <span className="help-block">{this.state.errors.task}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Time</FormLabel>
                          <DatePicker
                            selected={this.state.time}
                            dateFormat="yyyy/MM/dd"
                            className="form-control"
                            placeholderText="Example: yyyy/MM/dd"
                            onChange={(event)=>this.onChangeDOBHandler(event)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </FormGroup>
                      </div>
                    </Row>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Loading...' : 'Add'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AddEvent;
