import Card from 'components/Card/Card';
import PageLoader from 'components/Loader/PageLoader';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Col, Container, Row, Tab, Table, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as fb from "../../firebase";
//Datatable Modules
import $ from 'jquery';
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import { convertFirebseSecondsToDate } from 'components/Helper';
import api from 'api';
import { convertMilisecondsToDate } from 'components/Helper';

const orderColumns = ["Created", "Type", "Actions"];
const columns = ["Created", "Type", "Email", "Price", "Actions"];

const StripeShopifyList = () => {
  const [orders, setOrders] = useState([])
  const [all, setAll] = useState([])
  const [stripe, setStripe] = useState([])
  const [shopify, setShopify] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = async () => {
    try {
      setIsLoading(true)

      let {data: ordersArr} = await api.get('getOrders')
      ordersArr = ordersArr
        ?.filter(i => i?.status === 'request_refund')
        ?.map(i => ({ 
          ...i,
          created_seconds: new Date(i.created).getTime(),
          created: convertMilisecondsToDate(new Date(i.created).getTime()),
          type: i.type,
        }))

      setOrders(ordersArr.sort((a, b) => a.created_seconds < b.created_seconds ? 1 : -1))

      const stripeArr = []
      const shopifyArr = []
      await fb.stripeCollection.orderBy('created', 'desc').limit(1000).get().then(docs => {
        if (!docs.empty) {
          docs.forEach(doc => {
            const dataObj = doc.data()
            stripeArr.push({
              id: doc.id,
              created_seconds: dataObj.created,
              created: convertFirebseSecondsToDate(dataObj.created),
              type: 'Stripe',
              email: dataObj.data.object.customer_email,
              price: dataObj.data.object.total / 100
            })
          })
        }
      })
      await fb.shopifyCollection.orderBy('created_at', 'desc').limit(1000).get().then(docs => {
        if (!docs.empty) {
          docs.forEach(doc => {
            const dataObj = doc.data()
            shopifyArr.push({
              id: doc.id,
              created_seconds: new Date(dataObj.created_at).getTime() / 1000,
              created: convertMilisecondsToDate(new Date(dataObj.created_at).getTime()),
              type: 'Shopify',
              email: dataObj.customer.email,
              price: dataObj.total_price
            })
          })
        }
      })
      setStripe(stripeArr)
      setShopify(shopifyArr)
      setAll([...stripeArr, ...shopifyArr].sort((a, b) => a.created_seconds < b.created_seconds ? 1 : -1))

      $('#orders').DataTable({
        order: [[0, 'desc']],
      });
      $('#all').DataTable();
      $('#stripe').DataTable();
      $('#shopify').DataTable();
    } catch (err) {
      console.log('err', err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Stripe/Shopify Payments"
              category=""
              ctTableFullWidth
              ctTableResponsive
              loader={isLoading && <PageLoader />}
              content={
                <>
                  <div style={{marginBottom: '40px'}}>
                    <Table striped hover id={'orders'}>
                      <thead>
                        <tr>
                          {orderColumns.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((val, key) => {
                          return (
                            <tr key={key} id={key}>
                              <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                              <td>{val.type?.toUpperCase()}</td>
                              <td>
                                <Link to={`/admin/stripe-shopify/order/${val.id}`}><i className="fa fa-eye" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Tabs defaultActiveKey="all" id="uncontrolled-tab-example">
                    {Object.entries({
                      all: { title: 'All', data: all.reverse() },
                      stripe: { title: 'Stripe', data: stripe.reverse() },
                      shopify: { title: 'Shopify', data: shopify.reverse() },
                    }).map((tabVal, tabKey) => {
                      return <Tab eventKey={tabVal[0]} title={tabVal[1].title} key={tabKey}>
                        <Table striped hover id={tabVal[0]}>
                          <thead>
                            <tr>
                              {columns.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {tabVal[1].data.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td><span style={{ display: "none" }}>{val.created_seconds}</span>{val.created}</td>
                                  <td>{val.type}</td>
                                  <td>{val.email || '-'}</td>
                                  <td>{val.price || '-'}</td>
                                  <td>
                                    <Link to={`/admin/stripe-shopify/view/${val.id}?type=${val.type}`}><i className="fa fa-eye" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                    })}
                  </Tabs>
                </>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default StripeShopifyList