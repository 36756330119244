import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Alert
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import { 
  convertFirebseSecondsToDate, convertMilisecondsToDate, convertUnixTimestampToDate
} from "../../components/Helper/index";
import { Link } from "react-router-dom";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import UserDailiesView from "components/Model/UserDailiesView";
import "./User.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import api from "api.js";

class Performance extends Component {
    constructor(props){
        super(props);
        this.state = {
            id:"",
            name:"",
            email:"",
            status:"",
            dateOfStartUsingApp:"",
            initialPaymentDate:"",
            selectedDate:"",
            performance:"",
            currentTreatmentID: "",
            errors:{},
            error:"",
            isLoading:false,
            isDailiesGenerate:false,
            isGenerateCustomerDailies:false,
            successMessage:"",
            treatmentVersion: 0
        }
    }

    onChangeDOBHandler = (date) => {
        this.setState({dob:date});
    };

    showGenerateCustomerDailies = () => {
      this.setState({isGenerateCustomerDailies:!this.state.isGenerateCustomerDailies});
    };


    componentDidMount = async () => {
        this.setState({isLoading:true});
        const  url = window.location.href;
        const segment = url.substring(url.lastIndexOf('/') + 1);
        await this.fetchData(segment);
        await this.onChangeCalender(1); //on load
        await this.onChangeCalenderDailies(1); //on load
        await this.getPerformanceAvg(); //performance
        await this.getLastDeliveredPackage();
        this.setState({isLoading:false});
    }

    getLastDeliveredPackage = async() => {
        try {
            const result = await api.get(`s/package/last/${this.state.id}`)
            
            if(result && result.status==200){
                if(result.data.last && result.data.last.delivered){
                    let lastpackage = convertFirebseSecondsToDate(result.data.last.delivered._seconds)
                    this.setState({lastPackageDelivered:lastpackage});
                }
            }
        }catch (e) {
            this.setState({errorMessage:e.message})
        }
    }

    getPerformanceAvg = async () => {
    
        let appStartDate = new Date(this.state.dateOfStartUsingApp*1000);
    
        var fullDate = appStartDate;
        var twoDigitMonth = (parseInt(fullDate.getMonth()) + 1) + "";
        if (twoDigitMonth.length == 1)
            twoDigitMonth = "0" + twoDigitMonth;
        var twoDigitDate = fullDate.getDate() + "";
        if (twoDigitDate.length == 1)
            twoDigitDate = "0" + twoDigitDate;
        var startDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();
    
        var dateClicked = new Date();
        var twoDigitMonthClicked = (parseInt(dateClicked.getMonth()) + 1) + "";
        if (twoDigitMonthClicked.length == 1)
        twoDigitMonthClicked = "0" + twoDigitMonthClicked;
        var twoDigitDateClicked = dateClicked.getDate() + "";
        if (twoDigitDateClicked.length == 1)
            twoDigitDateClicked = "0" + twoDigitDateClicked;
        var endDate = twoDigitMonthClicked + "/" + twoDigitDateClicked + "/" + dateClicked.getFullYear();
    
        var firstDate = new Date(startDate);
        var secondDate = new Date(endDate);
    
        // time difference
        var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());
    
        // days difference
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
        // shift 
        var shift = diffDays+1;
    
        console.log('shift', shift);
    
        try{
          let { data: dailes } = await api.get('getDailiesByUserId', { params: { userId: this.state.id } })
          dailes = dailes.filter(i => i.shift <= shift)
          
          if(dailes?.length > 0){
            let done = 0;
            let no = 0;
            for (let dataObj of dailes) {
              for (let prData of dataObj.pr) {
                if(prData.done===true){
                  done = done+1;
                }else if(prData.done===false){
                  no = no+1;
                }
              }
            }
            this.setState({
              performance: ((done*100)/(done+no)).toFixed(2) + '%'
            });
          }
        }catch(err){
          this.setState({error:err.message})
        }
      }

    fetchData = async(segment) => {
        this.setState({isLoading:true});
        await api.get('userByID', {params: {userId: segment}}).then(async res => {
            const dataObj = res.data
            if(dataObj){
                this.setState({ 
                    id:dataObj.id,
                    name:dataObj.name ? dataObj.name : this.state.name,
                    email:dataObj.email ? dataObj.email : this.state.email,
                    status:dataObj.status ? dataObj.status : this.state.status,
                    dateOfStartUsingApp:dataObj.dateOfStartUsingApp ? dataObj.dateOfStartUsingApp : this.state.dateOfStartUsingApp,
                    currentTreatmentID:dataObj.currentTreatmentID ? dataObj.currentTreatmentID : this.state.currentTreatmentID,
                    initialPaymentDate:dataObj.initialPaymentDate ? convertMilisecondsToDate(new Date(dataObj.initialPaymentDate).getTime()) : "",
                })
                let {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: segment } })
                treatmentsByUserId = treatmentsByUserId?.filter(i => i.id === dataObj.currentTreatmentID || i.id === this.state.currentTreatmentID)
                
                if(treatmentsByUserId?.length > 0) {
                  this.setState({treatmentVersion: treatmentsByUserId[0]?.version})
                }
            }
        }).catch(err=> {
            this.setState({error:err.message})
        });   
    }

    onChangeCalender = async (value) => {
        var selectedDate;
        //this is just to shown the selected date
        if(value===1) {
          let todaySeconds = new Date().getTime() / 1000;
          selectedDate = convertFirebseSecondsToDate(todaySeconds);
          value = new Date();
          console.log('selectedDate', selectedDate);
          this.setState({
            selectedDate:selectedDate,
          });
        }else{
          let todaySeconds = value.getTime() / 1000;
          selectedDate = convertUnixTimestampToDate(todaySeconds);
          this.setState({
            selectedDate:selectedDate,
          });
        }
        let appStartDate = new Date(this.state.dateOfStartUsingApp*1000);
        var fullDate = appStartDate;
        var twoDigitMonth = (parseInt(fullDate.getMonth()) + 1) + "";
        if (twoDigitMonth.length == 1)
            twoDigitMonth = "0" + twoDigitMonth;
        var twoDigitDate = fullDate.getDate() + "";
        if (twoDigitDate.length == 1)
            twoDigitDate = "0" + twoDigitDate;
        var startDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();

        var dateClicked = value;
        var twoDigitMonthClicked = (parseInt(dateClicked.getMonth()) + 1) + "";
        if (twoDigitMonthClicked.length == 1)
        twoDigitMonthClicked = "0" + twoDigitMonthClicked;
        var twoDigitDateClicked = dateClicked.getDate() + "";
        if (twoDigitDateClicked.length == 1)
            twoDigitDateClicked = "0" + twoDigitDateClicked;
        var endDate = twoDigitMonthClicked + "/" + twoDigitDateClicked + "/" + dateClicked.getFullYear();

        var firstDate = new Date(startDate);
        var secondDate = new Date(endDate);

        // time difference
        var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());

        // days difference
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        // shift 
        var shift = diffDays+1;
        console.log('shift', shift)
        try{
          let { data: dailes } = await api.get('getDailiesByUserId', { params: { userId: this.state.id } })
          dailes = dailes.filter(i => i.shift === shift)

          if(dailes?.length > 0){
              let a = dailes[0]?.pr.sort(function(a, b) {
                  var keyA = a.order,
                    keyB = b.order;
                  // Compare the 2 dates
                  if (keyA < keyB) return -1;
                  if (keyA > keyB) return 1;
                  return 0;
              });
              this.setState({
                selectedDate:selectedDate,
                isDailies: true,
                dailiesList:a,
                isLoading:false
              });
          }else{
            // this.setState({
            //   dailiesList:[],
            // });
            alert("No dailies found for the current or selected date");
          }
        }catch(err){
          this.setState({error:err.message})
        }

        //treatment data
        if(this.state.currentTreatmentID){
          try{
            const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: this.state.id } })
            const treatment = treatmentsByUserId?.find(i => i.id === this.state.currentTreatmentID)
            
            if(treatment){
              this.setState({
                title: treatment.title || "",
                products: treatment.products && Object.keys(treatment.products).sort((a, b) => {return parseInt(a.slice(1)) - parseInt(b.slice(1))}).join(),
                isLoading:false
              });
            }
          }catch(err){
            this.setState({error:err.message})
          }
        }
    }

    onChangeCalenderDailies = async (value) => {
        if(value.length > 1){
          if(this.state.dateOfStartUsingApp){
            let appStartDate = new Date(this.state.dateOfStartUsingApp*1000);
            var fullDate = appStartDate;
            var twoDigitMonth = (parseInt(fullDate.getMonth()) + 1) + "";
            if (twoDigitMonth.length == 1)
              twoDigitMonth = "0" + twoDigitMonth;
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length == 1)
              twoDigitDate = "0" + twoDigitDate;
            var startDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();  
    
            var dateClicked = value[0];
            var twoDigitMonthClicked = (parseInt(dateClicked.getMonth()) + 1) + "";
            if (twoDigitMonthClicked.length == 1)
            twoDigitMonthClicked = "0" + twoDigitMonthClicked;
            var twoDigitDateClicked = dateClicked.getDate() + "";
            if (twoDigitDateClicked.length == 1)
              twoDigitDateClicked = "0" + twoDigitDateClicked;
            var endDate = twoDigitMonthClicked + "/" + twoDigitDateClicked + "/" + dateClicked.getFullYear();
    
            var firstDate = new Date(startDate);
            var secondDate = new Date(endDate);
    
            console.log('fs', firstDate, secondDate);
    
            // time difference
            var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());
    
            // days difference
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
            // shift 
            var shift = diffDays;
            console.log('from', shift)
    
          }else{
    
            let appStartDate = new Date();
            var fullDate = appStartDate;
            var twoDigitMonth = (parseInt(fullDate.getMonth()) + 1) + "";
            if (twoDigitMonth.length == 1)
              twoDigitMonth = "0" + twoDigitMonth;
            var twoDigitDate = fullDate.getDate() + "";
            if (twoDigitDate.length == 1)
              twoDigitDate = "0" + twoDigitDate;
            var startDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();  
    
            var dateClicked = value[0];
            var twoDigitMonthClicked = (parseInt(dateClicked.getMonth()) + 1) + "";
            if (twoDigitMonthClicked.length == 1)
            twoDigitMonthClicked = "0" + twoDigitMonthClicked;
            var twoDigitDateClicked = dateClicked.getDate() + "";
            if (twoDigitDateClicked.length == 1)
              twoDigitDateClicked = "0" + twoDigitDateClicked;
            var endDate = twoDigitMonthClicked + "/" + twoDigitDateClicked + "/" + dateClicked.getFullYear();
    
            var firstDate = new Date(startDate);
            var secondDate = new Date(endDate);
    
            console.log('fs', firstDate, secondDate);
    
            // time difference
            var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());
    
            // days difference
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
            // shift 
            // var shift = diffDays;
            var shift = 0;
            console.log('from', shift)
            this.setState({
              dateOfStartUsingAppIfNot:secondDate.getTime()
            });
    
          }
          
    
          //to
          let appStartDateTo = value[0];
          var fullDateTo = appStartDateTo;
          var twoDigitMonthTo = (parseInt(fullDateTo.getMonth()) + 1) + "";
          if (twoDigitMonthTo.length == 1)
            twoDigitMonthTo = "0" + twoDigitMonthTo;
          var twoDigitDate = fullDateTo.getDate() + "";
          if (twoDigitDate.length == 1)
            twoDigitDate = "0" + twoDigitDate;
          var startDateTo = twoDigitMonthTo + "/" + twoDigitDate + "/" + fullDateTo.getFullYear();
    
          var dateClickedSecond = value[1];
          var twoDigitMonthToClicked = (parseInt(dateClickedSecond.getMonth()) + 1) + "";
          if (twoDigitMonthToClicked.length == 1)
          twoDigitMonthToClicked = "0" + twoDigitMonthToClicked;
          var twoDigitDateClickedSecond = dateClickedSecond.getDate() + "";
          if (twoDigitDateClickedSecond.length == 1)
            twoDigitDateClickedSecond = "0" + twoDigitDateClickedSecond;
          var endDateTo = twoDigitMonthToClicked + "/" + twoDigitDateClickedSecond + "/" + dateClickedSecond.getFullYear();
    
          var firstDateTo = new Date(startDateTo);
          var secondDateTo = new Date(endDateTo);
    
          // time difference
          var timeDiffTo = Math.abs(secondDateTo.getTime() - firstDateTo.getTime());
    
          // days difference
          var diffDaysTo = Math.ceil(timeDiffTo / (1000 * 3600 * 24));
    
          // shift 
          var toDate = diffDaysTo+1;
          console.log('to', toDate)
    
          this.setState({
            shift:shift,
            toDate:toDate,
          });
        }
    }

    generateDailies = async() => {
        if (window.confirm("Do you really want to generate dailies for this customer?")) {
          try {
            const result = await api.get(`s/create_daily_schedule/${id}/${shift}/${toDate}?version=v${this.state.treatmentVersion}`)
            console.log('result', result)
            let {shift, toDate, id} = this.state;
    
            //update start date of using app
            if(this.state.dateOfStartUsingApp===""){
              api.pos('updateUser', {
                id: this.state.id,
                dateOfStartUsingApp:this.state.dateOfStartUsingAppIfNot /  1000
              });
            }
            this.setState({successMessage:"Generated dailies successfully!"})
            setTimeout(() => {
              this.setState({
                successMessage: ''
              });
            }, 3000)
          }catch(err) {
            this.setState({error:err.message})
          }
        }
      };

    onChangeCalenderClose = (status) => {
        this.setState({
            isDailies: status
        });
    }

    render() {
        // console.log('render', this.state)
        return (
        <div className="content">
            <Container fluid>
                <Row>
                    <Col md={12}>
                        { this.state.error &&
                            <Alert bsStyle="danger">
                            <span>{this.state.error}</span>
                            </Alert>
                        }
                        <Card
                            title={`${this.state.name} Performance Calender`}
                            category=""
                            button={
                                <div>
                                    <p>Email: {this.state.email}</p>
                                    <Link to={`/admin/users/view/${this.state.id}`} className="btn btn-success libi-admin-btn">Back</Link>
                                </div>
                            }
                            ctTableFullWidth
                            ctTableResponsive
                            loader={ this.state.isLoading && <PageLoader />}
                            content={
                                <div>
                                    <Row>
                                        <div className="col-md-6">
                                            {this.state.dateOfStartUsingApp &&
                                              <Calendar
                                                minDate={new Date(this.state.dateOfStartUsingApp*1000)}
                                                onChange={this.onChangeCalender}
                                                value={new Date()}
                                              />
                                            }
                                        </div>
                                        <div className="col-md-6">
                                            <div className="">
                                                <h5>
                                                    <span className="category">Customer since : </span> {this.state.initialPaymentDate}
                                                </h5>
                                            </div>
                                            <div className="">
                                                <h5>
                                                    <span className="category">Treatment date since : </span> {  this.state.dateOfStartUsingApp ? convertUnixTimestampToDate(this.state.dateOfStartUsingApp) : <span style={{color:"red"}}>Don't have date of treatment plan start</span>}
                                                </h5>
                                            </div>
                                            <div className="">
                                                <h5>
                                                    <span className="category">Last package received : </span> {this.state.lastPackageDelivered && this.state.lastPackageDelivered}
                                                </h5>
                                            </div>
                                            {/*<div className="">
                                                <h5>
                                                    <span className="category">Last stared activity day</span> ok
                                                </h5>
                                            </div> */}
                                            <div className="">
                                                <h5>
                                                    <span className="category">Performance rate : </span> {this.state.performance && this.state.performance}
                                                </h5>
                                            </div>
                                            <div className="">
                                                <h5>
                                                    <span className="category">Assessment : </span> {this.state.title && this.state.title}
                                                </h5>
                                            </div>
                                            <div className="">
                                                <h5>
                                                    <span className="category">Products : </span> {this.state.products && this.state.products}
                                                </h5>
                                            </div>
                                            <div className="">
                                                <h5>
                                                    <span className="category">For the selected : </span>{this.state.selectedDate && this.state.selectedDate}
                                                </h5>
                                            </div>
                                            <span className="border">
                                            {this.state.isDailies &&  
                                                <UserDailiesView
                                                    show={this.state.isDailies}
                                                    data={{dailies:this.state.dailiesList}}
                                                    onHide={() => this.onChangeCalenderClose(false)}
                                                />                                   
                                            }</span>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-md-6">
                                            <Link to={`#`} onClick={() => {this.showGenerateCustomerDailies()}}><h4>Generate customer dailies</h4></Link>
                                            {this.state.successMessage !== null && <div className = "py-4 bg-red-600 w-full text-success mb-3">{this.state.successMessage}</div>}
                                            { this.state.status==="active" && this.state.isGenerateCustomerDailies === true &&  <React.Fragment>
                                              <div className="">
                                                <h5>
                                                  <span className="category">Shift : </span>{this.state.shift}
                                                </h5>
                                              </div>
                                              <div className="">
                                                <h5>
                                                  <span className="category">Selected Days : </span>{this.state.toDate}
                                                </h5>
                                              </div>
                                                <Calendar
                                                  minDate={new Date(this.state.dateOfStartUsingApp*1000)}
                                                  onChange={this.onChangeCalenderDailies}
                                                  selectRange={true}
                                                />
                                                <br/><Link to={`#`} onClick={() => { this.generateDailies() }} className="btn btn-success users-back-btn">Generate</Link>
                                                </React.Fragment>
                                            }
                                        </div>
                                    </Row>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Container>
        </div>
        );
    } 
}

export default Performance;
