import React, { useEffect, useRef, useState } from "react";
import logo from '../../assets/img/logo2.png'
import jsPDF from "jspdf";
import image from "assets/img/loader.gif";
import { fontRegular } from "assets/fonts/CormorantGaramond-Regular-normal";
import { fontBold } from "assets/fonts/CormorantGaramond-Bold-bold";
import './fonts.css'
import { Button } from "react-bootstrap";
import api from "api";

const pageHeight = 446
const pageWidth = 631.4175
const halfPageWith = (pageWidth - 150) / 2

const s = {
  page1: {
    height: pageHeight,
    width: pageWidth,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 75,
    paddingRight: 75,
    pageBreakAfter: "always",
    background: "#fff",
  },
  page2: {
    height: pageHeight,
    width: pageWidth,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 50,
    paddingRight: 50,
    background: "#fff",
  },
  flex: {
    height: pageHeight - 60,
    display: 'flex',
    textAlign: 'center',
    gap: 50
  },
  page1Block1: {
    width: halfPageWith,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  page1Block2: {
    flexDirection: 'column',
    position: 'relative'
  },
  logo: {
    width: 70
  },
  logo2: {
    position: 'absolute',
    top: 50,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  title: {
    fontFamily: "CormorantGaramond-Bold",
    fontVariantNumeric: "normal",
    fontWeight: 700,
    fontSize: 11,
    color: "#943634",
    margin: 0
  },
  title2: {
    fontFamily: "CormorantGaramond-Bold",
    fontVariantNumeric: "normal",
    fontWeight: 700,
    fontSize: 9,
    lineHeight: '10px',
    color: "#943634",
    margin: 0
  },
  text: {
    fontFamily: "CormorantGaramond-Regular",
    fontVariantNumeric: "normal",
    fontWeight: 400,
    fontSize: 7,
    lineHeight: '8px',
    color: "#000000",
    margin: 0
  },
  page2Block: {
    width: halfPageWith,
  },
  page2Title: {
    fontVariantNumeric: "normal",
    fontSize: 9,
    borderBottom: '1px solid #943634',
    paddingBottom: 7,
    marginBottom: 10
  },
  item: {
    marginBottom: 10
  }
}

const PackagePdf = () => {
  const packageId = window.location.pathname.split('/').pop()

  const pdfRef = useRef(null)

  const [notFocused, setNotFocused] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [userData, setUserData] = useState({})
  const [morningProducts, setMorningProducts] = useState([])
  const [eveningProducts, setEveningProducts] = useState([])
  const [packageRef, setPackageRef] = useState('')

  const handleGeneratePdf = () => {
    const doc = new jsPDF({
      format: "A4",
      unit: "px",
      orientation: "landscape"
    });

    doc.addFileToVFS('CormorantGaramond-Regular-normal.ttf', fontRegular);
    doc.addFont('CormorantGaramond-Regular-normal.ttf', 'CormorantGaramond-Regular', 'normal');
    doc.setFont('CormorantGaramond-Regular')

    doc.addFileToVFS('CormorantGaramond-Bold-bold.ttf', fontBold);
    doc.addFont('CormorantGaramond-Bold-bold.ttf', 'CormorantGaramond-Bold', 'bold');
    doc.setFont('CormorantGaramond-Bold')

    doc.html(pdfRef.current, {
      async callback(doc) {
        doc.save(`package_document/${packageRef}-Treatment_Plan_${userData?.language}_${userData?.lastName}_${userData?.firstName}`)
      }
    });
  };

  const getData = async () => {
    try {
      await api.get(`packageByID?packageId=${packageId}`).then(async res => {
        setUserData(res.data.userData)

        const p = res.data?.package
        const texts = res.data?.texts
        let mProds = []
        let aProds = []
        let eProds = []
        let index = 0
        let bc_number = ''

        setPackageRef(p.ref.replace('LD', ''))

        if (p && p?.products) {
          const products = p.products

          for (const prod of products) {
            let prod_data = res.data?.productsDict?.find(i => i.ref === prod.value)

            if (['M', 'M&E'].includes(prod.time)) {
              mProds.push({ ...prod_data, ...prod })
            }
            if (!['M'].includes(prod.time)) {
              eProds.push({ ...prod_data, ...prod })
            }
            if (['p21', 'p75', 'p99'].includes(prod_data.ref)) {
              aProds.push({ ...prod_data, ...prod })
            }
          }

          const productItem = (prod, arr, isDescription, descriptionProds) => {
            let res = {}

            if (isDescription) {
              if (texts.hasOwnProperty('p_21_75_99_alergy_test_instruction')) {
                const text = texts['p_21_75_99_alergy_test_instruction'].replace('[[products]]', descriptionProds)
                const desc = text.split('<br>')
                arr.push({ text: desc[0] })
                arr.push({ text: desc[1] })
              }
            } else {
              let prod_number = parseInt(prod.value.replace("p", ""))
              let is_60 = prod_number < 70 && prod_number > 59 && prod_number !== 62;
              let is_70 = prod_number < 80 && prod_number > 73;

              if (is_60) {
                let cat_text = `${index + 1}. ${texts['p_75_instruction_top_title']}`
                index--
                arr.push({ title: cat_text })
                bc_number = 'N°' + prod_number
              }

              let prod_title = prod.title.trim()

              if (is_60) {
                const lastIndexOfSpace = prod_title.lastIndexOf(' ');
                if (lastIndexOfSpace !== -1) {
                  prod_title = prod_title.substring(0, lastIndexOfSpace)
                }
              }

              let title_text = `${is_60 || is_70 ? '-' : index + 1 + '.'} ${prod_title}`
              res.title = title_text

              let instruction = ''
              if (texts.hasOwnProperty('p_' + prod_number + '_instruction_base')) {
                let tempText = texts['p_' + prod_number + '_instruction_base']

                if (prod?.area) {
                  instruction += tempText.replace('[[area]]', prod.area.map((item) => item.val).join(', '))
                } else {
                  instruction = tempText;
                }

                if (prod_number === 795 && products.filter((item) => item.value === 'p48').length) {
                  instruction = texts['p_795_and_48_instruction_base'].replace('[[area]]', prod.area.map((item) => item.val).join(', '))
                }
              }

              let timeOrRule = prod?.frequency || prod?.rule
              let rule_key = 'p_' + prod_number + '_instruction_' + timeOrRule.toLowerCase().replace(/[ ]/g, '_').replace(/[()w/]/g, '')

              if (texts.hasOwnProperty(rule_key)) {
                instruction += ' ' + texts[rule_key]
              }
              instruction = instruction.replace('[[BC]]', bc_number)

              res.text = instruction
              arr.push(res)
            }
          }

          mProds = mProds.sort((a, b) => parseInt(a.order) - parseInt(b.order))

          let newMProds = []
          for (let i in mProds) {
            productItem(mProds[i], newMProds)
            index++
          }

          const newProds = aProds.map(i => i.ref.replace('p', 'NВ°')).join(';')
          productItem({}, newMProds, true, `(${newProds})`)

          eProds = eProds.sort((a, b) => parseInt(a.order) - parseInt(b.order))

          index = 0

          let newEProds = []
          for (let i in eProds) {
            productItem(eProds[i], newEProds)
            index++
          }

          setMorningProducts(newMProds)
          setEveningProducts(newEProds)
        }
      })
    } catch (err) {
      console.log('err', err)
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-3 mb-4">
        <img style={{ width: "50px" }} src={image} alt="Loader" />
      </div>
    )
  }

  if (error) {
    return <div className="mt-3 mb-4 text-center text-danger">{error}</div>
  }

  return (
    <div style={{ padding: '30px 0 80px', overflow: 'hidden' }}>
      <Button
        className="btn btn-success users-back-btn libi-admin-btn mx-0"
        onClick={handleGeneratePdf}
      >
        Generate PDF
      </Button>

      <br />

      <div style={{
        transform: 'scale(1.4) translate(20%, 15%)',
        height: pageHeight * 2 * 1.4,
        position: 'relative'
      }}>
        {notFocused &&
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '2px',
            background: 'rgb(247, 247, 248)',
            left: 0,
            top: pageHeight - 1,
          }} />
        }

        <div ref={pdfRef}>
          <div
            style={s.page1}
            contentEditable
            onFocus={() => setNotFocused(false)}
            onBlur={() => setNotFocused(true)}
          >
            <div style={s.flex}>
              <div style={s.page1Block1}>
                <img src={logo} style={s.logo} />
              </div>
              <div style={{ ...s.page1Block1, ...s.page1Block2 }}>
                <img src={logo} style={{ ...s.logo, ...s.logo2 }} />
                <p style={s.title}>Treatment plan for</p>
                <p style={{ ...s.title, marginTop: '5px' }}>{userData?.gender === 'M' ? 'Sir' : 'Lady'} {userData?.lastName} {userData?.firstName}</p>
              </div>
            </div>
          </div>

          <div
            style={s.page2}
            contentEditable
            onFocus={() => setNotFocused(false)}
            onBlur={() => setNotFocused(true)}
          >
            <div style={{ ...s.flex, gap: 100 }}>
              <div style={s.page2Block}>
                <p style={{ ...s.title, ...s.page2Title }}>MORNING</p>

                {!!morningProducts.length &&
                  morningProducts.map(i => (
                    <div key={i?.value} style={s.item}>
                      {i?.title && <p style={s.title2}>{i.title}</p>}
                      {i?.text && <p style={s.text}>{i.text}</p>}
                    </div>
                  ))
                }
              </div>
              <div style={s.page2Block}>
                <p style={{ ...s.title, ...s.page2Title }}>EVENING</p>

                {!!eveningProducts.length &&
                  eveningProducts.map(i => (
                    <div key={i?.value} style={s.item}>
                      {i?.title && <p style={s.title2}>{i.title}</p>}
                      {i?.text && <p style={s.text}>{i.text}</p>}
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagePdf;
