import React, { Component } from "react";
import SelectSearch from 'react-select-search';
import { Link } from "react-router-dom";

import { staticWebImages } from "../../components/Utils/images";
import { countries } from "../../components/Utils/helper";
import * as fb from "../../firebase"; 


class DepositVip extends Component {
  constructor(props){
    super(props);
    this.state = {
      country:"",
      errors:{},
      disabled : false
    }
  }

  componentDidMount = () => {
    //get current user
    const userId = localStorage.getItem('userId');
      if(userId){
        this.setState({
          userId:userId
        });
      }
    }

  onChangeHandler = async (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'country'){
      this.setState({country:value});
    }
  };

  onCountrySelect = async (country) => {
    console.log('country', country)
    this.setState({country:country}); 
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    this.props.history.push('/deposit-confirm')
  }

  logout = () => {
    fb.auth.signOut().then(() => {
      localStorage.removeItem('userId');
      localStorage.removeItem('userLogin');
      this.props.history.push('/')
    }).catch((error) => {
      alert(error)
    });
  }

  render() {
    console.log('render', this.state)
    return (
      <div className=" depositVip">
        <section className="Sign_Home ">
          <span className="custm-angle d-none"><i className="fa fa-angle-left" aria-hidden="true" />
          </span>
          <div className="container-fluid col-md-10 mx-auto">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-6 col-12 static-on-mobile">
                <div className="mainImgBox depositImg no-trans-mobile">
                  <img src={staticWebImages.depost} className="img-responsive center-block py-auto" />
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-6 align-V-center ">
                <div className="deposit_main_Sec no-trans-mobile">
                  <div className="main-logo">
                    <img src={staticWebImages.logo} className="img-responsive center-block web-logo-hide" />
                  </div>
                  <h2 className="main_txt" onClick={this.showNotification}>Thank you for filling out the <span className="MobileText">questionnaire!</span></h2>
                  <p className="sec_text">To finalize your VIP Invitation and early access to Libi &amp; Daughters, please pay your deposit now, which will count towards your subscription. Deposit Amount 200 EUR . </p>
                  
                  <div className="form-section">
                    <form onSubmit={this.handleSubmit}>
                      <div className="row pad-m-0">
                        <div className="col-md-8 col-lg-8 col-sm-12 padding-0 ">
                          <SelectSearch
                            // className="form-control text-Custom"
                            options={countries()}
                            search={true}
                            value={this.state.country}
                            name="country"
                            placeholder="Select Country"
                            onChange={(event)=>this.onCountrySelect(event)}
                          />
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-12 padding-0">
                          <button 
                            type="submit" 
                            className="form-control custom-btn-web ml-5" 
                            >Purchase Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <Link to={`#`} onClick={this.logout} className="web-custom-link d-none">Sign Out</Link>
                </div>
                <p className="copyright center-block text-center "> © 2020 Libi&amp;Daughters </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default DepositVip;
