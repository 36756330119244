import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {publicRoutes as routes} from "routes.js";

class Public extends Component {

  getRoutes = routes => {
    return routes.map((prop, key) => {
      console.log('public routes');
      if(this.props.location.pathname !== '/admins' &&  this.props.location.pathname !== '/admins/register' &&  this.props.location.pathname !== '/admins/forgot-password') {
        return (
          <Redirect to="/" key={key}/>
        );
      } else {
        return (
          <Route
            exact 
            path={prop.path}
            render={props => (
              <prop.component
                {...props}
                handleClick={this.handleNotificationClick}
              />
            )}
            key={key}
          />
        );
      }
    });
  };

  render() {
    return (
      <div className="wrapper">
        <Switch>{this.getRoutes(routes)}</Switch>
      </div>
    );
  }
}

export default Public;
