import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { Link } from "react-router-dom"; 
import * as fb from '../../firebase';
import {addAdminLog} from "../../components/Helper";

class EditStripeTestProduct extends Component {

  constructor(props){
    super(props);
    this.state = {
      id:"",
      key:"",
      priceId:"",
      title:"",
      data:[],
      dataList:[],
      disabled : false,
      error:"",
      errors:{}
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'priceId') {
      this.setState({
        priceId:value
      });
    }else if(name === 'title') {
      this.setState({
        title:value
      });
    }
  };

  componentDidMount = async () => {
    const  url = window.location.href;
    const key = url.substring(url.lastIndexOf('/') + 1);
    const metadata = await fb.metaCollection.doc('stripe_products_test').get();
    this.setState({
      key:key,
      priceId:metadata.data()[key].priceId,
      title:metadata.data()[key].title,
      isLoading:false
    });
  
  }

  handleValidation = () => {
    let {key, priceId, title} = this.state;
    let errors = {};
    let formIsValid = true;

    //Title
    if(!key){
      formIsValid = false;
      errors["key"] = "Key is required";
    }

    //priceId
    if(!priceId){
      formIsValid = false;
      errors["priceId"] = "priceId is required";
    }
    //priceId
    if(!title){
      formIsValid = false;
      errors["title"] = "Title is required";
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this._isMounted = true;
    this.setState({disabled: true});
    if(this.handleValidation(this.state.errors)){
      await fb.metaCollection.doc('stripe_products_test').update({
        [this.state.key]:{priceId:this.state.priceId, title:this.state.title}
      }).then(snapShot => {
        addAdminLog('meta_changed',
          `Changed the meta ${this.state.key} at the stripe_products_test`)
        this.props.history.push('/admin/stripe-products')
      }).catch(err=> {
        this.setState({
          error:`Something went wrong-${err}`,
          disabled: false
        });
      });
    }else{
      this.setState({
        disabled: false
      });
    }
  }

  

  render() {
    console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Edit Stripe Test Product"
                button={
                  <Link to={`/admin/stripe-products`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Key</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Key"
                            name="key"
                            value= {this.state.key}
                            onChange = {(event) => this.onChangeHandler(event)}
                            disabled={true}
                          />
                          <span className="help-block">{this.state.errors.key}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>PriceId</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="PriceId"
                            name="priceId"
                            value= {this.state.priceId}
                            onChange ={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.priceId}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Title</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Title"
                            name="title"
                            value= {this.state.title}
                            onChange ={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.title}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info" 
                      pullRight fill 
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Loading...' : 'Update'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditStripeTestProduct;
