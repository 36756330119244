import React, { Component } from "react";

class CustomNumberField extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:"",
            value: 0,
            max: 10,
            min: 0
        }
    }

    componentDidMount() {
        let newValue = Number(this.props.value) || 0;
        if(newValue > this.props.max){
            newValue = this.props.max
        }
        if(newValue < this.props.min){
            newValue = this.props.min
        }
        this.setState({
            value: newValue,
            min: this.props.min,
            max: this.props.max
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        let newValue = Number(nextProps.value) || 0;
        if(newValue > this.props.max){
            newValue = this.props.max
        }
        if(newValue < this.props.min){
            newValue = this.props.min
        }
        this.setState({
            value: newValue,
            min: nextProps.min,
            max: nextProps.max
        })
    }

    onChangeHandler = async (event) => {
        const {name, value} = event.currentTarget
        let newValue = this.state.value;
        if(name === 'plus'){
            newValue++
        }
        if(name === 'minus'){
            newValue--
        }
        if(newValue > this.state.max){
            newValue = this.state.max
        }
        if(newValue < this.state.min){
            newValue = this.state.min
        }
        await this.setState({value: newValue})
        this.props.onChange({currentTarget:{name:this.props.name,value:newValue}})
    }

    render() {
        return (
            <div className="CustomNumberField">
                <button
                    type="button"
                    name="minus"
                    disabled={this.state.value <= this.props.min || this.props.disabled}
                    onClick={this.onChangeHandler}>
                    <i className="fa fa-minus" aria-hidden="true" />
                </button>
                <span className={`input ${this.props.disabled ? 'disabled' : ''}`}>{this.state.value}</span>
                <button
                    type="button"
                    name="plus"
                    disabled={this.state.value >= this.props.max || this.props.disabled}
                    onClick={this.onChangeHandler}>
                    <i className="fa fa-plus" aria-hidden="true" />
                </button>
            </div>
        );
    }
}

export default CustomNumberField;
