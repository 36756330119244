import React, { useState } from "react";
import bgimgfrom from "../../src/assets/img/lady-background.jpg";
import { NavLink } from "react-router-dom";
import * as fb from "../../src/firebase";
import api from "api";
import axios from "axios";

const Login = ({ history }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  const signInWithEmailAndPasswordHandler = (event, email, password) => {
    event.preventDefault();

    fb.auth.signInWithEmailAndPassword(email, password).then(() => {
      fb.auth.currentUser.getIdToken(true).then(token => {
        api.get('userByID', { params: { userId: fb.auth.currentUser.uid } }).then(result => {
          if (result.data?.approved) {
            localStorage.setItem('checkUser', 'yes');
            history.push("/admin/dashboard");
            window.location.reload();
          } else {
            fb.auth.signOut().then(() => {
              //unsubscrip chat topics
              let currentToken = localStorage.getItem('firebaseClientToken');
              api.get(`s/unsubscribe/chat/${currentToken}`);
              localStorage.clear()
              history.push("/admins");
            });
            setError("Please wait for account approval!");
          }
        })
      })
    }).catch(error => {
      setError(error.message);
      console.error("Error signing in with password and email", error);
    });
  };

  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if (name === 'userEmail') {
      setEmail(value);
    } else if (name === 'userPassword') {
      setPassword(value);
    }
  };

  return (
    <div className="limiter">
      <div className="container-login100">
        <div className="wrap-login100">
          <form className="login100-form validate-form">
            <span className="login100-form-title p-b-43">
              Login to Continue
            </span>
            {error !== null && <div className="py-4 bg-red-600 w-full text-danger mb-3">{error}</div>}
            <div className="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
              <input
                className="input100"
                type="email"
                name="userEmail"
                placeholder="Email"
                value={email}
                onChange={(event) => onChangeHandler(event)}
              />
              <span className="focus-input100" />
            </div>
            <div className="wrap-input100 validate-input" data-validate="Password is required">
              <input
                type="password"
                name="userPassword"
                className="input100"
                placeholder="Password"
                value={password}
                id="userPassword"
                onChange={(event) => onChangeHandler(event)}
                autoComplete="off"
              />
              <span className="focus-input100" />
            </div>
            <div className="flex-sb-m w-full p-t-3 p-b-32">
              <div className="contact100-form-checkbox">
              </div>
              <div>
                <NavLink
                  to="/admins/forgot-password"
                  activeClassName="active"
                >
                  Forgot Password?
                </NavLink>
              </div>
            </div>
            <div className="container-login100-form-btn">
              <button className="login100-form-btn" onClick={(event) => { signInWithEmailAndPasswordHandler(event, email, password) }}>
                Login
              </button>
            </div>
            <div className="text-center p-t-46 p-b-20">
              <span className="txt2">
                <NavLink
                  to="/admins/register"
                  activeClassName="active"
                >
                  Create an Account
                </NavLink>
              </span>
            </div>
          </form>
          <div className="login100-more" style={{ backgroundImage: `url(${bgimgfrom})` }}>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
