import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert,
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import {
  addAdminLog,
} from "components/Helper/index";
import { Link } from "react-router-dom";
import PageLoader from "../../components/Loader/PageLoader";
import {Multiselect} from "multiselect-react-dropdown";
import {areaOptions} from "./AddRule";

class EditRule extends Component {
  constructor(props){
    super(props);
    this.state = {
      isLoading: false,
      refs: [],
      errors: {},
      error: "",
      disabled:false,
      id:"",
      product:"",
      prescription:"",
      time:"",
      priority:"",
      frequency:"",
      area:[]
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
  }

  fetchData = async () => {
    try {
      const url = window.location.href;
      const segment = url.substring(url.lastIndexOf('/') + 1);
      this.setState({isLoading: true});
      const refsData = await fb.productsCollection.get();
      const rulesData = await fb.rulesCollection.doc(segment).get();
      let refsArr = [];
      refsData.docs.forEach(doc => {
        const dataObj = doc.data();
        refsArr.push(dataObj.ref);
      })
      refsArr = refsArr
        .filter(ref => ref.indexOf('.') === -1)
        .map(str => Number(str.replace('p', '')))
        .sort((a, b) => a - b)
        .map(num => 'p' + num)

      const rulesObj = rulesData.data();
      this.setState({
        refs: refsArr,
        id: rulesObj.id,
        product: rulesObj.product,
        prescription: rulesObj.prescription,
        time: rulesObj.time,
        priority: rulesObj.priority,
        frequency: rulesObj.frequency,
        area: rulesObj.area,
        isLoading: false
      });
    } catch (err) {
      this.setState({error: err.message})
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'product') {
      this.setState({product:value});
    }else if(name === 'prescription'){
      this.setState({prescription:value});
    }else if(name === 'time'){
      this.setState({time:value});
    }else if(name === 'priority'){
      this.setState({priority:value});
    }else if(name === 'frequency') {
      this.setState({frequency:value});
    }
  };

  changeArea = (value) => {
    this.setState({area:value});
  }

  handleValidation = () => {
    let {
      product,
      prescription,
      time,
      priority,
      frequency,
      area
    } = this.state;
    let errors = {};
    let formIsValid = true;

    //Product
    if(!product){
      formIsValid = false;
      errors["product"] = "Product is required";
    }

    //Prescription
    if(!prescription){
      formIsValid = false;
      errors["prescription"] = "Prescription is required";
    }

    //Time
    if(!time){
      formIsValid = false;
      errors["time"] = "Time is required";
    }

    //Priority
    if(!priority){
      formIsValid = false;
      errors["priority"] = "Priority is required";
    }

    //Frequency
    if(!frequency){
      formIsValid = false;
      errors["frequency"] = "Frequency is required";
    }

    //Area
    if(!area){
      formIsValid = false;
      errors["area"] = "Area is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({disabled: true});
    if(this.handleValidation(this.state.errors)){
      await this.updateRule();
    }else{
      this.setState({
        disabled: false
      });
    }
  }

  updateRule = async () => {
    let docRef = fb.rulesCollection.doc(this.state.id).update({
      id:this.state.id,
      product:this.state.product,
      prescription:this.state.prescription,
      time:this.state.time,
      priority:this.state.priority,
      frequency:this.state.frequency,
      area:this.state.area
    }).then(spanShot => {
      addAdminLog(
        'rule_updated',
        `Update the rule ${docRef.id}`,
        docRef.id
      )
      this.props.history.push('/admin/rules')
    }).catch(err => {
      this.setState({
        error:`Something went wrong-${err}`,
        disabled: false
      });
    });
  }

  render() {
    let obj = {
      array: []
    };
    for (var l=0;l<100;l++){
      obj.array[l] = l+1;
    }
    let optionItems = obj.array.map((item) =>
      <option key={item}>{item}</option>
    );
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
              <Alert bsStyle="danger">
                <span>{this.state.error}</span>
              </Alert>
              }
              <Card
                title="Edit Rule"
                button={
                  <Link to={`/admin/rules`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                loader={this.state.isLoading && <PageLoader/>}
                content={
                  <form onSubmit={this.handleSubmit}>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Product</FormLabel>
                          <select
                            className= "form-control"
                            name="product"
                            value={this.state.product}
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Product</option>
                            {this.state.refs.map(ref => (
                              <option key={ref}>{ref}</option>
                            ))}
                          </select>
                          <span className="help-block">{this.state.errors.product}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Prescription</FormLabel>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Prescription"
                            name="prescription"
                            value={this.state.prescription}
                            onChange={(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.prescription}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Time</FormLabel>
                          <select
                            className= "form-control"
                            name="time"
                            value={this.state.time}
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Time</option>
                            <option>M</option>
                            <option>E</option>
                            <option>M&E</option>
                          </select>
                          <span className="help-block">{this.state.errors.time}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Priority</FormLabel>
                          <select
                            className= "form-control"
                            name="priority"
                            value={this.state.priority}
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Priority</option>
                            {optionItems}
                          </select>
                          <span className="help-block">{this.state.errors.priority}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Frequency</FormLabel>
                          <select
                            className= "form-control"
                            name="frequency"
                            value={this.state.frequency}
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Frequency</option>
                            <option>ED</option>
                            <option>E2D</option>
                            <option>E3D</option>
                            <option>E7D</option>
                            <option>E10D</option>
                            <option>E14D</option>
                          </select>
                          <span className="help-block">{this.state.errors.frequency}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Area</FormLabel>
                          <Multiselect
                            options={areaOptions}
                            selectedValues={this.state.area}
                            onSelect={this.changeArea}
                            onRemove={this.changeArea}
                            displayValue="val"
                            showCheckbox={true}
                            placeholder="Select Area"
                          />
                          <span className="help-block">{this.state.errors.area}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Button
                      className="btn-fill pull-right libi-admin-btn"
                      bsStyle="info"
                      type="submit"
                      disabled={this.state.disabled}
                    >
                      {this.state.disabled ? 'Uploading...' : 'Edit Rule'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditRule;
