import bell from "../../assets/img/bell.png";


export const staticDashImages = {
    bell:bell
}








