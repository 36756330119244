import React, { Component } from "react";

class WelcomeBoard extends Component {
  render() {
    return (
      <div className="welcome-msg">
        Welcome to the chat board
      </div>

    );
  }
}

export default WelcomeBoard;
