import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import PageLoader from "../../components/Loader/PageLoader.jsx";
import { answersListThArray,  userTreatmentThArray, userDiagnosesListThArray,paymentInformationListThArray, customerPackageListThArray} from "../../variables/Variables.jsx";
import * as fb from '../../firebase';
import {
  userAccountStatus,
  convertFirebseSecondsToDate,
  getUserStatusOption,
  checkPaymentStatusToShown,
  convertUnixTimestampToDate,
  packageStatusLabel,
  staticRuleArr,
  morningProducts,
  eveningProducts, addAdminLog, convertMilisecondsToDate
} from "../../components/Helper/index";
import { Link } from "react-router-dom";

//Datatable Modules
import $ from 'jquery'; 
import "../../../node_modules/datatables.net-dt/js/dataTables.dataTables"
import "../../../node_modules/datatables.net-dt/css/jquery.dataTables.min.css"
import UserTreatmentView from "components/Model/UserTreatmentView";
import "./User.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { convertFirebaseTimestampToDate } from "components/Helper/index.jsx";
import {stripeCollection} from "../../firebase";
import SelectSearch from "react-select-search";
import api from "api.js";
import moment from "moment";

class ViewUser extends Component {

  constructor(props){
    super(props);
    this.state = {
      id:"",
      userVersion:null,
      name:"",
      email:"",
      language:"EN",
      dateOfStartUsingApp:"",
      refCode:"",
      inviteCode:"",
      shippingAddress:{
        city:"",
        country:"",
        fullname:"",
        phone:"",
        line1:"",
        line2:"",
        state:"",
        postal_code:""
      },
      packageDelay: 0,
      dob:new Date(),
      gender:"",
      paymentStatus:"",
      paymentPlanStart:"",
      paymentPlanActive:"",
      answerId:"",
      test:"",
      checkPayment:-1,
      status:"",
      invited_by_code:"",
      successMessage:"",
      errors:{},
      error:"",
      isLoading:false,
      isOpen:false,
      isOpenDiagnoses:false,
      answersList:[],
      paymentInformationList:[],
      userTreatment:[],
      userDiagnoses:[],
      usersAns:[],
      questionnaire:[],
      question:[],
      productList:[],
      customerPackages:[],
      editPrivateInfo:false,
      editshippingAddressInfo:false,
      paymentPlanCode:"",
      performance:"",
      isFromInviteCode:false,
      isFromUserCode:false,
      isInvitedDetail:false,
      invite_name:"",
      invite_created:"",
      initialPaymentDate:"",
      lastOnlineTime:"",
      isDailiesGenerate:false,
      shift:"",
      dateOfStartUsingAppIfNot:"",
      generalData:{},
      r_id:"",
      r_name:"",
      r_created:"",
      currentTreatmentID:"",
      currentTreatmentProducts:[],
      morning:[],
      evening:[],
      me:[],
      selectedTreatmentID:"",
      skipQuestionnaire: false
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'name') {
      this.setState({name:value});
    }else if(name === 'firstName'){
      this.setState({firstName:value});
    }else if(name === 'lastName'){
      this.setState({lastName:value});
    }else if(name === 'email'){
      this.setState({email:value});
    }else if(name === 'fullname'){
      let currentState = this.state.shippingAddress;
      currentState.fullname = value;
      this.setState({currentState:currentState});
    }else if(name === 'city'){
      let currentState = this.state.shippingAddress;
      currentState.city = value;
      this.setState({currentState:currentState});
    }else if(name === 'phone'){
      let currentState = this.state.shippingAddress;
      currentState.phone = value;
      this.setState({currentState:currentState});
    }else if(name === 'country'){
      let currentState = this.state.shippingAddress;
      currentState.country = value;
      this.setState({currentState:currentState});
    }else if(name === 'postal_code'){
      let currentState = this.state.shippingAddress;
      currentState.postal_code = value;
      this.setState({currentState:currentState});
    }else if(name === 'state'){
      let currentState = this.state.shippingAddress;
      currentState.state = value;
      this.setState({currentState:currentState});
    }else if(name === 'line1'){
      let currentState = this.state.shippingAddress;
      currentState.line1 = value;
      this.setState({currentState:currentState});
    }else if(name === 'line2'){
      let currentState = this.state.shippingAddress;
      currentState.line2 = value;
      this.setState({currentState:currentState});
    }else if(name === 'gender'){
      this.setState({gender:value});
    }else if(name === 'status'){
      this.setState({status:value});
    }else if(name === 'test'){
      this.setState({test:value});
    }else if(name === 'checkPayment'){
      this.setState({checkPayment:value})
    }
  };

  onChangeDOBHandler = (date) => {
    this.setState({dob:date});
  };

  onChangePaymentPlanStartHandler = (date) => {
    var date = new Date(date);
    var ms = date.getTime()
    this.setState({paymentPlanStart:ms});
  };

  onChangePaymentPlanActiveHandler = (date) => {
    var date = new Date(date);
    var seconds = date.getTime() / 1000;
    this.setState({paymentPlanActive:seconds});
  };

  handleSubmitPrivateInfo = (event) => {
    event.preventDefault();
    this.setState(prevState => ({
        editPrivateInfo:!this.state.editPrivateInfo
      })
    )
    //this.setState(prevState => ({
      //score: prevState.score + 1
    //})
    let {editPrivateInfo, currentTreatmentID} = this.state;
    if(editPrivateInfo) {
      api.post('updateUser', {
        id: this.state.id,
        expertId: this.state.currentExpert
      }).then(() => {
        const newExpert = this.state.expertsList.find(i => i.value === this.state.currentExpert)
        this.setState({expert: {
          id: this.state.currentExpert,
          name: newExpert.name.slice(0, newExpert.name.indexOf('<'))
        }})
      })
    }
    if(this.state.status==="active" && editPrivateInfo ===true && !currentTreatmentID){
      if (window.confirm("Customer does't have the current treatment, Still you want to chnage the status?")) {
        //manage payment status
        var currentDateTime = new Date();
        var CurrentTimeInSeconds=currentDateTime.getTime() / 1000;
        let payment = ""
        if(this.state.paymentPlanActive == ""){
          payment = "No";
        }else{
          if(CurrentTimeInSeconds > this.state.paymentPlanActive){
            payment = "No";
          }else if(CurrentTimeInSeconds < this.state.paymentPlanActive){
            payment = "Yes";
          }
        }
        this.setState({paymentStatus:payment})
        let initialPaymentDate;
        if(this.state.status==="active"){
          initialPaymentDate = fb.timestamp.serverTimestamp();
        }else{
          initialPaymentDate = this.state.initialPaymentDate;
        }

        
        let userStatus = this.state.status
        if (this.state.inviteCode && this.state.invited_by_code && userStatus == 'waiting_list') {
          userStatus = 'questionnaire1'
        }
        if (this.state.skipQuestionnaire && userStatus== 'questionnaire1') {
          userStatus = 'waiting_diagnose'
        }

        api.post('updateUser', {
          id: this.state.id,
          name:this.state.name,
          firstName:this.state.firstName,
          lastName:this.state.lastName,
          email:this.state.email,
          shippingAddress:this.state.shippingAddress,
          dob: moment(this.state.dob).format('YYYY-MM-DD HH:mm:ss'),
          gender:this.state.gender,
          status: userStatus,
          test:this.state.test==="1" ? true : false,
          checkPayment:this.state.checkPayment,
          paymentPlanStart:this.state.paymentPlanStart,
          paymentPlanActive: userStatus === 'waiting_payment' ? '' : this.state.paymentPlanActive,
          initialPaymentDate:initialPaymentDate
        });
        // addAdminLog(
        //   'user_data_updated',
        //   `Changed the user data from user view for the user ${this.state.id}`,
        //   this.state.id,
        //   this.state.id,
        //   this.state.firstName + ' ' + this.state.lastName
        // )
        // const changeUserStatus = fb.functions.httpsCallable('changeUserStatus');
        // changeUserStatus({uid:this.state.id,status:this.state.status}).then(res => {}).catch(() => {})
        // if(this.state.status === 'waiting_payment'){
        //   api.post('updateUser', {
        //     id: this.state.id,
        //     paymentPlanActive: ""
        //   });
        // }
      }
    }else{
      //manage payment status
      var currentDateTime = new Date();
      var CurrentTimeInSeconds=currentDateTime.getTime() / 1000;
      let payment = ""
      if(this.state.paymentPlanActive == ""){
        payment = "No";
      }else{
        if(CurrentTimeInSeconds > this.state.paymentPlanActive){
          payment = "No";
        }else if(CurrentTimeInSeconds < this.state.paymentPlanActive){
          payment = "Yes";
        }
      }
      this.setState({paymentStatus:payment})
      let initialPaymentDate;
      if(this.state.status==="active"){
        initialPaymentDate = fb.timestamp.serverTimestamp();
      }else{
        initialPaymentDate = this.state.initialPaymentDate;
      }

      let userStatus = this.state.status
      if (this.state.inviteCode && this.state.invited_by_code && userStatus == 'waiting_list') {
        userStatus = 'questionnaire1'
      }
      if (this.state.skipQuestionnaire && userStatus== 'questionnaire1') {
        userStatus = 'waiting_diagnose'
      }

      api.post('updateUser', {
        id: this.state.id,
        name:this.state.name,
        firstName:this.state.firstName,
        lastName:this.state.lastName,
        email:this.state.email,
        shippingAddress:this.state.shippingAddress,
        dob: moment(this.state.dob).format('YYYY-MM-DD HH:mm:ss'),
        gender:this.state.gender,
        status: userStatus,
        test:this.state.test==="1" ? true : false,
        checkPayment:this.state.checkPayment,
        paymentPlanStart:this.state.paymentPlanStart,
        paymentPlanActive: userStatus === 'waiting_payment' ? '' : this.state.paymentPlanActive,
        initialPaymentDate:initialPaymentDate
      });
      // addAdminLog(
      //   'user_data_updated',
      //   `Changed the user data from user view for the user ${this.state.id}`,
      //   this.state.id,
      //   this.state.id,
      //   this.state.firstName + ' ' + this.state.lastName
      // )
      // const changeUserStatus = fb.functions.httpsCallable('changeUserStatus');
      // changeUserStatus({uid:this.state.id,status:this.state.status}).then(res => {}).catch(() => {})
      // if(this.state.status === 'waiting_payment'){
      //   api.post('updateUser', {
      //     id: this.state.id,
      //     paymentPlanActive: ""
      //   });
      // }
    }
  }

  handleSubmitDeliveryInfo = (event) => {
    event.preventDefault();
    try {
      this.setState({editDeliveryInfo:!this.state.editDeliveryInfo});
      let initialPaymentDate;
      if(this.state.status==="active"){
        initialPaymentDate = fb.timestamp.serverTimestamp();
      }else{
        initialPaymentDate = this.state.initialPaymentDate;
      }
      let userStatus = this.state.status
      if (this.state.inviteCode && this.state.invited_by_code && userStatus == 'waiting_list') {
        userStatus = 'questionnaire1'
      }
      if (this.state.skipQuestionnaire && userStatus== 'questionnaire1') {
        userStatus = 'waiting_diagnose'
      }

      api.post('updateUser', {
        id: this.state.id,
        name:this.state.name,
        email:this.state.email,
        shippingAddress:this.state.shippingAddress,
        dob: moment(this.state.dob).format('YYYY-MM-DD HH:mm:ss'),
        gender:this.state.gender,
        status: userStatus,
        test:this.state.test==="1" ? true : false,
        checkPayment: this.state.checkPayment,
        paymentPlanStart:this.state.paymentPlanStart,
        paymentPlanActive:this.state.paymentPlanActive,
        initialPaymentDate:initialPaymentDate
      });
      // addAdminLog(
      //   'user_data_updated',
      //   `Changed the user data from user view for the user ${this.state.id}`,
      //   this.state.id,
      //   this.state.id,
      //   this.state.firstName + ' ' + this.state.lastName
      // )
      // const changeUserStatus = fb.functions.httpsCallable('changeUserStatus');
      // changeUserStatus({uid:this.state.id,status:this.state.status}).then(res => {}).catch(() => {})
    }catch(err){
      console.log('err',err)
      this.setState({error:err.message})
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
  }


  componentDidMount = async () => {
    // console.log('okkk', new Date());
    this.setState({isLoading:true});
    const  url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    await this.fetchData(segment);
    await this.fetchAnswersData();
    // await this.fetchPaymentInformationData(); //commented as per discussion with client
    await this.getPerformanceAvg();
    await this.onChangeCalender(1);
    await this.fetchCustomerPackages();

    // $('#subscription-datatable').DataTable({
    //   "bStateSave": true,
    //   "order": [[ 0, "asc" ]]
    // });
    $('#answers-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });
    // $('#payment-datatable').DataTable({
    //   "bStateSave": true,
    //   "order": [[ 0, "asc" ]]
    // });
    $('#weekly-answers-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });
    await this.fetchUserTreatmentData(segment);
    $('#skincare-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });

    $('#package-datatable').DataTable({
      "order": [[ 0, "asc" ]],
      "bStateSave": true,
    });
    this.setState({isLoading:false});
  }

  renderRiskColor = (risk) => {
    let color= "green";
    if(risk==="yellow"){
      color= "yellow";
      return(
        <i className="fa fa-circle" aria-hidden="true"  style={{color:color}}></i>
      )
    }else if(risk==="red"){
      color= "red";
      return(
        <i className="fa fa-circle" aria-hidden="true"  style={{color:color}}></i>
      )
    }else {
      return(
        <i className="fa fa-circle" aria-hidden="true"  style={{color:color}}></i>
      )
    }
  }

  fetchCustomerPackages = async() => {
    const cp = await api.get(`s/package/list/${this.state.id}`)
    if(!!cp.data.list.length){
      let cpList = [];
      cp.data.list.forEach(pack => {
        if(!!Object.keys(pack).length) {
          cpList.push({
            id:pack.id,
            created:pack.created,
            risk:pack.risk || "",
            status:packageStatusLabel(pack.status),
            products: pack.products && pack.products.length
              ? pack.products.map(p=>p.value).sort((a, b) => {return parseInt(a.slice(1)) - parseInt(b.slice(1))}).join(', ')
              : []
          })
        }
      })
      this.setState({customerPackages:cpList});
    }
  }

  fetchStripeProduct = async (key) => {
    try {
      const stripe_products = await fb.metaCollection.doc('stripe_products').get();
      let obj =  {
        key:key,
        priceId:stripe_products.data()[key].priceId,
        title:stripe_products.data()[key].title,
      };
      return obj;
    }catch(err){
      console.log('err',err)
      this.setState({error:err.message})
    }
  }

  fetchData = async(segment) => {

    this.setState({isLoading:true});

    const expertsArr = []
    const {data: experts} = await api.get('expertsList')
    experts.forEach(i => {
      expertsArr.push({
        name: i.firstName + ' ' + i.lastName + " <" + i.email + ">",
        value: i.id
      })
    })
    this.setState({expertsList: expertsArr});

    //get user details
    await api.get('userByID', {params: {userId: segment}}).then(async res => {
      const dataObj = res.data
      let paymentPlanCode = {}
      if(dataObj){
        if(dataObj.paymentPlanCode){
          paymentPlanCode = await this.fetchStripeProduct(dataObj.paymentPlanCode);
        }

        let expert = {}
        if(dataObj.expertId) {
          await api.get('userByID', {params: {userId: dataObj.expertId}}).then(doc => {
            expert.id = doc.data.id
            expert.name = doc.data.firstName + ' ' + doc.data.lastName
          })
        }

        //manage payment status
        var currentDateTime = new Date();
        var CurrentTimeInSeconds=currentDateTime.getTime() / 1000;
        let payment = "";
        if(dataObj.paymentPlanActive == ""){
          payment = "No";
        }else{
          if(CurrentTimeInSeconds > dataObj.paymentPlanActive){
            payment = "No";
          }else if(CurrentTimeInSeconds < dataObj.paymentPlanActive){
            payment = "Yes";
          }
        }
        this.setState({ 
          id: dataObj.id,
          userVersion:dataObj.version,
          name:dataObj.name ? dataObj.name : this.state.name,
          firstName: dataObj.firstName || '',
          lastName: dataObj.lastName || '',
          email:dataObj.email ? dataObj.email : this.state.email,
          language:dataObj.language ? dataObj.language : this.state.language,
          shippingAddress:dataObj.shippingAddress ? dataObj.shippingAddress : this.state.shippingAddress,
          packageDelay: dataObj.hasOwnProperty('packageDelay') ? dataObj.packageDelay / 7 : 0,
          dob:dataObj.dob ? new Date(Date.parse(dataObj.dob)) : new Date(),
          gender:dataObj.gender ? dataObj.gender : this.state.gender,
          status:dataObj.status ? dataObj.status : this.state.status,
          paymentStatus:payment,
          dateOfStartUsingApp:dataObj.dateOfStartUsingApp ? dataObj.dateOfStartUsingApp : this.state.dateOfStartUsingApp,
          refCode:dataObj.ref ? dataObj.ref : this.state.refCode,
          invited_by_code:dataObj.invited_by_code ? dataObj.invited_by_code : this.state.invited_by_code,
          inviteCode:dataObj.invite_code ? dataObj.invite_code : this.state.inviteCode,
          paymentPlanStart:dataObj.paymentPlanStart ? dataObj.paymentPlanStart : this.state.paymentPlanStart,
          paymentPlanActive:dataObj.paymentPlanActive ? dataObj.paymentPlanActive : this.state.paymentPlanActive,
          paymentPlanCode:paymentPlanCode.title ? paymentPlanCode.title : "",
          initialPaymentDate:dataObj.initialPaymentDate || "",
          test:dataObj.test===true ? "1" : "0",
          checkPayment: typeof dataObj.checkPayment === 'undefined' ? -1 : dataObj.checkPayment,
          lastOnlineTime:dataObj.lastOnlineTime || "",
          currentTreatmentID:dataObj.currentTreatmentID|| "",
          appPlatform: dataObj.appPlatform,
          appVersion: dataObj.appVersion,
          skipQuestionnaire: dataObj.skipQuestionnaire,
          expert,
          currentExpert: expert.id ? expert.id : ''
        })

        // let domain;
        // if(process.env.REACT_APP_ENV === 'production') {
        //   domain = `https://us-central1-production-lnd.cloudfunctions.net`
        // }else{
        //   domain = `https://us-central1-l-d-control.cloudfunctions.net`
        // }
        // const paymentPlans = await axios.get(`${domain}/s/products/${res.id}`)
        // const nextPackageDate = await axios.get(`${domain}/s/next_package_date/DD MMM YYYY/${res.id}`)
        // const subscriptionList = await axios.get(`${domain}/s/subscriptions?uid=${res.id}&all=true`)
        // let fullSubsList = []
        // if(subscriptionList.hasOwnProperty('data') && subscriptionList.data.hasOwnProperty('HU')){
        //   fullSubsList = fullSubsList.concat(
        //       subscriptionList.data.HU.data ? subscriptionList.data.HU.data.map(obj=> ({ ...obj, country: 'HU' })) : [],
        //       subscriptionList.data.UK.data ? subscriptionList.data.UK.data.map(obj=> ({ ...obj, country: 'UK' })) : [],
        //       subscriptionList.data.HU_test.data ? subscriptionList.data.HU_test.data.map(obj=> ({ ...obj, country: 'HU_test' })) : [],
        //       subscriptionList.data.UK_test.data ? subscriptionList.data.UK_test.data.map(obj=> ({ ...obj, country: 'UK_test' })) : []
        //   ).filter(n => n)
        // }else{
        //   this.setState({error:subscriptionList.data});
        // }
        // console.log('fullSubsList',fullSubsList)
        // const stripe_get_subscription_by_uid = fb.functions.httpsCallable('stripe_get_subscription_by_uid');
        // const country = (dataObj.shippingAddress && dataObj.shippingAddress.country === 'GB') ? 'UK' : 'HU'
        // let subscr = {}
        // try{
        //   subscr = await stripe_get_subscription_by_uid({uid:res.id,CountryCode:country})
        //   console.log('subscr',subscr)
        // }catch (e){
        // }
        this.setState(prevState => ({
          generalData: {
            ...prevState.generalData,
            created: convertMilisecondsToDate(new Date(dataObj.created).getTime()),
            initialPaymentDate: dataObj.initialPaymentDate ? convertMilisecondsToDate(new Date(dataObj.initialPaymentDate).getTime()) : 'none',
            // paymentPlanPeriod: dataObj.paymentPlanPeriod ? dataObj.paymentPlanPeriod+' months' : 'none',
            // paymentPlanInterval: dataObj.paymentPlanInterval ? dataObj.paymentPlanInterval : 1,
            // subscriptionType: dataObj.packageType ? dataObj.packageType : 'Full',
            // nextShipmentDate: nextPackageDate.data,
            // subscriptionList: fullSubsList,
            // subscriptionState: subscr.hasOwnProperty('data') && subscr.data.status === 'active' && subscr.data.hasOwnProperty('current_period_end') && subscr.data.current_period_end >= Date.now() / 1000,
            // paymentMethod: subscr.hasOwnProperty('data') && subscr.data.hasOwnProperty('default_payment_method') && subscr.data.default_payment_method !== null,
            // nextPaymentDate: subscr.hasOwnProperty('data') && subscr.data.hasOwnProperty('current_period_end') ? convertFirebseSecondsToDate(subscr.data.current_period_end) : 'none'
          }
        }))
    
        //manage current treatment
        this.showCurrentTreatmentProduct(dataObj.currentTreatmentID);
        
      }
    }).catch(err=> {
      console.log('err',err.message)
      // this.setState({error:"Customer doesn't exist!"});
    });   
  }

  showCurrentTreatmentProduct = async (treatment_id) => {
    let { id } = this.state;
    this.setState({
      selectedTreatmentID:treatment_id,
    })
    //manage current treatment
    if(treatment_id){
      const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: id } })
      const treatment = treatmentsByUserId?.find(i => i.id === treatment_id)

      if(!treatment) return
      let cp = treatment.hasOwnProperty('products') ? treatment.products : {};
      let ct = [];
      Object.keys(cp).map(async (p, i) => {
        ct.push({
          title:cp[p].title,
          order:cp[p].order,
          realRule: cp[p].rule || cp[p].time || "",
          rule:await this.getRuleLabel(cp[p].rule) || "",
          time:await this.getRuleLabel(cp[p].time) || "",
        })
        if(Object.keys(cp).length-1===i){
          const sortedProduct = ct.sort(function (a, b) {
            return parseInt(a.order) - parseInt(b.order);
          });
          let morning = [];
          let evening = [];
          sortedProduct.forEach(p=> {
            if(morningProducts().includes(p.realRule)){
              morning.push({
                title:p.title,
                order:p.order,
                rule:p.rule || "",
                time:p.time || "",
              })
            }
            if(eveningProducts().includes(p.realRule)){
              evening.push({
                title:p.title,
                order:p.order,
                rule:p.rule || "",
                time:p.time || "",
              })
            }
          })
          this.setState({
            currentTreatmentProducts:sortedProduct,
            morning:morning,
            evening:evening,
          })
        }
      });
    }
  }

  getRuleLabel = async (rule) => {
    var item = staticRuleArr().find((item) => item.val === rule);
    const metadata = await fb.metaCollection.doc("instruction_txt").get();
    if(metadata.data() && item && item.key){
      return metadata.data()[item.key];
    }
  }

  fetchAnswersData = async() => {
    try{
      const answersArr = [];
      const {data: answers} = await api.get('getAnswersByUserId', {params: {userId: this.state.id}})

      for (let dataObj of answers) {
          answersArr.push({
            id:dataObj.id,
            user_id:dataObj.user_id,
            mark:dataObj.mark,
            event_id:dataObj.event_id,
            form_response:dataObj.form_response,
            treatment:dataObj.treatment,
            created_sec:new Date(dataObj.time).getTime() / 1000,
            created: convertMilisecondsToDate(new Date(dataObj.time).getTime())
          })
      }
      this.setState({
        answersList:answersArr,
        // isLoading:false
      });
    }catch(err){
      console.log('err',err)
      this.setState({error:err.message})
    }
  }

  fetchPaymentInformationData = async() => {
    try{
      const result = await api.get(`s/payments/${this.state.id}?all=true`)
      let paymentInformationList = [];
      let lastPaymentDate = 'none';
      console.log('paymentInformationList',result.data)
      if(result.hasOwnProperty('data')){
        let rd = result.data
        let changePaymentData = (country) => {
          return (doc) => ({
            date: convertFirebseSecondsToDate(doc.created),
            created: doc.created,
            charges: doc.charges,
            payment: doc.amount / 100 + " "+doc.currency.toUpperCase(),
            document: doc.charges.data[0] ? doc.charges.data[0].receipt_url : '',
            status: doc.status,
            country: country
          })
        }
        paymentInformationList = paymentInformationList.concat(
          rd.hasOwnProperty('HU') && rd.HU.data ? rd.HU.data.map(changePaymentData('HU')) : [],
          rd.hasOwnProperty('UK') && rd.UK.data ? rd.UK.data.map(changePaymentData('UK')) : [],
          rd.hasOwnProperty('HU_test') && rd.HU_test.data ? rd.HU_test.data.map(changePaymentData('HU_test')) : [],
          rd.hasOwnProperty('UK_test') && rd.UK_test.data ? rd.UK_test.data.map(changePaymentData('UK_test')) : []
        ).filter(n => n).sort((a,b)=>{return b.created - a.created})

        let lastPayment = paymentInformationList[0];
        if(lastPayment && lastPayment.hasOwnProperty('charges') &&
          lastPayment.charges.data.length > 0 &&
          lastPayment.charges.data[0].hasOwnProperty('created')){
          lastPaymentDate = convertFirebseSecondsToDate(lastPayment.charges.data[0].created)
        }
      }
      this.setState({
        paymentInformationList: paymentInformationList,
        // isLoading:false
      });
      this.setState(prevState => ({
        generalData: {
          ...prevState.generalData,
          // lastPaymentDateInvoice: paymentInformationList.length > 0 ? paymentInformationList[0].date : 'none',
          lastPaymentDate: lastPaymentDate,
        }
      }))
    }catch(err){
      console.log('err',err)
      this.setState({error:err.message})
    }
  }

  cancelSubscription = async (subs) => {
    if (window.confirm('Are you sure you want to delete this subscription?')) {
      const del_subscription = fb.functions.httpsCallable('del_subscription');
      // const country = (this.state.shippingAddress && this.state.shippingAddress.country === 'GB') ? 'UK' : 'HU'
      try {
        let result = await del_subscription({
          CountryCode: subs.country,
          SubID: subs.id
        })
        this.fetchData(this.state.id).then(() => {
          this.setState({isLoading:false})
        })
        console.log('cancelSubscription result', result)
      } catch (e) {
        console.log('cancelSubscription error', e.message)
        this.setState({error: "Cancel subscription error"});
      }
    }
    // let url;
    // if(process.env.REACT_APP_ENV === 'production') {
    //   url = `https://us-central1-production-lnd.cloudfunctions.net/s/cancel_subscription/${this.state.id}`
    // }else{
    //   url = `https://us-central1-l-d-control.cloudfunctions.net/s/cancel_subscription/${this.state.id}`
    // }
    // axios.get(url).then(result => {
    // }).catch(err=> {
    //   console.log('err',err)
    //   this.setState({error:err.message})
    // })
  }
  // createSubscription = async (subs) => {
  //   if (window.confirm('Are you sure you want to create a new subscription? The user will be charged.')) {
  //     const stripe_create_subscription = fb.functions.httpsCallable('stripe_create_subscription');
  //     const country = (this.state.shippingAddress && this.state.shippingAddress.country === 'GB') ? 'UK' : 'HU'
  //     try {
  //       let price_id = (subs.items && subs.items.data.length > 0 && subs.items.data[0].price) ? subs.items.data[0].price.id : ''
  //       let result = await stripe_create_subscription({
  //         UID: this.state.id,
  //         CountryCode: country,
  //         SubscriptionPrice: price_id,
  //         PaymentMethodId: subs.createSubscription})
  //       console.log('createSubscription result', result)
  //     } catch (e) {
  //       console.log('createSubscription error', e.message)
  //       this.setState({error:"Creation subscription error"});
  //     }
  //   }
  // }

  fetchUserTreatmentData = async(segment) => {
    // Get values and render on document
    await api.get('getTreatmentsByUserId', { params: { userId: segment } }).then(res => {
      const treatments = res.data

      if(treatments.length > 0 ){
        const userTreatmentArr = [];
        treatments.forEach(async (userTreatment) => {
          if(
            // userTreatment.answerId &&
            userTreatment.matched && 
            userTreatment.matched !== false
          ){
            let products = "";
            if(userTreatment.products && Object.keys(userTreatment.products).length > 0){
              products = Object.keys(userTreatment.products).sort((a, b) => {return parseInt(a.slice(1)) - parseInt(b.slice(1))}).join(', ');
            }
            let confirmed_time = userTreatment?.treatmentConfirmedBy 
              ? new Date(userTreatment.treatmentConfirmedBy?.time).getTime() 
              : new Date(userTreatment?.time).getTime()

            userTreatmentArr.push({
              id:userTreatment.id,
              // description:removeHTMLTags(userTreatment.description, 50),
              answer:userTreatment.answer,
              products:products,
              // title:userTreatment.title,
              ref: userTreatment.ref || 'empty ref',
              answerId:userTreatment.answerId,
              confirmed_by: userTreatment?.treatmentConfirmedBy ?
                userTreatment.treatmentConfirmedBy?.adminName || ''
                : userTreatment.version === 2 ? 'N/A' : '',
              confirmed: confirmed_time ? convertMilisecondsToDate(confirmed_time) : '',
              confirmed_seconds: confirmed_time,
              time: convertMilisecondsToDate(new Date(userTreatment?.time).getTime()),
              seconds: new Date(userTreatment?.time).getTime() / 1000
            })
            this.setState({
              userTreatment:userTreatmentArr
            });
          }
        });
      }
    }).catch(err=> {
      console.log('err',err)
      this.setState({error:err.message})
    });
  }
  
  handleShowDiagnosesDialog = (status) => {
    this.setState({ isOpenDiagnoses: status})
  };

  handleCheckPackageNeed = async () => {
    let self = this;
    let result = await api.get(`s/testpackagetime/again/${this.state.id}`).then(function (response) {
      if(typeof response.data === 'string'){
        self.setState({
          successMessage: response.data,
        });
        setTimeout(() => {
          self.setState({
            successMessage: '',
          });
        }, 3000);
      }else{
        self.setState({
          successMessage: "Successfully created",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    }).catch(function (err) {
      console.log('err',err)
      self.setState({error:err.message})
    });
  }

  performWeeklyQuestionnaire = async() => {
    if (window.confirm("Do you really want to perform weekly questionnaire for this user ?")) {
      try {
        const result = await api.get(`s/set_weekly_questionnaire/${this.state.id}`)
      }catch(err) {
        console.log('err',err)
        this.setState({error:err.message})
      }
    }
  };

  getPerformanceAvg = async () => {
    
    let appStartDate = new Date(this.state.dateOfStartUsingApp*1000);
    console.log('appStartDate', appStartDate)

    var fullDate = appStartDate;
    var twoDigitMonth = (parseInt(fullDate.getMonth()) + 1) + "";
    if (twoDigitMonth.length == 1)
        twoDigitMonth = "0" + twoDigitMonth;
    var twoDigitDate = fullDate.getDate() + "";
    if (twoDigitDate.length == 1)
        twoDigitDate = "0" + twoDigitDate;
    var startDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();

    var dateClicked = new Date();
    var twoDigitMonthClicked = (parseInt(dateClicked.getMonth()) + 1) + "";
    if (twoDigitMonthClicked.length == 1)
    twoDigitMonthClicked = "0" + twoDigitMonthClicked;
    var twoDigitDateClicked = dateClicked.getDate() + "";
    if (twoDigitDateClicked.length == 1)
        twoDigitDateClicked = "0" + twoDigitDateClicked;
    var endDate = twoDigitMonthClicked + "/" + twoDigitDateClicked + "/" + dateClicked.getFullYear();

    var firstDate = new Date(startDate);
    var secondDate = new Date(endDate);

    // time difference
    var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());

    // days difference
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    // shift 
    var shift = diffDays+1;

    try{
      let { data: dailes } = await api.get('getDailiesByUserId', { params: { userId: this.state.id } })
      dailes = dailes?.filter(i => i?.shift <= shift)

      if(dailes?.length > 0){
        let done = 0;
        let no = 0;
        for (let dataObj of dailes) {
          for (let prData of dataObj.pr) {
            if(prData.done===true){
              done = done+1;
            }else if(prData.done===false){
              no = no+1;
            }
          }
        }
        this.setState({
          performance: ((done*100)/(done+no)).toFixed(2) + '%'
        });
      }
    }catch(err){
      console.log('err',err)
      this.setState({error:err.message})
    }
  }

  onChangeCalender = async (value) => {
    if(value.length > 1){
      if(this.state.dateOfStartUsingApp){
        let appStartDate = new Date(this.state.dateOfStartUsingApp*1000);
        var fullDate = appStartDate;
        var twoDigitMonth = (parseInt(fullDate.getMonth()) + 1) + "";
        if (twoDigitMonth.length == 1)
          twoDigitMonth = "0" + twoDigitMonth;
        var twoDigitDate = fullDate.getDate() + "";
        if (twoDigitDate.length == 1)
          twoDigitDate = "0" + twoDigitDate;
        var startDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();  

        var dateClicked = value[0];
        var twoDigitMonthClicked = (parseInt(dateClicked.getMonth()) + 1) + "";
        if (twoDigitMonthClicked.length == 1)
        twoDigitMonthClicked = "0" + twoDigitMonthClicked;
        var twoDigitDateClicked = dateClicked.getDate() + "";
        if (twoDigitDateClicked.length == 1)
          twoDigitDateClicked = "0" + twoDigitDateClicked;
        var endDate = twoDigitMonthClicked + "/" + twoDigitDateClicked + "/" + dateClicked.getFullYear();

        var firstDate = new Date(startDate);
        var secondDate = new Date(endDate);

        console.log('fs', firstDate, secondDate);

        // time difference
        var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());

        // days difference
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        // shift 
        var shift = diffDays;
        console.log('from', shift)

      }else{

        let appStartDate = new Date();
        var fullDate = appStartDate;
        var twoDigitMonth = (parseInt(fullDate.getMonth()) + 1) + "";
        if (twoDigitMonth.length == 1)
          twoDigitMonth = "0" + twoDigitMonth;
        var twoDigitDate = fullDate.getDate() + "";
        if (twoDigitDate.length == 1)
          twoDigitDate = "0" + twoDigitDate;
        var startDate = twoDigitMonth + "/" + twoDigitDate + "/" + fullDate.getFullYear();  

        var dateClicked = value[0];
        var twoDigitMonthClicked = (parseInt(dateClicked.getMonth()) + 1) + "";
        if (twoDigitMonthClicked.length == 1)
        twoDigitMonthClicked = "0" + twoDigitMonthClicked;
        var twoDigitDateClicked = dateClicked.getDate() + "";
        if (twoDigitDateClicked.length == 1)
          twoDigitDateClicked = "0" + twoDigitDateClicked;
        var endDate = twoDigitMonthClicked + "/" + twoDigitDateClicked + "/" + dateClicked.getFullYear();

        var firstDate = new Date(startDate);
        var secondDate = new Date(endDate);

        console.log('fs', firstDate, secondDate);

        // time difference
        var timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());

        // days difference
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        // shift 
        // var shift = diffDays;
        var shift = 0;
        console.log('from', shift)
        this.setState({
          dateOfStartUsingAppIfNot:secondDate.getTime()
        });

      }
      

      //to
      let appStartDateTo = value[0];
      var fullDateTo = appStartDateTo;
      var twoDigitMonthTo = (parseInt(fullDateTo.getMonth()) + 1) + "";
      if (twoDigitMonthTo.length == 1)
        twoDigitMonthTo = "0" + twoDigitMonthTo;
      var twoDigitDate = fullDateTo.getDate() + "";
      if (twoDigitDate.length == 1)
        twoDigitDate = "0" + twoDigitDate;
      var startDateTo = twoDigitMonthTo + "/" + twoDigitDate + "/" + fullDateTo.getFullYear();

      var dateClickedSecond = value[1];
      var twoDigitMonthToClicked = (parseInt(dateClickedSecond.getMonth()) + 1) + "";
      if (twoDigitMonthToClicked.length == 1)
      twoDigitMonthToClicked = "0" + twoDigitMonthToClicked;
      var twoDigitDateClickedSecond = dateClickedSecond.getDate() + "";
      if (twoDigitDateClickedSecond.length == 1)
        twoDigitDateClickedSecond = "0" + twoDigitDateClickedSecond;
      var endDateTo = twoDigitMonthToClicked + "/" + twoDigitDateClickedSecond + "/" + dateClickedSecond.getFullYear();

      var firstDateTo = new Date(startDateTo);
      var secondDateTo = new Date(endDateTo);

      // time difference
      var timeDiffTo = Math.abs(secondDateTo.getTime() - firstDateTo.getTime());

      // days difference
      var diffDaysTo = Math.ceil(timeDiffTo / (1000 * 3600 * 24));

      // shift 
      var toDate = diffDaysTo+1;
      console.log('to', toDate)

      this.setState({
        shift:shift,
        toDate:toDate,
      });
    }
  }

  checkInvite = async() => {
    this.setState({isInvitedDetail:!this.state.isInvitedDetail});
    await fb.InvitedUsersCollection
    .where("number","==", this.state.invited_by_code)
    .get()
    .then(async snapShot => {
      if(snapShot.docs.length > 0){
        let data = snapShot.docs[0].data();
        this.setState({
          invite_name:data.name,
          invite_created:data.created && data.created.seconds ? convertFirebseSecondsToDate(data.created.seconds) : "",
          isFromInviteCode:true
        })
      }else{
        const {data: users} = await api.get('getUsers')
        const {data: admins} = await api.get('getAdmins')
        const {data: experts} = await api.get('expertsList')
        
        const dataObj = [...users, ...admins, ...experts]?.find(i => i?.invite_code === this.state.invited_by_code)
        
        this.setState({
          r_id:dataObj.id,
          r_name:dataObj.name ? dataObj.name : dataObj.email,
          r_created:dataObj.created ? convertMilisecondsToDate(new Date(dataObj.created).getTime()) : "",
          isFromUserCode:true
        })
      } 
    }).catch(err=> {
      console.log('err',err)
      this.setState({
        error:err.message,
        disabled: false
      });
    });
  }

  generateDailies = async() => {
    if (window.confirm("Do you really want to generate dailies for this customer?")) {
      try {
        const result = await api.get(`s/create_daily_schedule/${id}/${shift}/${toDate}`)
        let {shift, toDate, id} = this.state;

        //update start date of using app
        if(this.state.dateOfStartUsingApp===""){
          api.post('updateUser', {
            id: this.state.id,
            dateOfStartUsingApp:this.state.dateOfStartUsingAppIfNot /  1000
          });
        }
      }catch(err) {
        console.log('err',err)
        this.setState({error:err.message})
      }
    }
  };

  showInvitedUser=() => {
    window.open(`/admin/users/view/${this.state.r_id}`);
  }

  render() {
    // console.log('render', this.state)
    return (
      <div className="content customer-details">
        <Link to={`/admin/users`} className="btn btn-success users-back-btn libi-admin-btn">Back</Link>
        <Link 
          to={`#`} 
          className="btn btn-success libi-admin-btn"
          style={{
            float: "right",
            margin: "0 15px 0 0"
          }}
          onClick={() => {this.performWeeklyQuestionnaire()}}
        >
          Perform Weekly Questionnaire
        </Link>
        <Container fluid>
          <Row>
            <Col md={6}>
              {this.state.successMessage && (
                  <Alert bsStyle="success">
                    <button type="button" aria-hidden="true" className="close">
                      &#x2715;
                    </button>
                    <span>{this.state.successMessage}</span>
                  </Alert>
              )}
              {this.state.error &&
                <Alert bsStyle="danger">
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Private Information"
                button={
                  <Link to={`#`} onClick={(event) => {this.handleSubmitPrivateInfo(event)}} className="btn btn-success custom-btn edit-btn pull-right btn-right libi-admin-btn">
                    {this.state.editPrivateInfo ? "Save": "Edit"} 
                  </Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  this.state.editPrivateInfo ?
                    <form onSubmit={this.handleSubmit}>
                    <Row>
                      {(this.state.firstName && this.state.lastName) ? (
                        <div className="mx-2">
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>First Name</FormLabel>
                              <input
                                type ="text"
                                className= "form-control"
                                placeholder= "First Name"
                                name="firstName"
                                value= {this.state.firstName}
                                onChange = {(event) => this.onChangeHandler(event)}
                              />
                              <span className="help-block">{this.state.errors.firstName}</span>
                            </FormGroup>
                          </div>
                          <div className="col-md-6">
                            <FormGroup>
                              <FormLabel>Last Name</FormLabel>
                              <input
                                type ="text"
                                className= "form-control"
                                placeholder= "Last Name"
                                name= "lastName"
                                value= {this.state.lastName}
                                onChange = {(event) => this.onChangeHandler(event)}
                              />
                              <span className="help-block">{this.state.errors.lastName}</span>
                            </FormGroup>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Name</FormLabel>
                            <input
                              type ="text"
                              className= "form-control"
                              placeholder= "Name"
                              name= "name"
                              value= {this.state.name}
                              onChange = {(event) => this.onChangeHandler(event)}
                            />
                            <span className="help-block">{this.state.errors.name}</span>
                          </FormGroup>
                        </div>
                      )}
                      <div 
                        className={(this.state.firstName && this.state.lastName) ? "col-md-12" : "col-md-6"}
                        style={{paddingLeft: (this.state.firstName && this.state.lastName) ? "15px" : "0"}}
                      >
                        <FormGroup>
                          <FormLabel>Email</FormLabel>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            value= {this.state.email}
                            onChange ={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.email}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Account Status</FormLabel>
                          <select
                            className="form-control"
                            name="status"
                            value={this.state.status}
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Status</option>
                            { 
                              userAccountStatus().map((status, index) => {
                                return <option key={index} value={status.key}>{status.val}</option>
                              })
                            }
                          </select>
                          <span className="help-block">{this.state.errors.status}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>DOB</FormLabel>
                          <DatePicker
                            selected={this.state.dob}
                            dateFormat="yyyy/MM/dd"
                            className="form-control"
                            placeholderText="Example: yyyy/MM/dd"
                            onChange={(event)=>this.onChangeDOBHandler(event)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Gender</FormLabel>
                          <select
                            className= "form-control"
                            name= "gender"
                            value={this.state.gender} 
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Gender</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                            <option value="O">Other</option>
                          </select>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Partner</FormLabel>
                          <SelectSearch
                            options={this.state.expertsList}
                            search={true}
                            value={this.state.currentExpert}
                            name="expert"
                            placeholder="Select Partner"
                            onChange={(value)=>this.setState({currentExpert: value})}
                          />
                          <span className="help-block">{this.state.errors.expert}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    {
                      checkPaymentStatusToShown().includes(this.state.status) &&
                      <Row>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Last Payment Date</FormLabel>
                            <DatePicker
                              selected={this.state.paymentPlanStart && new Date((this.state.paymentPlanStart/1000)*1000)}
                              dateFormat="yyyy/MM/dd"
                              className="form-control"
                              placeholderText="Example: yyyy/MM/dd"
                              onChange={(event)=>this.onChangePaymentPlanStartHandler(event)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Next Payment Date</FormLabel>
                            <DatePicker
                              selected={this.state.paymentPlanActive && new Date(this.state.paymentPlanActive*1000)}
                              dateFormat="yyyy/MM/dd"
                              className="form-control"
                              placeholderText="Example: yyyy/MM/dd"
                              onChange={(event)=>this.onChangePaymentPlanActiveHandler(event)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          </FormGroup>
                        </div>
                      </Row>
                    }
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Payment status</FormLabel>
                          <select
                            className="form-control"
                            name="checkPayment"
                            value={this.state.checkPayment}
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="-1">Unknown</option>
                            <option value="0">Unpaid</option>
                            <option value="1">Paid</option>
                          </select>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Test</FormLabel>
                          <select
                            className= "form-control"
                            name="test"
                            value={this.state.test} 
                            onChange={(event)=>this.onChangeHandler(event)}
                          >
                            <option value="">Select Test</option>
                            <option value="1">True</option>
                            <option value="0">False</option>
                          </select>
                        </FormGroup>
                      </div>
                    </Row>
                  </form>
                  :
                  <div>
                    <div className="">
                      <h5>
                        <span className="category">Reference Code</span> {this.state.refCode}
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Name</span> 
                        {(this.state.firstName && this.state.lastName) 
                          ? this.state.firstName + ' ' + this.state.lastName 
                          : this.state.name || '-'
                        }
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Email</span> <span style={{wordBreak: 'break-word'}}>{this.state.email}</span>
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                          <span className="category">Gender</span> {this.state.gender == "M" ? "Male" : this.state.gender == "F" ? "Female" : "Other" }
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Status</span> {this.state.status ? getUserStatusOption(this.state.status) : ""}
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Partner</span> {this.state.expert ? this.state.expert.name : '-'}
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Language</span> {this.state.language}
                      </h5>
                    </div>
                    {
                      checkPaymentStatusToShown().includes(this.state.status) &&  this.state.paymentStatus === "Paid" && <div>
                      
                      <div className="">
                        <h5>
                          <span className="category">Initial Payment Date</span> {this.state.initialPaymentDate && this.state.initialPaymentDate.seconds && convertFirebseSecondsToDate(this.state.initialPaymentDate.seconds)}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Last Payment Date</span> {this.state.paymentPlanStart && convertFirebseSecondsToDate(Math.floor(this.state.paymentPlanStart / 1000))}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Next Payment Date</span> {this.state.paymentPlanActive && convertFirebseSecondsToDate(this.state.paymentPlanActive)}
                        </h5>
                      </div>

                    </div>
                    }
                    <div className="">
                      <h5>
                        <span className="category">Test</span> {this.state.test && this.state.test==="1" ? "True" : "False"}
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Invite Code</span> 
                        <Link to={`/admin/users/referral/${this.state.id}`}> { this.state.inviteCode } </Link>

                      </h5>
                    </div>
                    <div className="">
                      <h5>
                      <span className="category">Create coupon</span> 
                        <a target="_blank" href={`${fb.CRM_URL}/sh/create_coupon?code=${this.state.inviteCode}&percentage=0`}>0% discount</a>
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Invited By</span> 
                        <Link to={`#`} onClick={() => {this.checkInvite()}}> { this.state.invited_by_code } </Link>
                        
                      </h5>
                      {
                        this.state.isFromInviteCode && this.state.isInvitedDetail &&
                        <div className="invite-from">
                          <h4><strong>Invite Details</strong></h4>
                          <h5>
                            <span className="category">Name</span> { this.state.invite_name }
                          </h5>
                          <h5>
                            <span className="category">Created</span> { this.state.invite_created }
                          </h5>
                        </div>
                      }
                      {
                        this.state.isFromUserCode && this.state.isInvitedDetail &&
                        <div className="invite-from">
                          <h4><strong>Invite Details</strong></h4>
                          <h5>
                            <span className="category">Name</span>
                            <Link onClick={() => {this.showInvitedUser()}} to={`#`}> { this.state.r_name }</Link>
                          </h5>
                          <h5>
                            <span className="category">Created</span> { this.state.r_created }
                          </h5>
                        </div>
                      }
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Payment Plan</span>
                        <div className="space1">{this.state.paymentPlanCode ? <Link to={`/admin/payment/view/${this.state.id}`}>{this.state.paymentPlanCode}</Link> : 'N/A'}</div>
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Start Date </span>
                        <div className="space1">{  this.state.dateOfStartUsingApp ?convertUnixTimestampToDate(this.state.dateOfStartUsingApp) : <div style={{color:"red"}}>Don't have date of treatment plan start</div>}</div>
                      </h5>
                    </div>
                    <div>
                      <h5><span className="category"> User Performance </span>
                      <Link to={`/admin/users/performance/${this.state.id}`}> {this.state.performance}  </Link><i className="fa fa-calendar" aria-hidden="true"></i>
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Last Online</span> {(this.state.lastOnlineTime && this.state.lastOnlineTime.seconds) ? convertFirebseSecondsToDate(this.state.lastOnlineTime.seconds) : 'N/A'}
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">App version</span> {this.state.appPlatform ? this.state.appPlatform : ''} {this.state.appVersion ? this.state.appVersion : '< 1.09'}
                      </h5>
                    </div>
                    <div className="">
                      <h5>
                        <span className="category">Skip Questionnaire</span> {this.state.skipQuestionnaire ? 'true' : 'false'}
                      </h5>
                    </div>
                  </div>
                }
              />
            </Col>
            <Col md={6}>
              <Card
                title="Delivery Address"
                button={
                  <Link to={`#`} onClick={(event) => {this.handleSubmitDeliveryInfo(event)}} className="btn btn-success custom-btn edit-btn pull-right btn-right libi-admin-btn">
                    { this.state.editDeliveryInfo ? 'Save' : 'Edit' }
                  </Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <div>
                  { this.state.editDeliveryInfo ?
                    <form onSubmit={this.handleSubmit}>
                      <Row>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Full Name</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Full Name"
                                name="fullname"
                                value={this.state.shippingAddress ? this.state.shippingAddress.fullname : ""}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Phone</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Phone"
                                name="phone"
                                value={this.state.shippingAddress ? this.state.shippingAddress.phone : ""}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Country</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                name="country"
                                value={this.state.shippingAddress ? this.state.shippingAddress.country : ""}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>City</FormLabel>
                            <input 
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              value={this.state.shippingAddress ? this.state.shippingAddress.city : ""}
                              onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Postal Code</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Postal Code"
                                name="postal_code"
                                value={this.state.shippingAddress ? this.state.shippingAddress.postal_code : ""}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Street</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Street"
                                name="line1"
                                value={this.state.shippingAddress ? this.state.shippingAddress.line1 : ""}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Apt (Optional)</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Apartment"
                                name="line2"
                                value={this.state.shippingAddress ? this.state.shippingAddress.line2 : ""}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                      <Row>
                        <div className="col-md-6">
                          <FormGroup>
                            <FormLabel>Street</FormLabel>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Street"
                                name="line1"
                                value={this.state.shippingAddress ? this.state.shippingAddress.line1 : ""}
                                onChange={(event)=>this.onChangeHandler(event)}
                            />
                          </FormGroup>
                        </div>
                      </Row>
                    </form>
                    :
                    <div>
                      <div className="">
                        <h5>
                          <span className="category">Full Name</span> {this.state.shippingAddress.fullname}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Phone</span> {this.state.shippingAddress.phone}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Country</span> {this.state.shippingAddress.country}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">City</span> {this.state.shippingAddress.city}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Postal Code</span> {this.state.shippingAddress.postal_code}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Street Address</span> {this.state.shippingAddress.line1}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Apt (Optional)</span> {this.state.shippingAddress.line2}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Note</span> <strong>Customer asked {this.state.packageDelay} week(s) package delay</strong>
                        </h5>
                      </div>
                    </div>
                  }
                </div>
                }
              />
            </Col>
            {/*<Col md={6}>
              <Card
                  title="General Information"
                  loader={ this.state.isLoading && <PageLoader />}
                  content={
                    <div>
                      <div className="">
                        <h5>
                          <span className="category">Payment method</span> {this.state.generalData.paymentMethod ? 'Exists' : 'Not exists'}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">User since</span> {this.state.generalData.created}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Initial Payment Day</span> {this.state.generalData.initialPaymentDate}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Subscription period</span> {this.state.generalData.paymentPlanPeriod}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Subscription type</span> {this.state.generalData.subscriptionType}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Last payment date</span> {this.state.generalData.lastPaymentDate} (invoice {this.state.generalData.lastPaymentDateInvoice})
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Next payment date</span> {this.state.generalData.nextPaymentDate}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Next shipment date</span> {this.state.generalData.nextShipmentDate}
                        </h5>
                      </div>
                      <div className="">
                        <h5>
                          <span className="category">Write to user</span> <Link to={`/admin/chat/${this.state.id}`}>Chat</Link> <Link to="#" onClick={(e) => {
                          window.location = `mailto:${this.state.email}`;
                          e.preventDefault();
                        }}>Email</Link>
                        </h5>
                      </div>
                      <Table striped hover id="subscription-datatable">
                        <thead>
                        <tr>
                          <th>Created</th><th>Country</th><th>Status</th><th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.generalData.subscriptionList &&
                        this.state.generalData.subscriptionList.map((val, key) => {
                          return (
                              <tr key={key}>
                                <td data-sort={key}>{convertFirebseSecondsToDate(val.created)}</td>
                                <td>{val.country}</td>
                                <td>{val.status}</td>
                                <td>
                                  {val.status === 'active' &&
                                    <a style={{color: 'red', cursor: 'pointer'}}
                                     onClick={() => {this.cancelSubscription(val)}}>Delete</a>
                                  }
                                  {val.status === 'canceled' &&
                                    <a style={{color: 'green', cursor: 'pointer'}}
                                     onClick={() => {this.createSubscription(val)}}>Recreate</a>
                                  }
                                </td>
                              </tr>
                          );
                        })}
                        </tbody>
                      </Table>
                    </div>
                  }
              />
                </Col>*/}
            {/*<Col md={6}>
              <Card
                  title="Payment Information"
                  loader={ this.state.isLoading && <PageLoader />}
                  content={
                    <Table striped hover id="payment-datatable">
                      <thead>
                      <tr>
                        {paymentInformationListThArray.map((val, key) => {
                          return <th key={key}>{val}</th>;
                        })}
                      </tr>
                      </thead>
                      <tbody>
                      {this.state.paymentInformationList.map((val, key) => {
                        return (
                            <tr key={key}>
                              <td data-sort={key}>{val.date}</td>
                              <td>{val.country}</td>
                              <td>{val.payment}</td>
                              <td>
                                {val.document ?
                                  <Link to={{pathname: val.document}} target="_blank"> <i className="fa fa-file-pdf-o"/></Link>
                                    :
                                    val.status
                                }
                              </td>
                            </tr>
                        );
                      })}
                      </tbody>
                    </Table>
                  }
              />
                </Col>*/}
          </Row>
        </Container>
        {/*<div className="content">*/}
          
          {this.state.isOpenUserTreatment &&  
            <UserTreatmentView
              show={this.state.isOpenUserTreatment}
              data={{currentDocument:this.state.currentDocument}}
              onHide={() => this.handleShowUserTreatmentDialog(false, {})}
            />                                   
          }
          <Container fluid>
            <Row>
              <Col md={6}>
                  <Card
                    title="Customer Questionnaire Answers"
                    category=""
                    ctTableFullWidth
                    ctTableResponsive
                    loader={ this.state.isLoading && <PageLoader />}
                    content={
                      <Table striped hover id="answers-datatable">
                        <thead>
                        <tr>
                          {answersListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.answersList.map((val, key) => {
                          return (
                            <tr key={key}>
                              <td data-sort={val.created_sec}>{val.created}</td>
                              <td>{val.mark.toLowerCase() === 'intro' ? 'Initial' : 'Secondary'}</td>
                              <td>
                                <Link target="_blank" to={`/admin/users/answers/${val.user_id}/${val.id}`}  className="btn btn-success btn-small libi-admin-btn mb-3"> <i className="fa fa-eye" /></Link>&nbsp;&nbsp;
                                {val.mark.toLowerCase() === 'intro' ?
                                <Link target="_blank" to={`/admin/users/${this.state.userVersion == 2 ? 'assessment' : 'diagnosis'}/${this.state.id}/${val.id}`}
                                      className={`btn btn-${val.treatment ? 'success' : 'danger'} btn-small libi-admin-btn mb-3`}
                                      style={{width: "141px"}}> {val.treatment ? 'Diagnosis Sent' : 'Awaiting Diagnosis'}</Link>
                                  :
                                  <>{val.treatment &&
                                    <span style={{color: 'green'}} className="mb-3">Reviewed</span>
                                  }</>
                                }
                              </td>
                            </tr>
                          );
                        })}
                        </tbody>
                      </Table>
                    }
                  />
              </Col>
              <Col md={6}>
                <Card
                  title="Customer Packages"
                  button={
                    <Link to={`#`} onClick={(event) => {this.handleCheckPackageNeed(event)}} className="btn btn-success custom-btn edit-btn pull-right btn-right libi-admin-btn">
                      Check package need
                    </Link>
                  }
                  loader={ this.state.isLoading && <PageLoader />}
                  content={
                    <div className="table-responsive-important">
                      <Table striped hover id="package-datatable">
                        <thead>
                        <tr>
                          {customerPackageListThArray.map((val, key) => {
                            return <th key={key}>{val}</th>;
                          })}
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.customerPackages.map((val, key) => {
                          if(!!val && !!Object.keys(val).length) {
                            return (
                              <tr key={key}>
                                <td><span style={{display:"none"}}>{val.created}</span>{convertMilisecondsToDate(new Date(val.created).getTime())}{ this.renderRiskColor(val.risk)}</td>
                                <td>{val.status}</td>
                                <td>{val.products}</td>
                                <td><Link to={`/admin/shipment/view/${val.id}`}> <i className="fa fa-eye" /></Link></td>
                              </tr>
                            );
                          }
                          return null
                        })}
                        </tbody>
                      </Table>
                    </div>
                  }
                />
              </Col>
            </Row>
              <Row>
                <Col md={6}>
                  <Card
                    title="Treatment Plan"
                    button={
                      <Link to={`/admin/users/${this.state.userVersion == 2 ? 'assessment' : 'diagnosis'}/${this.state.id}${this.state.currentTreatmentID ? `/${this.state.currentTreatmentID}` : ''}?type=t`} className="btn btn-success custom-btn edit-btn pull-right btn-right libi-admin-btn">
                        New
                      </Link>
                    }
                    category=""
                    ctTableFullWidth
                    ctTableResponsive
                    loader={ this.state.isLoading && <PageLoader />}
                    content={
                      <Table striped hover id="skincare-datatable">
                        <thead>
                          <tr>
                            {userTreatmentThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.userTreatment.map((val, key) => {
                            return (
                              <tr key={key}>
                                <td data-sort={val.seconds}>{val.time}</td>
                                <td data-sort={val.confirmed_seconds}><Link target="_blank" to={`/admin/users/${this.state.userVersion == 2 ? 'assessment' : 'diagnosis'}/${this.state.id}/${val.id}?type=t`}>{val.confirmed}</Link></td>
                                <td style={{wordBreak:"break-all"}}>{val.confirmed_by}</td>
                                <td>
                                  <Link target="_blank" to={`/admin/users/${this.state.userVersion == 2 ? 'assessment' : 'diagnosis'}/${this.state.id}/${val.id}?type=t`}><i className="pe-7s-note2" /></Link>
                                  <div className={`fake_link ${this.state.selectedTreatmentID === val.id ? 'active' : ''}`} onClick={() => {this.showCurrentTreatmentProduct(val.id)}}>
                                    {this.state.selectedTreatmentID === val.id ? <i className="fa fa-arrow-right" /> : <i className="fa fa-eye" />}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    }
                  />
                </Col>
                <Col md={6}>
                  <Card
                    title="Treatment Products"
                    category=""
                    ctTableFullWidth
                    ctTableResponsive
                    loader={ this.state.isLoading && <PageLoader />}
                    content={
                      <div>
                        <h4>Morning</h4>
                        {
                          this.state.morning && this.state.morning.map((p, k) => {
                            return <p className="black" key={k}><b>{p.title}: </b>{this.state.userVersion == 2 ? p.time : p.rule}</p>
                          })
                        }
                        <h4>Evening</h4>
                        {
                          this.state.evening && this.state.evening.map((p, k) => {
                            return <p className="black" key={k}><b>{p.title}: </b>{this.state.userVersion == 2 ? p.time : p.rule}</p>
                          })
                        }
                      </div>
                    }
                  />
                </Col>
            </Row>
          </Container>
        {/*</div>*/}
      </div>
    );
  }
}

export default ViewUser;
