import React, { Component } from "react";
import { Button, Container, Form, Nav, Navbar, NavDropdown } from "react-bootstrap";

import AdminNavbarLinks from "./AdminNavbarLinks.jsx";

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false
    };
  }
  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    //document.body.appendChild(node);
  }

  render() {
    return (

      <Navbar bg="light" expand="lg">
        <Container fluid>
          <Navbar.Brand>
            {this.props.brandText}
          </Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />

          <Navbar.Collapse>
            <Nav className="ml-auto" navbar>
              <Nav.Link href="#">&nbsp;</Nav.Link>
            </Nav>
            <AdminNavbarLinks />
          </Navbar.Collapse>
        </Container>
      </Navbar>

    );
  }
}

export default Header;
