import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import { Card } from "../../components/Card/Card.jsx";
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import { UserContext } from "../../providers/UserProvider";
import api from "api.js";

class EditAdminProfile extends Component {

  static contextType = UserContext;

  constructor(props){
    super(props);
    this.state = {
      id:"",
      name:"",
      email:"",
      picture:"",
      image: {},
      successMessage:"",
      errors:{}
    };
    this._isMounted = false;
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    if(name === 'name') {
      this.setState({name:value});
    }else if(name === 'email'){
      this.setState({email:value});
    }else if(event.target.files && event.target.files[0]){
      this.setState({image:event.target.files[0]});
    }
  };

  componentDidMount = async () => {
    const  url = window.location.href;
    const segment = url.substring(url.lastIndexOf('/') + 1);
    const {data: userByID} = await api.get('userByID', {params: {userId: segment}})
    this.setState({
      id: userByID.id,
      name: userByID.name,
      email: userByID.email,
      picture: userByID.picture
    })
  }

  handleValidation = () => {
    let {name, email } = this.state;
    let errors = {};
    let formIsValid = true;

    //Name
    if(!name){
      formIsValid = false;
      errors["name"] = "Name is required";
    }

    //Email
    if(!email){
      formIsValid = false;
      errors["email"] = "Please enter a valid email address";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this._isMounted = true;
    if(this.handleValidation(this.state.errors) &&  typeof(this.state.image.name) != "undefined") {
      var timestamp = Math.floor(Date.now() / 1000);
      fb.storage.child(`pictures/${timestamp}-${this.state.image.name}`).put(this.state.image)
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then((url) => { 
        if(url) 
        if(this._isMounted === true){
          this.setState({picture:url}, () => {
            this.saveUser()
          });
        }
      });
    }else{
      this.saveUser(); 
    }
  }

  saveUser = () => {
    console.log('saveUser', this.state)
    api.post('updateUser', {
      id: this.state.id,
      name:this.state.name,
      picture:this.state.picture,
    })
    this.setState({successMessage:"Profile updated successfully!"})
    setTimeout(() => {
      this.setState({
        successMessage: ''
      });
    }, 3000)
  }

  componentWillUnmount = () => {
    if(this._isMounted){
      this._isMounted = false;
    }
  }

  render() {
    console.log('Render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Update Profile"
                content={
                  <form onSubmit={this.handleSubmit}>
                  {this.state.successMessage !== null && <div className = "py-4 bg-red-600 w-full text-success mb-3">{this.state.successMessage}</div>}
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Name</FormLabel>
                          <input
                            type ="text"
                            className= "form-control"
                            placeholder= "Name"
                            name= "name"
                            value= {this.state.name}
                            onChange = {(event) => this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.name}</span>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Email</FormLabel>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            readOnly
                            name="email"
                            value= {this.state.email}
                            onChange ={(event)=>this.onChangeHandler(event)}
                          />
                          <span className="help-block">{this.state.errors.email}</span>
                        </FormGroup>
                      </div>
                    </Row>
                    <Row>
                      <div className="col-md-6">
                        <FormGroup>
                          <FormLabel>Image</FormLabel>
                          <input 
                            type="file" 
                            name="picture" 
                            className="form-control" 
                            onChange={(event)=>this.onChangeHandler(event)}
                          />
                        </FormGroup>                        
                        { this.state.picture && <img src={this.state.picture}  alt="" /> }
                      </div>
                    </Row>
                    <Button 
                      bsStyle="info" 
                      pullRight fill 
                      type="submit">
                      Update
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditAdminProfile;
