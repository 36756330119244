import React, { Component } from "react";

class PopupImage extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:"",
            popup: false,
        }
    }

    togglePopup() {
        let popup = this.state.popup
        this.setState({popup: !popup})
    }

    render() {
        return (
          <>
              <div className={`big_image_without_popup ${this.props.big && 'big'}`} style={{backgroundImage: "url(" + this.props.src + ")"}} />
              <img src={this.props.src} className={`popup_image ${this.props.className} ${this.props.big && 'big'} ${this.state.popup ? 'show_popup' : ''}`} onClick={(e) => {this.togglePopup()}} alt=""/>
          </>
        );
    }
}

export default PopupImage;
