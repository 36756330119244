import Card from 'components/Card/Card'
import PageLoader from 'components/Loader/PageLoader'
import React, { useEffect, useState } from 'react'
import { Col, Container, FormGroup, Row } from 'react-bootstrap';
import Button from "../../components/CustomButton/CustomButton.jsx";
import * as fb from "../../firebase";
import { convertFirebseSecondsToDate } from 'components/Helper';
import moment from 'moment';
import { Link } from 'react-router-dom';
import api from 'api.js';

const PaymentsOrder = () => {
  const orderId = window.location.pathname.split('/').splice(-1)[0]

  const [order, setOrder] = useState({})
  const [productsList, setProductsList] = useState([])
  const [type, setType] = useState('')
  const [created, setCreated] = useState('')
  const [isEdit, setIsEdit] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const handleClick = async () => {
    if (!isEdit) {
      setIsEdit(true)
      return
    }

    await api.post('updateOrder', {
      id: orderId,
      created,
      type
    })

    setIsEdit(false)
  }

  const fetchData = async () => {
    try {
      setIsLoading(true)

      const {data: orderObj} = await api.get('orderByID', { params: { orderId } })

      if (orderObj) {
        const {data: partnerData} = await api.get('userByID', {params: {userId: orderObj.expertId}})

        if (partnerData) {
          orderObj.partnerName = ((partnerData?.firstName && partnerData?.lastName) && `${partnerData?.firstName} ${partnerData?.lastName}`) || partnerData?.name || '-'
          orderObj.partnerEmail = partnerData.email || '-'
        }

        if (orderObj.type === 'rb') {
          const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: orderObj.user_id } })
          const treatment = treatmentsByUserId.filter(i => i.id === orderObj.treatmentId)

          if (treatment) {
            orderObj.product = {
              products: Object.values(treatment.products).map(i => ({ ref: i.product || i.ref, quantity: i.qty }))
            }
          }
        }

        setOrder(orderObj)
        setType(orderObj?.type)
        setCreated(new Date(orderObj.created).getTime())
      }

      const allProduct = await fb.productsCollection.orderBy("order", "asc").get();
      const productMasterArr = [];
      allProduct.docs.forEach(doc => {
        const dataObj = doc.data();
        let p = dataObj.ref.replace("p", "");
        productMasterArr.push({
          ref: dataObj.ref,
          title: dataObj.title,
          p: p
        })
      });
      const sortedProduct = productMasterArr.sort(function (a, b) {
        return parseInt(a.p) - parseInt(b.p);
      });
      setProductsList(sortedProduct)

    } catch (err) {
      console.log('err', err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card
              title="Order View"
              button={
                <Link to={`/admin/payment`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
              }
              category=""
              ctTableFullWidth
              ctTableResponsive
              loader={isLoading && <PageLoader />}
              content={
                <>
                  <p><b>Type: </b> 
                    {isEdit ?
                      <FormGroup style={{ 'display': 'inline-block' }}>
                        <select
                          className="form-control"
                          value={type}
                          onChange={ev => setType(ev.target.value)}
                        >
                          <option value="ip">Individual products</option>
                          <option value="ds">Discovery Set</option>
                          {/* <option value="qcA">Quintessential collection А</option>
                          <option value="qcB">Quintessential collection B </option> */}
                          <option value="rb">Red box</option>
                        </select>
                      </FormGroup> :
                      type?.toUpperCase() || '-'
                    }
                  </p>
                  <p><b>Created: </b>
                    {isEdit ?
                      <FormGroup style={{ 'display': 'inline-block' }}>
                        <input
                          type="date"
                          className="form-control"
                          value={moment(created).format('YYYY-MM-DD')}
                          onChange={(event) => {
                            setCreated(fb.firebase.firestore.Timestamp.fromDate(new Date(event.target.value)))
                          }}
                        />
                      </FormGroup>
                      : created
                        ? convertFirebseSecondsToDate(created)
                        : "N/A"
                    }
                  </p>
                  <p><b>Status: </b>
                    {(order?.status === 'paid_shopify' || order?.status === 'paid_stripe') && 'Paid'}
                    {order?.status === 'payment_needed' && 'Payment needed'}
                    {order?.status === 'delivery_in_process' && 'Delivery In-Process'}
                    {order?.status === 'confirmed' && 'Confirmed'}
                  </p>
                  <p><b>Partner Name:</b> {order?.partnerName || '-'}</p>
                  <p><b>Partner Email:</b> {order?.partnerEmail || '-'}</p>
                  <p><b>User Name:</b> {order?.userName || '-'}</p>
                  <p><b>User Email:</b> {order?.userEmail || '-'}</p>
                  {['qc', 'ds'].includes(order?.type) && <p><b>Title:</b> {order?.product?.title}</p>}
                  {['qc', 'ds'].includes(order?.type) && <p><b>Allergy:</b> {order?.allergy}</p>}

                  <Button
                    className="btn-fill libi-admin-btn"
                    style={{ margin: '0 0 20px 0' }}
                    bsStyle="info"
                    fill
                    onClick={handleClick}
                  >
                    {isEdit ? 'Save' : 'Edit'}
                  </Button>

                  <table className="products-table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Ref</th>
                        <th>Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.product?.products
                        .sort((a, b) => Number(a?.ref?.replace( /[^0-9]/g, '')) - Number(b?.ref?.replace( /[^0-9]/g, '')))
                        .map(i => (
                          <tr key={i?.ref}>
                            <td>{productsList.find(j => j.ref === i.ref)?.title}</td>
                            <td>{i?.ref}</td>
                            <td>{i?.quantity}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default PaymentsOrder