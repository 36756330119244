import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert
} from "react-bootstrap";

import { staticWebImages } from "../../components/Utils/images";


class StartOver extends Component {
  constructor(props){
    super(props);
    this.state = {
      customer:"",
      products:[],
      productsList:[],
      customersList:[],
      delivery:{
        appartment:"",
        city:"",
        country:"",
        name:"",
        phone:"",
        street:"",
        zipCode:""
      },
      error:"",
      errors:{},
      disabled : false
    }
    this._isMounted = false;
  }

  render() {
    console.log('render', this.state)
    return (
      <div className="deposit">
        <section className="Sign_Home waitingMain deposit py-5">
          <span className="custm-angle d-none"><i className="fa fa-angle-left" aria-hidden="true" />
          </span>
          <div className="container-fluid col-md-10 mx-auto">
            <div className="row">
              <div className="col-md-6 col-12 ">
                <div className="mainImgBox">
                  <img src={staticWebImages.startOverBanner} alt srcSet className="img-responsive" />
                </div>
              </div>
              <div className="col-md-6 col-12 ">
                <div className="mainContentBox deposit_main  ">
                  <div className="main-logo">
                    <img src={staticWebImages.logo} alt className="img-responsive center-block web-logo-hide" />
                  </div>
                  <h2 className="main_txt_sign text-center">Unfortunately we do not yet deliver to your country. </h2>
                  <p className="signUpwait_text text-center ">Visit our website <a href className="signOutLink">https://libi.com</a> , and we'll notify you when we come to your area! Thank you for your interest! </p>
                  <div className="form-section form-deposit">
                    <form action className="m-auto">
                      <input type="submit" defaultValue="Start Over" className="form-control custom-btn-web" />
                    </form>
                  </div>
                </div>
                <p className="copyright"> © 2020 Libi&amp;Daughters </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default StartOver;
