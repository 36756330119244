import React, { Component } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import PageLoader from "components/Loader/PageLoader.jsx";
import { emailsListThArray } from "variables/Variables.jsx";
import * as fb from "../../firebase";
// eslint-disable-next-line
import { removeHTMLTags } from "components/Helper/index";

//Datatable Modules
import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { convertFirebseSecondsToDate } from "components/Helper";

class EmailList extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      dataList:[],
      errors:{}
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#list-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });
  }
  
  fetchData = async() => {
    this.setState({isLoading:true});
    const data = await fb.emailsCollection.get();
    const dataArr = [];
    data.docs.forEach(doc=> {
      const dataObj = doc.data();
      dataArr.push({
        id:dataObj.id,
        userId:dataObj.userId,
        body:dataObj.body,
        recipients:dataObj.recipients,
        subject:dataObj.subject,
        created:dataObj.sent ? convertFirebseSecondsToDate(dataObj.sent.seconds) : 'N/A'
      });
    })
    this.setState({
      dataList:dataArr,
      isLoading:false
    });
  }

  deleteTemplate = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await fb.emailsCollection.doc(id).delete().then(() => {
        this.fetchData();
      }).catch(function(error) {
        console.error("Error removing document: ", error);
      });
    }
  }

  render() {
    //console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Emails"
                category=""
                ctTableFullWidth
                ctTableResponsive
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Table striped hover id="list-datatable">
                    <thead>
                      <tr>
                        {emailsListThArray.map((val, key) => {
                          return <th key={key}>{val}</th>;
                        })}
                      </tr>
                    </thead>
                      <tbody>
                        {this.state.dataList.map((val, key) => {
                          return (
                            <tr key={key} id={key}>
                              <td style={{overflowWrap:"anywhere"}}><Link to={`users/view/${val.userId}`}>{val.recipients}</Link></td>
                              <td>{ val.subject }</td>
                              <td>{ val.created }</td>
                              <td>
                                <Link to={`emails/view/${val.id}`}><i className="fa fa-eye" /></Link>&nbsp;&nbsp; 
                                <Link to={`#`} onClick={() => {this.deleteTemplate(val.id)}}><i className="fa fa-times" /></Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EmailList;
