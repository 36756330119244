import React, {useEffect, useState} from "react";
import { 
    Modal, Button,
    Row,
    FormGroup,
    FormLabel,
    Alert
} from "react-bootstrap";
import { Multiselect } from 'multiselect-react-dropdown';
import * as fb from '../../firebase';
import { arrayChunks } from '../Helper/index';
import api from "api";

function AddProductView(props) {
    
    const [title, setTitle] = useState({});
    const [errors, setErrors] = useState({});
    const [disabled, setDisabled] = useState(false);
    const [options, setOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState([])
    const [error, seterror] = useState('')

    const onChangeHandler = (event) => {
        const {name, value} = event.currentTarget;
        if(name === 'title') {
            setTitle(value);
        }
    };

    useEffect(() => {
        async function fetchData() {
            const data = await fb.productsCollection.where('language', "==", props.data.stateData.language)
                .get();
            const products = [];
            data.docs.forEach(doc=> {
                const dataObj = doc.data();
                products.push({
                    id:dataObj.ref,
                    name:dataObj.title
                })
            })
            if(Object.keys(props.data.stateData.products).length !== 0){
                try{
                    let currentDocument = props.data.stateData.products;
                    var result = Object.keys(currentDocument);
                    //make chucks of ref as firebase dones't support more than 10 
                    let productRefChunks = arrayChunks(result);
                    const selectedProducts = [];
                    for(var i=0; i < productRefChunks.length; i++){
                        const data1 = await fb.productsCollection
                        .where('ref', 'in', productRefChunks[i])
                        .where('language', '==', props.data.stateData.language)
                        .get();
                        data1.docs.forEach(doc1=> {
                            const dataObj1 = doc1.data();
                            selectedProducts.push({
                                id:dataObj1.ref,
                                name:dataObj1.title
                            })
                        })
                    }
                    setSelectedOption(selectedProducts)
                }catch(err){
                    console.log('err', err)
                    seterror(err.message)
                }
            }
            setOptions(products)
        }
        fetchData();
    }, []);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setDisabled(true)
        let products = {};
        console.log('selectedOption', selectedOption, props.data.stateData)
        selectedOption.forEach(async (val, key)  => {
            if(props.data.stateData.id){
                const {data: treatmentsByUserId} = await api.get('getTreatmentsByUserId', { params: { userId: props.data.stateData.userId } })
                const treatment = treatmentsByUserId?.find(i => i?.id === props.data.stateData.id)

                if(treatment){
                    let productref = treatment
                    if(productref && productref.products && Object.keys(productref.products).includes(val.id)){ 

                        products[val.id] = {
                            title:productref.products[val.id].title,
                            title_ru:productref.products[val.id].title_ru || "",
                            order:productref.products[val.id].order ? productref.products[val.id].order: "",
                            description:productref.products[val.id].description,
                            description_ru:productref.products[val.id].description_ru || "",
                            rule:productref.products[val.id].rule ? productref.products[val.id].rule: ""
                        };

                    }else{
                        const data = await fb.productsCollection.where('ref', '==', val.id).where('language', "==", "EN").get();

                        const data1 = await fb.productsCollection.where('ref', '==', val.id).where('language', "==", "RU").get();

                        const dataObj1 = data1.docs[0].data();

                        const dataObj = data.docs[0].data();
                        products[dataObj.ref] = {
                            title:dataObj.title,
                            title_ru:dataObj1.title,
                            description:`/0/${dataObj.title} /1/ /2/<img src=${dataObj.picture} /> ${dataObj.description} Instruction: ${dataObj.instruction} /3/`,
                            description_ru:`/0/${dataObj1.title} /1/ /2/<img src=${dataObj1.picture} /> ${dataObj1.description} Инструкция: ${dataObj1.instruction} /3/`,
                            order: Number(dataObj1.order), 
                        };
                    }
                    if(selectedOption.length-1===key){
                        console.log('products', products)
                        await api.post('updateTreatment', {
                            id: props.data.stateData.id,
                            products:products,
                            description:props.data.stateData.description,
                            skinCarePlan:props.data.stateData.skinCarePlan,
                            matched:true,
                            time:fb.timestamp.serverTimestamp()
                        }).then(res => {
                            window.location.reload();
                            // props.onHide();
                        });
                    }
                }
            }else{
                const data = await fb.productsCollection.where('ref', '==', val.id).get();
                const dataObj = data.docs[0].data();
                products[dataObj.ref] = {
                    title:dataObj.title, 
                    description:`/0/${dataObj.title} /1/ /2/<img src=${dataObj.picture} /> ${dataObj.description} Instruction: ${dataObj.instruction} /3/`,
                    order: Number(dataObj.order), 
                };
                if(selectedOption.length-1===key){
                    await api.post('createTreatment', {
                        user_id: props.data.stateData.userId,
                        answerId:props.data.stateData.answerId,
                        templateId:props.data.stateData.templateId,
                        title:props.data.stateData.title,
                        products:products,
                        description:props.data.stateData.description,
                        skinCarePlan:props.data.stateData.skinCarePlan,
                        matched:true,
                        time:fb.timestamp.serverTimestamp()
                    }).then(res => {
                        props.onHide();
                    });
                }
            }
        });
    }

    const onSelect = (selectedList, selectedItem) => {
        setSelectedOption(selectedList)
    }
    
    const onRemove = (selectedList, removedItem) => {
        setSelectedOption(selectedList)
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header closeButton>
                <h4 style={{textAlign:"center"}}><strong>Edit Product</strong></h4>
                { error && 
                    <Alert bsStyle="danger">
                      <span>{error}</span>
                    </Alert>
                }
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <Row>
                        <div className="col-md-12 custom-multi-select">
                            <FormGroup>
                                <FormLabel>Products</FormLabel>
                                    <Multiselect
                                        options={options}
                                        selectedValues={selectedOption}
                                        onSelect={onSelect}
                                        onRemove={onRemove} 
                                        displayValue="name" 
                                        showCheckbox={true}
                                        placeholder="Select Product"
                                    />
                                <span className="help-block">{errors.group}</span>
                            </FormGroup>
                        </div>
                    </Row>
                    <Button 
                        bsStyle="info" 
                        type="submit"
                        disabled={disabled}
                    >
                        {disabled ? 'Loading...' : 'Submit'}
                    </Button>
                    <div className="clearfix" />
                </form>
            </Modal.Body>
        </Modal>
    );
}
  
export default AddProductView;
