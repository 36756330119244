import React, { useState } from 'react'
import logo from '../../../assets/img/logo.png'
import './TypeForm.scss'
import FileUpload from './FileUpload'
import firebase from "firebase/app";
import { storage, usersCollection } from '../../../firebase'

const imgs = [
  'https://images.typeform.com/images/m4Gc3uKmuWLy',
  'https://images.typeform.com/images/yBKwPBv35AUd',
  'https://images.typeform.com/images/VfuHi8mfEBG8',
  'https://images.typeform.com/images/WkkLe6kzznAa',
  'https://images.typeform.com/images/WkkLe6kzznAa',
]

const titles = [
  '1. Please take a photo of your nose, mouth and chin like in the example*',
  '2. Please take a photo, focusing on your left cheek, as  inducated in the photo*',
  '3. Please take a photo focusing on your right cheek, as shows in the picture*',
  '4. Please include a photo of your forehead*',
  'Thank you!',
]

const UploadPhotos = () => {
  const userId = window.location.href.split('/').pop()

  const [currentView, setCurrentView] = useState(0)
  const [file, setFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState('')

  const changeHandler = (file, type) => {
    setFile(file)
  }

  const onSubmit = async () => {
    if(currentView === titles.length - 1) {
      window.location.href = '/'
      return
    }

    setIsUploading(true)
    
    try {
      const fileRef = storage.child(`attachments/${file.name}`)

      await fileRef.put(file)
        .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          const fileObj = {
            name: file.name,
            url,
            time: firebase.firestore.FieldValue.serverTimestamp()
          }

          usersCollection.doc(userId).collection('Attachments').add(fileObj).then(() => {
            setError('')
            setFile(null)
            setCurrentView(prev => prev + 1)
          })
        })
    } catch (err) {
      console.log('err', err)
      setError(err.message)
    } finally {
      setIsUploading(false)
    }
  }

  return (
    <div className="UploadPhotosWrapper" style={{ "--answer-color": "#92001A" }}>
      <div className="UploadPhotosMain">
        <a href="/" className="UploadPhotosLogo">
          <img src={logo} alt="Libi&Daughters" />
        </a>

        <p>{titles[currentView]}</p>

        {currentView < titles.length - 1 &&
          <div className="UploadPhotosInput">
            <FileUpload
              key={currentView}
              onChange={changeHandler}
              onError={setError}
            />
          </div>
        }

        {error && <div className="UploadPhotosError">{error}</div>}

        <button
          type="button"
          className="typeFormWelcomeButton UploadPhotosButton"
          onClick={onSubmit}
          disabled={isUploading}
        >
          {isUploading ? 'Uploading...' : 'Continue'}
        </button>
      </div>
      <img className="UploadPhotosImg" src={imgs[currentView]} alt="Face" />
    </div>
  )
}

export default UploadPhotos