import React, { Component } from "react";
import { Container, Row, Col, Table, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import PageLoader from "components/Loader/PageLoader.jsx";
import { stripeProductListThArray } from "variables/Variables.jsx";
import * as fb from "../../firebase";
//Datatable Modules
import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

class StripeProductList extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      data:[],
      productList:[],
      testProductList:[],
      testProduct:[],
      errors:{}
    }
  }

  componentDidMount = async () => {
    this._isMounted = true;
    await this.fetchData();
    await this.fetchTestData();
    $('#stripe-product-production-datatable').DataTable({
      "bStateSave": true,
      "pageLength": 150
    });
    $('#stripe-product-test-datatable').DataTable({
      "bStateSave": true,
      "pageLength": 150
    });
  }

  fetchData = async() => {
    this.setState({isLoading:true});
    const data = await fb.metaCollection.doc('stripe_products').get();
    if(data.exists){
      let productArr = [];
      Object.keys(data.data()).map((key) => productArr.push({
        key:key,
        title:data.data()[key].title,
        priceId:data.data()[key].priceId
      }));
      //[key, data.data()[key].title, data.data()[key].priceId]);
      // console.log('a', a)
      this.setState({
        data:data.data(),
        isLoading:false,
        productList:productArr
      });
    }else{
      this.setState({
        isLoading:false
      });
    }
  }

  fetchTestData = async() => {
    this.setState({isLoading:true});
    const data = await fb.metaCollection.doc('stripe_products_test').get();
    if(data.exists){
      let productArr = [];
      Object.keys(data.data()).map((key) => productArr.push({
        key:key,
        title:data.data()[key].title,
        priceId:data.data()[key].priceId
      }));
      this.setState({
        testProduct:data.data(),
        isLoading:false,
        testProductList:productArr
      });
    }else{
      this.setState({
        isLoading:false
      });
    }
  }

  deleteItem = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      const { data } = { ...this.state };
      const currentState = data;
      delete currentState[id];
      this.setState({
        data:currentState,
      });
      let data1= this.state.data;
      await fb.metaCollection.doc('stripe_products').set(data1).then(snapShot => {
        this.fetchData();
      }).catch(err=> {
        this.setState({
          error:`Something went wrong-${err}`,
          disabled: false
        });
      });
    }
  }

  deleteTestItem = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      const { testProduct } = { ...this.state };
      const currentState = testProduct;
      delete currentState[id];
      this.setState({
        testProduct:currentState,
      });
      let data1= this.state.testProduct;
      await fb.metaCollection.doc('stripe_products_test').set(data1).then(snapShot => {
        this.fetchData();
      }).catch(err=> {
        this.setState({
          error:`Something went wrong-${err}`,
          disabled: false
        });
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    // console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Stripe Products"
                category="All Stripe products"
                ctTableFullWidth
                ctTableResponsive
                button={
                  <Link to={`stripe-products/add`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"> Add Stripe Product</Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Tabs defaultActiveKey="stripe-product-production" id="uncontrolled-tab-example">
                    <Tab eventKey="stripe-product-production" title="Production">
                      <Table striped hover id="stripe-product-production-datatable">
                        <thead>
                          <tr>
                            {stripeProductListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                          <tbody>
                            {this.state.productList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val.key}</td>
                                  <td>{val.title}</td>
                                  <td>{val.priceId}</td>
                                  <td>
                                    <Link to={`stripe-products/edit/${val.key}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp; 
                                    <Link to={`#`} onClick={() => {this.deleteItem(val.key)}}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody> 
                      </Table>
                    </Tab>
                    <Tab eventKey="stripe-product-test" title="Test">
                      <Table striped hover id="stripe-product-test-datatable">
                        <thead>
                          <tr>
                            {stripeProductListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                          <tbody>
                            {this.state.testProductList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val.key}</td>
                                  <td>{val.title}</td>
                                  <td>{val.priceId}</td>
                                  <td>
                                    <Link to={`stripe-products/edit-test/${val.key}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp; 
                                    <Link to={`#`} onClick={() => {this.deleteTestItem(val.key)}}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody> 
                      </Table>
                    </Tab>
                  </Tabs>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default StripeProductList;
