import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Tab,
  Tabs
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import PageLoader from "components/Loader/PageLoader.jsx";
import { metaListThArray } from "variables/Variables.jsx";
import * as fb from "../../firebase";
//Datatable Modules
import $ from 'jquery';
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import EditShipping from "./EditShipping";
import {
  removeHTMLTags
} from "components/Helper/index";

class MetaList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      data: [],
      notificationList: [],
      notification: [],
      instructionList: [],
      instruction: [],
      stripeKeyList: [],
      stripeKey: [],
      questionnaireList: [],
      questionnaire: [],
      questionnaire_newList: [],
      questionnaire_new: [],
      dataList: [],
      shipment: "",
      oneDay: 86400,
      referralList: [],
      referral: [],
      portalList: [],
      portal: [],
      errors: {},
      ru_data: [],
      ru_dataList: [],
      ru_notificationList: [],
      ru_notification: [],
      ru_questionnaireList: [],
      ru_questionnaire: [],
      ru_instructionList: [],
      ru_instruction: [],
      ru_portalList: [],
      ru_portal: [],
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#app-text-datatable').DataTable({
      "bStateSave": true
    });
    $('#app-text-notification-datatable').DataTable({
      "bStateSave": true
    });
    $('#app-text-instruction-datatable').DataTable({
      "bStateSave": true
    });
    $('#app-text-questionnaire-datatable').DataTable({
      "bStateSave": true
    });
    $('#app-text-questionnaire_new-datatable').DataTable({
      "bStateSave": true
    });
    $('#shipping-datatable').DataTable({
      "bStateSave": true
    });
    $('#app-text-stripe-keys-datatable').DataTable({
      "bStateSave": true
    });
    $('#referral-datatable').DataTable({
      "bStateSave": true
    });
    $('#portal-datatable').DataTable({
      "bStateSave": true
    });

    //ru
    $('#ru-app-text-datatable').DataTable({
      "bStateSave": true
    });
    $('#ru_app-text-notification-datatable').DataTable({
      "bStateSave": true
    });
    $('#ru_app-text-instruction-datatable').DataTable({
      "bStateSave": true
    });
    $('#ru_app-text-questionnaire-datatable').DataTable({
      "bStateSave": true
    });
    $('#ru_portal-datatable').DataTable({
      "bStateSave": true
    });
  }


  fetchData = async () => {
    this.setState({ isLoading: true });
    //app text
    const txt = await fb.metaCollection.doc('txt').get();
    Object.keys(txt.data()).map((key) => [Number(key), txt.data()[key]]);

    //app notification text
    const notification = await fb.metaCollection.doc('notifications_txt').get();
    Object.keys(notification.data()).map((key) => [Number(key), notification.data()[key]]);

    const instruction = await fb.metaCollection.doc('instruction_txt').get();
    Object.keys(instruction.data()).map((key) => [Number(key), instruction.data()[key]]);

    //app questionnaire text
    const questionnaire = await fb.metaCollection.doc('questionnaire').get();
    Object.keys(questionnaire.data()).map((key) => [Number(key), questionnaire.data()[key]]);

    //app questionnaire text
    const questionnaire_new = await fb.metaCollection.doc('questionnaire_new').get();
    Object.keys(questionnaire_new.data()).map((key) => [Number(key), questionnaire_new.data()[key]]);

    //app shipment text
    const shipment = await fb.metaCollection.doc('shipment').get();

    //stripe key text
    const stripe = await fb.metaCollection.doc('stripe_keys').get();
    Object.keys(stripe.data()).map((key) => [Number(key), stripe.data()[key]]);

    //referral key text
    const referral = await fb.metaCollection.doc('referral').get();
    Object.keys(referral.data()).map((key) => [Number(key), referral.data()[key]]);

    //portal key text
    const portal = await fb.metaCollection.doc('portal_txt').get();
    Object.keys(portal.data()).map((key) => [Number(key), portal.data()[key]]);

    //RU
    const txt_ru = await fb.metaCollection.doc('txt_ru').get();
    //app notification text
    const notification_ru = await fb.metaCollection.doc('notifications_txt_ru').get();

    const instruction_ru = await fb.metaCollection.doc('instruction_txt_ru').get();

    //app questionnaire text
    const questionnaire_ru = await fb.metaCollection.doc('questionnaire_ru').get();

    //portal key text
    const portal_ru = await fb.metaCollection.doc('portal_txt_ru').get();

    this.setState({
      data: txt.data(),
      dataList: Object.keys(txt.data()),
      notificationList: Object.keys(notification.data()),
      notification: notification.data(),
      instructionList: Object.keys(instruction.data()),
      instruction: instruction.data(),
      stripeKeyList: Object.keys(stripe.data()),
      stripeKey: stripe.data(),
      questionnaireList: Object.keys(questionnaire.data()),
      questionnaire: questionnaire.data(),
      questionnaire_newList: Object.keys(questionnaire_new.data()),
      questionnaire_new: questionnaire_new.data(),
      shipment: shipment.data(),
      referralList: Object.keys(referral.data()),
      referral: referral.data(),
      portalList: Object.keys(portal.data()),
      portal: portal.data(),
      isLoading: false,

      ru_data: txt_ru.data(),
      ru_dataList: Object.keys(txt_ru.data()),
      ru_notificationList: Object.keys(notification_ru.data()),
      ru_notification: notification_ru.data(),
      ru_instructionList: Object.keys(instruction_ru.data()),
      ru_instruction: instruction_ru.data(),
      ru_questionnaireList: Object.keys(questionnaire_ru.data()),
      ru_questionnaire: questionnaire_ru.data(),
      ru_portalList: Object.keys(portal_ru.data()),
      ru_portal: portal_ru.data(),
    });
  }

  deleteItem = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      const { data } = { ...this.state };
      const currentState = data;
      delete currentState[id];
      this.setState({
        data: currentState,
      });
      let data1 = this.state.data;
      await fb.metaCollection.doc('txt').set(data1).then(snapShot => {
        // this.fetchData();
        window.location.reload();
      }).catch(err => {
        this.setState({
          error: `Something went wrong-${err}`,
          disabled: false
        });
      });
    }
  }

  deleteNotification = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      const { notification } = { ...this.state };
      const currentState = notification;
      delete currentState[id];
      this.setState({
        notification: currentState,
      });
      let data1 = this.state.notification;
      await fb.metaCollection.doc('notifications_txt').set(data1).then(snapShot => {
        // this.fetchData();
        window.location.reload();
      }).catch(err => {
        this.setState({
          error: `Something went wrong-${err}`,
          disabled: false
        });
      });
    }
  }

  deleteStripeKey = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      const { stripeKey } = { ...this.state };
      const currentState = stripeKey;
      delete currentState[id];
      this.setState({
        stripeKey: currentState,
      });
      let data1 = this.state.stripeKey;
      await fb.metaCollection.doc('stripe_keys').set(data1).then(snapShot => {
        window.location.reload();
      }).catch(err => {
        this.setState({
          error: `Something went wrong-${err}`,
          disabled: false
        });
      });
    }
  }

  deleteQuestionnaire = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      const { questionnaire } = { ...this.state };
      const currentState = questionnaire;
      delete currentState[id];
      this.setState({
        questionnaire: currentState,
      });
      let data1 = this.state.questionnaire;
      await fb.metaCollection.doc('questionnaire').set(data1).then(snapShot => {
        // this.fetchData();
        window.location.reload();
      }).catch(err => {
        this.setState({
          error: `Something went wrong-${err}`,
          disabled: false
        });
      });
    }
  }

  deleteInstruction = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      const { instruction, ru_instruction } = { ...this.state };
      delete instruction[id];
      delete ru_instruction[id];
      this.setState({
        instruction: instruction,
        ru_instruction: ru_instruction
      });
      await fb.metaCollection.doc('instruction_txt').update({
        [id]: fb.firebase.firestore.FieldValue.delete()
      }).then(snapShot => {
        // this.fetchData();
        // window.location.reload();
      }).catch(err => {
        this.setState({
          error: `Something went wrong-${err}`,
          disabled: false
        });
      });
      await fb.metaCollection.doc('instruction_txt_ru').update({
        [id]: fb.firebase.firestore.FieldValue.delete()
      }).then(snapShot => {
        // this.fetchData();
        window.location.reload();
      }).catch(err => {
        this.setState({
          error: `Something went wrong-${err}`,
          disabled: false
        });
      });
    }
  }

  deleteNewQuestionnaire = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      const { questionnaire_new } = { ...this.state };
      const currentState = questionnaire_new;
      delete currentState[id];
      this.setState({
        questionnaire_new: currentState,
      });
      let data1 = this.state.questionnaire_new;
      await fb.metaCollection.doc('questionnaire_new').set(data1).then(snapShot => {
        // this.fetchData();
        window.location.reload();
      }).catch(err => {
        this.setState({
          error: `Something went wrong-${err}`,
          disabled: false
        });
      });
    }
  }

  deleteReferral = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      const { referral } = { ...this.state };
      const currentState = referral;
      delete currentState[id];
      this.setState({
        referral: currentState,
      });
      let data = this.state.referral;
      await fb.metaCollection.doc('referral').set(data).then(snapShot => {
        window.location.reload();
      }).catch(err => {
        this.setState({
          error: `Something went wrong-${err}`,
          disabled: false
        });
      });
    }
  }

  deletePortal = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      const { portal, ru_portal } = { ...this.state };
      delete portal[id];
      delete ru_portal[id];
      this.setState({
        portal: portal,
        ru_portal: ru_portal
      });
      await fb.metaCollection.doc('portal_txt').update({
        [id]: fb.firebase.firestore.FieldValue.delete()
      }).then(snapShot => {
        // this.fetchData();
        // window.location.reload();
      }).catch(err => {
        this.setState({
          error: `Something went wrong-${err}`,
          disabled: false
        });
      });
      await fb.metaCollection.doc('portal_txt_ru').update({
        [id]: fb.firebase.firestore.FieldValue.delete()
      }).then(snapShot => {
        // this.fetchData();
        window.location.reload();
      }).catch(err => {
        this.setState({
          error: `Something went wrong-${err}`,
          disabled: false
        });
      });
    }
  }

  render() {
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title=""
                category=""
                ctTableFullWidth
                ctTableResponsive
                button={
                  <Link to={`meta/add`} className="btn btn-success libi-admin-btn"> Add Meta</Link>
                }
                loader={this.state.isLoading && <PageLoader />}
                content={
                  <div>
                    <h4><b>EN</b></h4>
                    <Tabs defaultActiveKey="app-text" id="uncontrolled-tab-example">
                      <Tab eventKey="app-text" title="App Text">
                        <Table striped hover id="app-text-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.dataList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{this.state.data[val]}</td>
                                  <td>
                                    <Link to={`meta/edit-image/${val}?doc=text`}><i className="fa fa-eye" /></Link>
                                    <Link to={`meta/edit/txt/${val}?lng=en`}><i className="fa fa-edit" /></Link>
                                    <Link to={`#`} onClick={() => { this.deleteItem(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="app-text-notification" title="Notifications">
                        <Table striped hover id="app-text-notification-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.notificationList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{this.state.notification[val]}</td>
                                  <td>
                                    <Link to={`meta/edit-image/${val}?doc=notification`}><i className="fa fa-eye" /></Link>
                                    <Link to={`meta/edit/notifications_txt/${val}?lng=en`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deleteNotification(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="app-text-questionnaire" title="Questionnaire">
                        <Table striped hover id="app-text-questionnaire-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.questionnaireList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{val === "weeklyDays" ? this.state.questionnaire[val].join() : removeHTMLTags(this.state.questionnaire[val], 100)}</td>
                                  <td>
                                    <Link to={`meta/edit/questionnaire/${val}?lng=en`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deleteQuestionnaire(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="app-text-instruction" title="Instruction">
                        <Table striped hover id="app-text-instruction-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.instructionList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{this.state.instruction[val]}</td>
                                  <td>
                                    <Link to={`meta/edit/instruction_txt/${val}?lng=en`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deleteInstruction(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="app-text-questionnaire_new" title="Questionnaire New">
                        <Table striped hover id="app-text-questionnaire_new-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.questionnaire_newList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{val === "weeklyDays" ? this.state.questionnaire_new[val].join() : removeHTMLTags(this.state.questionnaire_new[val], 100)}</td>
                                  <td>
                                    <Link to={`meta/edit-questionnaire-new/${val}?lng=en`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deleteQuestionnaire(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="shipping" title="Shipping">
                        <Table striped hover id="shipping-datatable">
                          <thead>
                            <tr>
                              <th>Package Due Shift</th>
                              <th>Ship By Shift</th>
                              <th>Shipment After Days</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{this.state.shipment.package_due_shift && this.state.shipment.package_due_shift / this.state.oneDay}</td>
                              <td>{this.state.shipment.ship_by_shift && this.state.shipment.ship_by_shift / this.state.oneDay}</td>
                              <td>{this.state.shipment.shipmentAfterDays}</td>
                              <td>
                                <Link to={`meta/edit-shipping/${1}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="app-text-stripe-keys" title="Stripe Keys">
                        <Table striped hover id="app-text-stripe-keys-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.stripeKeyList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{this.state.stripeKey[val]}</td>
                                  <td>
                                    <Link to={`meta/edit-stripe-key/${val}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deleteStripeKey(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="referral" title="Referral">
                        <Table striped hover id="referral-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.referralList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{this.state.referral[val]}</td>
                                  <td>
                                    <Link to={`meta/edit-referral/${val}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deleteReferral(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="portal" title="Portal">
                        <Table striped hover id="portal-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.portalList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{this.state.portal[val]}</td>
                                  <td>
                                    <Link to={`meta/edit/portal_txt/${val}?lng=en`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deletePortal(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                    <h4><b>RU</b></h4>
                    <Tabs defaultActiveKey="ru-app-text" id="uncontrolled-tab-example">
                      <Tab eventKey="ru-app-text" title="App Text">
                        <Table striped hover id="ru-app-text-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ru_dataList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{this.state.ru_data[val]}</td>
                                  <td>
                                    <Link to={`meta/edit-image/${val}?doc=text`}><i className="fa fa-eye" /></Link>
                                    <Link to={`meta/edit/txt/${val}?lng=ru`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deleteItem(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="ru_app-text-notification" title="Notifications">
                        <Table striped hover id="ru_app-text-notification-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ru_notificationList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{this.state.ru_notification[val]}</td>
                                  <td>
                                    <Link to={`meta/edit-image/${val}?doc=notification`}><i className="fa fa-eye" /></Link>
                                    <Link to={`meta/edit/notifications_txt/${val}?lng=ru`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deleteNotification(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="ru_app-text-questionnaire" title="Questionnaire">
                        <Table striped hover id="ru_app-text-questionnaire-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ru_questionnaireList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{val === "weeklyDays" ? this.state.ru_questionnaire[val].join() : removeHTMLTags(this.state.ru_questionnaire[val], 100)}</td>
                                  <td>
                                    <Link to={`meta/edit/questionnaire/${val}?lng=ru`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deleteQuestionnaire(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="ru_app-text-instruction" title="Instruction">
                        <Table striped hover id="ru_app-text-instruction-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ru_instructionList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{this.state.ru_instruction[val]}</td>
                                  <td>
                                    <Link to={`meta/edit/instruction_txt/${val}?lng=ru`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deleteInstruction(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                      <Tab eventKey="ru_portal" title="Portal">
                        <Table striped hover id="ru_portal-datatable">
                          <thead>
                            <tr>
                              {metaListThArray.map((val, key) => {
                                return <th key={key}>{val}</th>;
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ru_portalList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{val}</td>
                                  <td>{this.state.ru_portal[val]}</td>
                                  <td>
                                    <Link to={`meta/edit/portal_txt/${val}?lng=ru`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp;
                                    <Link to={`#`} onClick={() => { this.deletePortal(val) }}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Tab>
                    </Tabs>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}


export default MetaList;
