import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert,
  Tab, 
  Tabs
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from "react-router-dom";
import './Template.css';
import UploadAdapter from '../../components/image-adaptor/UploadAdapter';
import {
  addAdminLog,
  replaceDiagnosisTags
} from "components/Helper/index";
import {Multiselect} from "multiselect-react-dropdown";

class AddTemplate extends Component {
  constructor(props){
    super(props);
    this.state = {
      ref:"",
      skinCarePlan:"",
      title:"",
      description:"",
      skinCarePlan_ru:"",
      title_ru:"",
      description_ru:"",
      template_type:"simple_results",
      groupId:"",
      groupUIId:"",
      mustacheCardGroups: [],
      mustacheUIGroups: [],
      disabled : false,
      selectedGroupId: [],
      recommend: '',
      error:"",
      errors:{},
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    console.log(name, value)
    if(name === 'ref') {
      this.setState({ref:value});
    }else if(name === 'template_type') {
      this.setState({template_type:value});
    }else if(name === 'recommend') {
      this.setState({recommend:value});
    }else if(name === 'groupUIId') {
      this.setState({groupUIId:value});
    }else if(name === 'title') {
      this.setState({title:value});
    }else if(name === 'title_ru') {
      this.setState({title_ru:value});
    }
  };

  onChangeGroupIdHandler = (value) => {
    this.setState({selectedGroupId:value})
    let groupId = value.map(el => el.groupId).join(',')
    this.setState({groupId:groupId})
  }

  onChangeHandlerDescription = (value) => {
    this.setState({description:value});
  };

  onChangeHandlerskinCarePlan = (value) => {
    this.setState({skinCarePlan:value});
  };

  onChangeHandlerDescriptionRu = (value) => {
    this.setState({description_ru:value});
  };

  onChangeHandlerskinCarePlanRu = (value) => {
    this.setState({skinCarePlan_ru:value});
  };

  componentDidMount = async () => {
    await fb.metaCollection.doc('mustachescard_groups').get().then(res => {
      const data = res.data();
      if(data.hasOwnProperty('groups')){
        this.setState({
          mustacheCardGroups: data.groups
        })
      }
    });
    await fb.metaCollection.doc('mustaches_ui_groups').get().then(res => {
      const data = res.data();
      if(data.hasOwnProperty('groups')){
        this.setState({
          mustacheUIGroups: data.groups
        })
      }
    });
  }

  handleValidation = () => {
    let {ref, title, description, title_ru, description_ru} = this.state;
    let errors = {};
    let formIsValid = true;

    //ref
    if(!ref){
      formIsValid = false;
      errors["ref"] = "Ref is required";
    }else if(ref && ref.includes('.')){
      formIsValid = false;
      errors["ref"] = "Please don't use [.] character for ref";
    }

    //Title
    if(!title){
      formIsValid = false;
      errors["title"] = "Subject is required";
    }

    //Description
    if(!description){
      formIsValid = false;
      errors["description"] = "Result is required";
    }

    //Title
    if(!title_ru){
      formIsValid = false;
      errors["title_ru"] = "Subject ru is required";
    }

    //Description
    if(!description_ru){
      formIsValid = false;
      errors["description_ru"] = "Result ru is required";
    }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({disabled: true}); //disable button
    if(this.handleValidation(this.state.errors)){
      let docRef = fb.mustachesCollection.doc();
      await docRef.set({
        id:docRef.id,
        ref:this.state.ref,
        title:this.state.title,
        type:this.state.template_type,
        groupId: this.state.groupId,
        groupUIId: this.state.template_type.includes('simple') ? this.state.groupUIId : '',
        description:replaceDiagnosisTags(this.state.description),
        title_ru:this.state.title_ru,
        description_ru:replaceDiagnosisTags(this.state.description_ru),
        created:fb.timestamp.serverTimestamp(),
        recommend: ['recommendation','procedure'].includes(this.state.template_type) ? this.state.recommend === 'true' : ''
      }).then(spanShot => {
        addAdminLog('template_created',
          `Created the template ${docRef.id}`)
        this.props.history.push('/admin/template/new')
      }).catch(err => {
        this.setState({
          error:`Something went wrong-${err}`,
          disabled: false
        });
      });
    }else{
      this.setState({
        disabled: false
      });
    }
  }
  
  render() {
    console.log('state', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.error}</span>
                </Alert>
              }
              {Object.keys(this.state.errors).length > 0 &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>All fields are required for EN and RU</span>
                </Alert>
              }
              <Card
                title="Add M-Templates"
                button={
                  <Link to={`/admin/template/new`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                  <Button
                    className="btn-fill pull-right libi-admin-btn"
                    bsStyle="info" 
                    pullRight fill 
                    type="submit"
                    disabled={this.state.disabled}
                    >
                    {this.state.disabled ? 'Loading...' : 'Save'}
                  </Button>
                  <div className="clearfix" />
                  <Row>
                    <div className="col-md-12">
                      <FormGroup>
                        <FormLabel>Select template type</FormLabel>
                        {Object.entries({
                          simple_results: 'Simple Results',
                          simple_skincare: 'Simple Skincare',
                          card_results: 'Card Results',
                          card_skincare: 'Card Skincare',
                          recommendation: 'Recommendation',
                          procedure: 'Procedure'
                        }).map((val, key) => {
                          return <span key={key}><input
                            type="radio"
                            id={val[0]}
                            name="template_type"
                            value={val[0]}
                            checked={this.state.template_type === val[0]}
                            onChange={(event) => this.onChangeHandler(event)}
                          /> <label htmlFor={val[0]} style={{marginRight:'10px'}} className="template_type">{val[1]}</label></span>
                        })}
                        <span className="help-block">{this.state.errors.template_type}</span>
                      </FormGroup>
                    </div>
                  </Row>
                  {['recommendation','procedure'].includes(this.state.template_type) &&
                  <Row>
                    <div className="col-md-12">
                      <FormGroup>
                        <FormLabel>Recommend</FormLabel>
                        <select
                          className="form-control"
                          name="recommend"
                          value={this.state.recommend}
                          onChange={(event) => this.onChangeHandler(event)}
                        >
                          <option value="">Select recommend</option>
                          <option value={true}>Do</option>
                          <option value={false}>Don't</option>
                        </select>
                        <span className="help-block">{this.state.errors.recommend}</span>
                      </FormGroup>
                    </div>
                  </Row>
                  }
                  {this.state.template_type.includes('simple') &&
                    <Row>
                      <div className="col-md-12">
                        <FormGroup>
                          <FormLabel>Group UI id</FormLabel>
                          <select
                            className="form-control"
                            name="groupUIId"
                            value={this.state.groupUIId}
                            onChange={(event) => this.onChangeHandler(event)}
                          >
                            <option value="">Select UI group</option>
                            {
                              this.state.mustacheUIGroups.map((group, index) => {
                                return <option key={index} value={group.groupId}>{group.groupName}</option>
                              })
                            }
                          </select>
                          <span className="help-block">{this.state.errors.groupUIId}</span>
                        </FormGroup>
                      </div>
                    </Row>
                  }
                  <Row>
                    <div className="col-md-12">
                      <FormGroup>
                        <FormLabel>Group id</FormLabel>
                        <Multiselect
                          options={this.state.mustacheCardGroups}
                          selectedValues={this.state.selectedGroupId}
                          onSelect={(event)=>this.onChangeGroupIdHandler(event)}
                          onRemove={(event)=>this.onChangeGroupIdHandler(event)}
                          displayValue="groupName"
                          placeholder="Select group"
                        />
                        <span className="help-block">{this.state.errors.groupId}</span>
                      </FormGroup>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-12">
                      <FormGroup>
                        <FormLabel>Ref</FormLabel>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ref"
                          name="ref"
                          value= {this.state.ref}
                          onChange = {(event) => this.onChangeHandler(event)}
                        />
                        <span className="help-block">{this.state.errors.ref}</span>
                      </FormGroup>
                    </div>
                  </Row>
                  <Tabs defaultActiveKey="en" id="uncontrolled-tab-example">
                    <Tab eventKey="en" title="EN">
                      <Row>
                        <div className="col-md-12">
                          <FormGroup controlId="formControlsTextarea">
                            <FormLabel>Title</FormLabel>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              name="title"
                              value= {this.state.title}
                              onChange = {(event) => this.onChangeHandler(event)}
                            />
                            <span className="help-block">{this.state.errors.title}</span>
                          </FormGroup>
                        </div>
                        </Row>
                        <Row>
                          <div className="col-md-12">
                            <FormGroup controlId="formControlsTextarea">
                              <FormLabel>Body</FormLabel>
                              <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.description}
                                onChange={ ( event, editor ) => this.onChangeHandlerDescription(editor.getData())}
                                onReady={(editor) => {
                                  editor.plugins.get('FileRepository')
                                  .createUploadAdapter = (loader) => {
                                    return new UploadAdapter(loader)
                                  }
                                }}
                              />
                              <span className="help-block">{this.state.errors.description}</span>
                            </FormGroup>
                          </div>
                        </Row>
                      </Tab>
                      <Tab eventKey="ru" title="RU">
                        <Row>
                        <div className="col-md-12">
                          <FormGroup controlId="formControlsTextarea">
                            <FormLabel>Title</FormLabel>
                            <input
                              type="text"
                              className="form-control"
                              placeholder=""
                              name="title_ru"
                              value= {this.state.title_ru}
                              onChange = {(event) => this.onChangeHandler(event)}
                            />
                            <span className="help-block">{this.state.errors.title_ru}</span>
                          </FormGroup>
                        </div>
                        </Row>
                        <Row>
                          <div className="col-md-12">
                            <FormGroup controlId="formControlsTextarea">
                              <FormLabel>Body</FormLabel>
                              <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.description_ru}
                                onChange={ ( event, editor ) => this.onChangeHandlerDescriptionRu(editor.getData())}
                                onReady={(editor) => {
                                  editor.plugins.get('FileRepository')
                                  .createUploadAdapter = (loader) => {
                                    return new UploadAdapter(loader)
                                  }
                                }}
                              />
                              <span className="help-block">{this.state.errors.description_ru}</span>
                            </FormGroup>
                          </div>
                        </Row>
                      </Tab>
                    </Tabs>
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AddTemplate;
