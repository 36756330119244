import React, { Component } from "react";
import { Container, Row, Col, Table, Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "components/Card/Card.jsx";
import PageLoader from "components/Loader/PageLoader.jsx";
import { templateListThArray } from "variables/Variables.jsx";
import * as fb from "../../firebase";
import {
  removeHTMLTags
} from "components/Helper/index";

//Datatable Modules
import $ from 'jquery'; 
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { convertFirebseSecondsToDate } from "components/Helper";

class TemplateList extends Component {
  constructor(props){
    super(props)
    this.state = {
      isLoading:false,
      dataList:[],
      errors:{}
    }
  }

  componentDidMount = async () => {
    await this.fetchData();
    $('#en-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });
    $('#ru-datatable').DataTable({
      "bStateSave": true,
      "order": [[ 0, "desc" ]]
    });
  }
  
  fetchData = async() => {
    const dataArr = [];
    this.setState({isLoading:true});
    const data = await fb.templatesCollection.get();
    data.docs.forEach(doc=> {
      const dataObj = doc.data();
      dataArr.push({
        id:dataObj.id,
        ref:dataObj.ref,
        title:dataObj.title,
        description:dataObj.description,
        title_ru:dataObj.title_ru || "",
        description_ru:dataObj.description_ru || "",
        products:dataObj.products,
        created:convertFirebseSecondsToDate(dataObj.created.seconds)
      });
    })
    this.setState({
      dataList:dataArr,
      isLoading:false
    });
  }

  deleteTemplate = async (id) => {
    if (window.confirm("Do you want to delete the item?")) {
      await fb.templatesCollection.doc(id).delete().then(() => {
        this.fetchData();
      }).catch(function(error) {
        console.error("Error removing document: ", error);
      });
    }
  }

  render() {
    //console.log('render', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              <Card
                title="Templates"
                category="Email templates: structure of information which will be sent to users by emails and also will be available in the mobile app"
                ctTableFullWidth
                ctTableResponsive
                button={
                  <Link to={`template/add`} className="btn btn-success custom-btn pull-right btn-right libi-admin-btn"> Add Template</Link>
                }
                loader={ this.state.isLoading && <PageLoader />}
                content={
                  <Tabs defaultActiveKey="en" id="uncontrolled-tab-example">
                    <Tab eventKey="en" title="EN">
                      <Table striped hover id="en-datatable">
                        <thead>
                          <tr>
                            {templateListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                          <tbody>
                            {this.state.dataList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{ val.ref }</td>
                                  <td>{ removeHTMLTags(val.title, 50) }</td>
                                  <td>{ removeHTMLTags(val.description, 50)}...</td>
                                  <td>
                                    <Link to={`template/edit/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp; 
                                    <Link to={`#`} onClick={() => {this.deleteTemplate(val.id)}}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                      </Table>
                    </Tab>
                    <Tab eventKey="ru" title="RU">
                      <Table striped hover id="ru-datatable">
                        <thead>
                          <tr>
                            {templateListThArray.map((val, key) => {
                              return <th key={key}>{val}</th>;
                            })}
                          </tr>
                        </thead>
                          <tbody>
                            {this.state.dataList.map((val, key) => {
                              return (
                                <tr key={key} id={key}>
                                  <td>{ val.ref }</td>
                                  <td>{ removeHTMLTags(val.title_ru, 50) }</td>
                                  <td>{ removeHTMLTags(val.description_ru, 50)}...</td>
                                  <td>
                                    <Link to={`template/edit/${val.id}`}><i className="fa fa-edit" /></Link>&nbsp;&nbsp; 
                                    <Link to={`#`} onClick={() => {this.deleteTemplate(val.id)}}><i className="fa fa-times" /></Link>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                      </Table>
                    </Tab>
                  </Tabs>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default TemplateList;
