import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Alert,
  Tab, 
  Tabs
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import * as fb from '../../firebase';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link } from "react-router-dom";
import './Template.css';
import UploadAdapter from '../../components/image-adaptor/UploadAdapter';
import {
  replaceDiagnosisTags
} from "components/Helper/index";

class AddTemplate extends Component {
  constructor(props){
    super(props);
    this.state = {
      ref:"",
      skinCarePlan:"",
      title:"",
      description:"",
      skinCarePlan_ru:"",
      title_ru:"",
      description_ru:"",
      template_type:"assesment",
      products:[],
      disabled : false,
      error:"",
      errors:{},
    }
  }

  onChangeHandler = (event) => {
    const {name, value} = event.currentTarget;
    console.log(name, value)
    if(name === 'ref') {
      this.setState({ref:value});
    }else if(name === 'template_type') {
      this.setState({template_type:value}); 
    }
  };

  onChangeHandlerTitle = (value) => {
    this.setState({title:value});
  };

  onChangeHandlerDescription = (value) => {
    this.setState({description:value});
  };

  onChangeHandlerskinCarePlan = (value) => {
    this.setState({skinCarePlan:value});
  };

  onChangeHandlerTitleRu = (value) => {
    this.setState({title_ru:value});
  };

  onChangeHandlerDescriptionRu = (value) => {
    this.setState({description_ru:value});
  };

  onChangeHandlerskinCarePlanRu = (value) => {
    this.setState({skinCarePlan_ru:value});
  };

  handleValidation = () => {
    let {ref, title, description, title_ru, description_ru} = this.state;
    let errors = {};
    let formIsValid = true;

    //Title
    if(!ref){
      formIsValid = false;
      errors["ref"] = "Ref is required";
    }


    //Title
    if(!title){
      formIsValid = false;
      errors["title"] = "Subject is required";
    }

    //Description
    if(!description){
      formIsValid = false;
      errors["description"] = "Result is required";
    }

    //Title
    // if(!skinCarePlan){
    //   formIsValid = false;
    //   errors["skinCarePlan"] = "Skin care plan is required";
    // }

    //Title
    if(!title_ru){
      formIsValid = false;
      errors["title_ru"] = "Subject ru is required";
    }

    //Description
    if(!description_ru){
      formIsValid = false;
      errors["description_ru"] = "Result ru is required";
    }

    //Title
    // if(!skinCarePlan_ru){
    //   formIsValid = false;
    //   errors["skinCarePlan_ru"] = "Skin care plan ru is required";
    // }

    this.setState({errors: errors});
    return formIsValid;
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({disabled: true}); //disable button
    if(this.handleValidation(this.state.errors)){
      let docRef = fb.templatesCollection.doc();
      await docRef.set({
        id:docRef.id,
        ref:this.state.ref,
        title:this.state.title,
        skinCarePlan:replaceDiagnosisTags(this.state.skinCarePlan),
        description:replaceDiagnosisTags(this.state.description),
        title_ru:this.state.title_ru,
        skinCarePlan_ru:replaceDiagnosisTags(this.state.skinCarePlan_ru),
        description_ru:replaceDiagnosisTags(this.state.description_ru),
        created:fb.timestamp.serverTimestamp()
      }).then(spanShot => {
        this.props.history.push('/admin/template')
      }).catch(err => {
        this.setState({
          error:`Something went wrong-${err}`,
          disabled: false
        });
      });
    }else{
      this.setState({
        disabled: false
      });
    }
  }
  
  render() {
    console.log('state', this.state)
    return (
      <div className="content">
        <Container fluid>
          <Row>
            <Col md={12}>
              {this.state.error &&
                <Alert bsStyle="danger">
                  <button type="button" aria-hidden="true" className="close">
                    &#x2715;
                  </button>
                  <span>{this.state.error}</span>
                </Alert>
              }
              <Card
                title="Add Template"
                button={
                  <Link to={`/admin/template`} className="btn btn-success pull-left card-back-button libi-admin-btn"> Back</Link>
                }
                content={
                  <form onSubmit={this.handleSubmit}>
                  <Button
                    className="btn-fill pull-right libi-admin-btn"
                    bsStyle="info" 
                    pullRight fill 
                    type="submit"
                    disabled={this.state.disabled}
                    >
                    {this.state.disabled ? 'Loading...' : 'Save'}
                  </Button>
                  <div className="clearfix" />
                  <Row>
                    <div className="col-md-12">
                      <FormGroup>
                        <FormLabel>Select template type</FormLabel>
                        <input
                          type="radio"
                          className="mr-5"
                          id="assesment"
                          name="template_type"
                          value="assesment"
                          checked={this.state.template_type === "assesment"}
                          onChange={(event)=>this.onChangeHandler(event)}
                        /> <label htmlFor="assesment" className="template_type">Assesment</label>
                        <input
                          type="radio"
                          className="radio-additional"
                          id="additional"
                          name="template_type"
                          value="additional"
                          checked={this.state.template_type === "additional"}
                          onChange={(event)=>this.onChangeHandler(event)}
                        /> <label htmlFor="additional" className="template_type">Additional</label>
                        <span className="help-block">{this.state.errors.template_type}</span>
                      </FormGroup>
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-12">
                      <FormGroup>
                        <FormLabel>Ref</FormLabel>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Ref"
                          name="ref"
                          value= {this.state.ref}
                          onChange = {(event) => this.onChangeHandler(event)}
                        />
                        <span className="help-block">{this.state.errors.ref}</span>
                      </FormGroup>
                    </div>
                  </Row>
                  <Tabs defaultActiveKey="en" id="uncontrolled-tab-example">
                    <Tab eventKey="en" title="EN">
                      <Row>
                        <div className="col-md-12">
                          <FormGroup controlId="formControlsTextarea">
                            <FormLabel>Subject</FormLabel>
                            <CKEditor
                              editor={ ClassicEditor }
                              data={this.state.title}
                              onChange={ ( event, editor ) => this.onChangeHandlerTitle(editor.getData())}
                              onReady={(editor) => {
                                editor.plugins.get('FileRepository')
                                .createUploadAdapter = (loader) => {
                                  return new UploadAdapter(loader)
                                }
                              }}
                            />
                            <span className="help-block">{this.state.errors.title}</span>
                          </FormGroup>
                        </div>
                        </Row>
                        <Row>
                          <div className="col-md-12">
                            <FormGroup controlId="formControlsTextarea">
                              <FormLabel>Assessment</FormLabel>
                              <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.description}
                                onChange={ ( event, editor ) => this.onChangeHandlerDescription(editor.getData())}
                                onReady={(editor) => {
                                  editor.plugins.get('FileRepository')
                                  .createUploadAdapter = (loader) => {
                                    return new UploadAdapter(loader)
                                  }
                                }}
                              />
                              <span className="help-block">{this.state.errors.description}</span>
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                          {
                            this.state.template_type === "assesment" &&
                            <div className="col-md-12">
                              <FormGroup controlId="formControlsTextarea">
                                <FormLabel>Skin Care plan</FormLabel>
                                <CKEditor
                                  editor={ ClassicEditor }
                                  data={this.state.skinCarePlan}
                                  onChange={ ( event, editor ) => this.onChangeHandlerskinCarePlan(editor.getData())}
                                  onReady={(editor) => {
                                    editor.plugins.get('FileRepository')
                                    .createUploadAdapter = (loader) => {
                                      return new UploadAdapter(loader)
                                    }
                                  }}
                                />
                                <span className="help-block">{this.state.errors.skinCarePlan}</span>
                              </FormGroup>
                            </div>
                          }
                        </Row>
                      </Tab>
                      <Tab eventKey="ru" title="RU">
                        <Row>
                        <div className="col-md-12">
                          <FormGroup controlId="formControlsTextarea">
                            <FormLabel>Subject</FormLabel>
                            <CKEditor
                              editor={ ClassicEditor }
                              data={this.state.title_ru}
                              onChange={ ( event, editor ) => this.onChangeHandlerTitleRu(editor.getData())}
                              onReady={(editor) => {
                                editor.plugins.get('FileRepository')
                                .createUploadAdapter = (loader) => {
                                  return new UploadAdapter(loader)
                                }
                              }}
                            />
                            <span className="help-block">{this.state.errors.title_ru}</span>
                          </FormGroup>
                        </div>
                        </Row>
                        <Row>
                          <div className="col-md-12">
                            <FormGroup controlId="formControlsTextarea">
                              <FormLabel>Assessment</FormLabel>
                              <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.description_ru}
                                onChange={ ( event, editor ) => this.onChangeHandlerDescriptionRu(editor.getData())}
                                onReady={(editor) => {
                                  editor.plugins.get('FileRepository')
                                  .createUploadAdapter = (loader) => {
                                    return new UploadAdapter(loader)
                                  }
                                }}
                              />
                              <span className="help-block">{this.state.errors.description_ru}</span>
                            </FormGroup>
                          </div>
                        </Row>
                        <Row>
                        {
                          this.state.template_type === "assesment" && 
                          <div className="col-md-12">
                            <FormGroup controlId="formControlsTextarea">
                              <FormLabel>Skin Care Plan</FormLabel>
                              <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.skinCarePlan_ru}
                                onChange={ ( event, editor ) => this.onChangeHandlerskinCarePlanRu(editor.getData())}
                                onReady={(editor) => {
                                  editor.plugins.get('FileRepository')
                                  .createUploadAdapter = (loader) => {
                                    return new UploadAdapter(loader)
                                  }
                                }}
                              />
                              <span className="help-block">{this.state.errors.skinCarePlan_ru}</span>
                            </FormGroup>
                          </div>
                          }
                        </Row>
                      </Tab>
                    </Tabs>
                  </form>
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default AddTemplate;
