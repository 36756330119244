import React, { Component } from "react";
import * as fb from "../../firebase";
import {convertFirebseSecondsToDate, convertMilisecondsToDate} from "../Helper";

class MultiplePhotoField extends Component {
    constructor(props){
        super(props);
        this.state = {
            error: "",
            errors: "",
            pictures: [],
            forUpload: []
        }

    }

    componentDidMount() {
        this.setState({pictures: this.props.pictures})
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({pictures: nextProps.pictures})
    }

    clearField() {
        this.setState({forUpload: []})
    }

    onChangeHandler = async (event) => {
        if(event.target.files.length > 0){
            let forUpload = this.state.forUpload
            for (const file of event.target.files) {
                forUpload.push(file)
            }
            await this.setState({
                forUpload: forUpload
            })
            this.props.onChange(this.getDataForOutput())
        }
    }

    onChangeOrder = async (event,key,type) => {
        if(type === 'old') {
            if (typeof this.state.pictures[key] !== 'undefined') {
                let pictures = this.state.pictures
                pictures[key].order = event.target.value
                this.setState({pictures: pictures})
            }
        }else{
            if (typeof this.state.forUpload[key] !== 'undefined') {
                let forUpload = this.state.forUpload
                forUpload[key].order = event.target.value
                this.setState({forUpload: forUpload})
            }
        }
    }

    removeImage(key,type){
        if(window.confirm('Are you sure you want to remove this photo?')){
            if(type === 'old'){
                if(typeof this.state.pictures[key] !== 'undefined'){
                    fb.storage.child(this.state.pictures[key].name).delete().then(() => {
                    }).catch((error) => {
                    });
                    let pictures = this.state.pictures
                    pictures.splice(key, 1)
                    this.setState({pictures: pictures})
                }
            }else{
                if(typeof this.state.forUpload[key] !== 'undefined'){
                    let forUpload = this.state.forUpload
                    forUpload.splice(key, 1)
                    this.setState({forUpload: forUpload})
                }
            }
            this.props.onChange(this.getDataForOutput())
        }
    }

    getDataForOutput(){
        return {
            currentTarget: {
                    name: this.props.name,
                    value: {
                        pictures: this.state.pictures,
                        forUpload: this.state.forUpload
                    }
            }
        }
    }
    getUrlFromFile(file){
        return URL.createObjectURL(file)
    }

    render() {
        return (
            <div className="multiple-photo-field">
                <div className="preview-wrapper">
                    {this.state.pictures.map((item,key) => {
                    return (
                        <div className="preview-item" key={`${key}_old`}>
                            <div className="remove-image-btn" onClick={(e) => {this.removeImage(key,'old')}}>&times;</div>
                            {item.type && item.type.includes('video') ?
                              <video controls><source src={item.url} type={item.type}/></video>
                              :
                              <img src={item.url} alt="" />
                            }
                            <div className="meta">Uploaded at: <span>{convertFirebseSecondsToDate(item.uploaded.seconds)}</span></div>
                            <div className="meta">Order: <input type="number" value={item.order} onChange={(e) => {this.onChangeOrder(e,key,'old')}}/></div>
                        </div>
                    );
                    })}
                    {this.state.forUpload.map((item,key) => {
                        return (
                            <div className="preview-item" key={`${key}_new`}>
                                <div className="remove-image-btn" onClick={(e) => {this.removeImage(key,'new')}}>&times;</div>
                                {item.type.includes('video') ?
                                  <video controls><source src={this.getUrlFromFile(item)} type={item.type}/></video>
                                  :
                                  <img src={this.getUrlFromFile(item)} />
                                }
                                <div className="meta">Uploaded at: <span>{convertMilisecondsToDate(new Date().getTime())}</span></div>
                                <div className="meta">Order: <input type="number" value={item.order} onChange={(e) => {this.onChangeOrder(e,key,'new')}}/></div>
                            </div>
                        );
                    })}
                </div>
                <div className="uploader-wrapper">
                    <div className="uploader-item-wrapper">
                        <div className="uploader-icon"><i className="fa fa-plus"></i></div>
                        <div className="uploader-description">Add up to more media</div>
                        <input type="file" accept="image/*,video/*" multiple onDrop={this.onChangeHandler} onChange={this.onChangeHandler} />
                    </div>
                </div>
            </div>
        );
    }
}

export default MultiplePhotoField;
