import React, { Component } from "react";
import {ReactComponent as TypeFormCheck} from "../../../web_assets/img/typeFormCheck.svg";

class MultipleChoice extends Component {
    constructor(props){
        super(props);
        this.state = {
            error:"",
            errors:"",
            answerValue: {ref:''}
        }
    }

    multipleChoice(){
        let items = [];
        let answer = this.state.answerValue
        for (const item of this.props.properties.choices) {
            items.push(<div className={`typeFormMultipleItem ${answer.ref === item.ref || (answer.refs && answer.refs.includes(item.ref)) ? 'selected' : ''}`} key={item.ref} onClick={(e) => {this.onChangeHandler(item)}}><span>{item.label}</span><TypeFormCheck /></div>)
        }
        if(this.props.properties.allow_other_choice)
            items.push(<div className={`typeFormMultipleItem ${answer.ref === 'other' || (answer.refs && answer.refs.includes('other')) ? 'selected' : ''}`} key="other"><input type="text" onChange={(e) => {this.onChangeHandler({ref: 'other',label: e.target.value})}} onClick={(e) => {this.onChangeHandler({ref: 'other',label: e.target.value})}} placeholder="Other" /><TypeFormCheck /></div>)
        return items
    }

    onChangeHandler = async (event) => {
        if(this.props.properties.allow_multiple_selection){
            let refs = []
            let labels = []
            if(this.state.answerValue.hasOwnProperty('refs') && this.state.answerValue.refs){
                refs = this.state.answerValue.refs
                labels = this.state.answerValue.labels
            }
            let index = refs.indexOf(event.ref)
            let indexNone = labels.indexOf('None')
            if(indexNone !== -1){
                refs.splice(indexNone, 1)
                labels.splice(indexNone, 1)
            }
            if(event.ref === 'other'){
                if(index === -1){
                    refs.push(event.ref)
                    labels.push(event.label)
                }else{
                    refs.splice(index, 1)
                    labels.splice(index, 1)
                    if(event.label){
                        refs.push(event.ref)
                        labels.push(event.label)
                    }
                }
            }else{
                if(event.label === 'None'){
                    refs = [event.ref]
                    labels = [event.label]
                }else{
                    if(index === -1){
                        refs.push(event.ref)
                        labels.push(event.label)
                    }else{
                        refs.splice(index, 1)
                        labels.splice(index, 1)
                    }
                }
            }
            this.setState({answerValue: {refs: refs, labels: labels}})
            this.props.onChange({refs: refs, labels: labels})
        }else{
            this.setState({answerValue: event})
            this.props.onChange(event)
        }

    }

    render() {
        return (
            <div className="typeFormMultiple">{this.multipleChoice()}</div>
        );
    }
}

export default MultipleChoice;
